import { ServiceItemTypes } from "../core/Constants";
import Utils from "../core/Utils";

const adjustTaxIfNeeded = (
  inclusiveOfTax,
  calculatedTotalAmount,
  totalPriceIncludingTax,
  taxAmount
) => {
  if (!inclusiveOfTax || calculatedTotalAmount === totalPriceIncludingTax) {
    return taxAmount;
  }
  var diff = Utils.ConvertToFloat(
    (calculatedTotalAmount - totalPriceIncludingTax).toFixed(2)
  );
  taxAmount = Utils.ConvertToFloat((taxAmount - diff).toFixed(2));
  return taxAmount;
};

export const getTotalPrice = (price, quantity) => {
  return Utils.ConvertToFloat((price * quantity).toFixed(2));
};

export const getTaxAmount = (
  inclusiveOfTax,
  taxPercent,
  totalPrice,
  totalPriceIncludingTax
) => {
  if (inclusiveOfTax) {
    var taxAmount = Utils.ConvertToFloat(
      (totalPriceIncludingTax - totalPrice).toFixed(2)
    );
    var calculatedTotalAmount = totalPrice + taxAmount;
    taxAmount = adjustTaxIfNeeded(
      inclusiveOfTax,
      calculatedTotalAmount,
      totalPriceIncludingTax,
      taxAmount
    );
    return taxAmount;
  } else {
    return Utils.ConvertToFloat((totalPrice * (taxPercent / 100)).toFixed(2));
  }
};

export const getServiceItemFromProduct = (
  product,
  quantity = 1,
  isAnonymous = false
) => {
  if (Utils.IsNullOrEmptyObject(product)) {
    return {};
  }
  var totalPrice = getTotalPrice(product.price, quantity);
  var totalpriceIncludingTax = getTotalPrice(
    product.priceIncludingTax,
    quantity
  );
  var taxamount = getTaxAmount(
    product.inclusiveOfTax,
    product.taxPercent,
    totalPrice,
    totalpriceIncludingTax
  );
  var serviceItem = {
    Sequence: 0,
    Cost: product.cost,
    Description: product.shortDesc,
    BriefDescription: product.description,
    ProductHSN: product.hsn,
    Price: product.price,
    ProductClass: product.class,
    ProductCode: product.code,
    ProductDepartment: product.department,
    ProductFineline: product.fineline,
    ProductId: product.id,
    Quantity: quantity,
    DiscountTotal: 0,
    RetailPrice: product.retailPrice,
    MeasuringUnit: product.measuringUnit,
    Service: product.service,
    Labour: product.labour,
    Material: product.isMaterial,
    ServiceTime: product.serviceTime,
    TaxPercent: product.taxPercent,
    TotalPrice: totalPrice,
    IsAnonymous: isAnonymous,
    TotalPriceIncludingTax: totalPrice + taxamount,
    TaxAmount: taxamount,
    Discounts: [],
    ServiceItemStaffs: [],
    ServiceItemProducts: product.serviceItemProducts,
    RefundTotal: 0,
    InclusiveOfTax: product.inclusiveOfTax,
    PriceIncludingTax: product.priceIncludingTax,
  };
  return serviceItem;
};

export const getServiceItemAMCPlan = (amcPlan) => {
  if (Utils.IsNullOrEmptyObject(amcPlan)) {
    return {};
  }
  var quantity = 1;
  var totalPrice = getTotalPrice(amcPlan.price, quantity);
  var totalpriceIncludingTax = getTotalPrice(
    amcPlan.priceIncludingTax,
    quantity
  );
  var taxamount = getTaxAmount(
    amcPlan.inclusiveOfTax,
    amcPlan.taxPercent,
    totalPrice,
    totalpriceIncludingTax
  );

  var serviceItem = {
    Sequence: 0,
    Cost: amcPlan.cost,
    Description: amcPlan.name,
    ProductHSN: "",
    Price: amcPlan.price,
    ProductClass: "",
    ProductCode: "",
    ProductDepartment: "",
    ProductFineline: "",
    ProductId: amcPlan.id,
    Quantity: quantity,
    DiscountTotal: 0,
    RetailPrice: 0,
    MeasuringUnit: 0,
    Service: false,
    Labour: false,
    Material: 0,
    ServiceTime: 0,
    TaxPercent: amcPlan.taxPercent,
    TotalPrice: totalPrice,
    IsAnonymous: false,
    TotalPriceIncludingTax: totalPrice + taxamount,
    TaxAmount: taxamount,
    Discounts: [],
    ServiceItemStaffs: [],
    ServiceItemType: ServiceItemTypes.Plan,
    RefundTotal: 0,
    InclusiveOfTax: amcPlan.inclusiveOfTax,
    PriceIncludingTax: amcPlan.priceIncludingTax,
  };
  return serviceItem;
};
export const getBackPaymentPopup = ([AMCPLanDetails], quantity = 1) => {
  if (Utils.IsNullOrEmptyObject(AMCPLanDetails)) {
    return {};
  }
  var serviceItem = {
    Sequence: 0,
    cost: AMCPLanDetails.Cost,
    name: AMCPLanDetails.Description,
    ProductHSN: "",
    price: AMCPLanDetails.Price,
    ProductClass: "",
    ProductCode: "",
    ProductDepartment: "",
    ProductFineline: "",
    ProductId: AMCPLanDetails.id,
    Quantity: quantity,
    DiscountTotal: 0,
    RetailPrice: 0,
    MeasuringUnit: 0,
    Service: false,
    Labour: false,
    Material: 0,
    ServiceTime: 0,
    taxPercent: AMCPLanDetails.TaxPercent,
    paymentTotal: AMCPLanDetails.TotalPrice,
    IsAnonymous: false,
    TotalPriceIncludingTax: 0,
    paymentTax: AMCPLanDetails.TaxAmount,
    Discounts: [],
    ServiceItemStaffs: [],
    RefundTotal: 0,
  };
  return serviceItem;
};

export const getSpares = (products, isServiceItem = false) => {
  if (isServiceItem) {
    return getServiceItems(products);
  }
  return getProducts(products);
};

export const getLaboursOrServices = (products, isServiceItem = false) => {
  if (isServiceItem) {
    return getServiceItems(products, "", true);
  }
  return getProducts(products, "", true);
};

export const getServices = (products, isServiceItem = false) => {
  if (isServiceItem) {
    return getServiceItems(products, ServiceItemTypes.Service);
  }
  return getProducts(products, ServiceItemTypes.Service);
};

export const getConsumables = (products, isServiceItem = false) => {
  if (isServiceItem) {
    return getServiceItems(products, ServiceItemTypes.Consumable);
  }
  return getProducts(products, ServiceItemTypes.Consumable);
};

export const getLabours = (products, isServiceItem = false) => {
  if (isServiceItem) {
    return getServiceItems(products, ServiceItemTypes.Labour);
  }
  return getProducts(products, ServiceItemTypes.Labour);
};

const getProducts = (products, itemType = "", labourAndService = false) => {
  if (Utils.IsNullOrEmptyArray(products)) {
    return null;
  }
  if (labourAndService) {
    return products.filter((p) => p.labour || p.service);
  }
  var labour = false;
  var service = false;
  var consumable = false;
  switch (itemType) {
    case ServiceItemTypes.Service:
      service = true;
      break;
    case ServiceItemTypes.Consumable:
      consumable = true;
      break;
    case ServiceItemTypes.Labour:
      labour = true;
      break;
  }
  return products.filter(
    (p) =>
      p.labour === labour &&
      p.isMaterial === consumable &&
      p.service === service
  );
};

const getServiceItems = (items, itemType = "", labourAndService = false) => {
  if (Utils.IsNullOrEmptyArray(items)) {
    return null;
  }
  if (labourAndService) {
    return items.filter((p) => p.labour || p.service);
  }
  var labour = false;
  var service = false;
  var consumable = false;
  switch (itemType) {
    case ServiceItemTypes.Service:
      service = true;
      break;
    case ServiceItemTypes.Consumable:
      consumable = true;
      break;
    case ServiceItemTypes.Labour:
      labour = true;
      break;
  }
  return items.filter(
    (p) =>
      p.labour === labour && p.material === consumable && p.service === service
  );
};

export const convertServiceItemObject = (item, canConvertDiscount = false) => {
  if (Utils.IsNullOrEmpty(item)) {
    return {};
  }
  var totalPrice = getTotalPrice(item.price, item.quantity);
  var totalpriceIncludingTax = getTotalPrice(
    item.priceIncludingTax,
    item.quantity
  );
  var taxamount = getTaxAmount(
    item.inclusiveOfTax,
    item.taxPercent,
    totalPrice,
    totalpriceIncludingTax
  );
  var serviceItem = {
    Sequence: item.sequence,
    Cost: item.cost,
    Description: item.description,
    BriefDescription: item.briefDescription,
    ProductHSN: item.productHSN,
    Price: item.price,
    DiscountTotal: 0,
    ProductClass: item.productClass,
    ProductCode: item.productCode,
    ProductDepartment: item.productDepartment,
    ProductFineline: item.productFineline,
    ProductId: item.productId,
    Quantity: item.quantity,
    RetailPrice: item.retailPrice,
    MeasuringUnit: item.measuringUnit,
    Service: item.service,
    Labour: item.labour,
    Material: item.material,
    ServiceTime: item.serviceTime,
    TaxPercent: item.taxPercent,
    TotalPrice: item.totalPrice,
    IsAnonymous: item.isAnonymous,
    TotalPriceIncludingTax: totalPrice + taxamount,
    TaxAmount: taxamount,
    Discounts: [],
    ServiceItemStaffs: item.serviceItemStaffs,
    ServiceItemProducts: item.serviceItemProducts,
    RefundTotal: item.refundTotal,
    InclusiveOfTax: item.inclusiveOfTax,
    PriceIncludingTax: item.priceIncludingTax,
  };
  if (!Utils.IsNullOrEmptyArray(item.discounts) && canConvertDiscount) {
    var newTotalPrice = item.totalPrice - item.discountTotal;
    totalpriceIncludingTax = Utils.ConvertToFloat(
      ((newTotalPrice * (item.taxPercent + 100)) / 100).toFixed(2)
    );
    taxamount = Utils.ConvertToFloat(
      (totalpriceIncludingTax - newTotalPrice).toFixed(2)
    );
    var convertedDiscounts = [];
    item.discounts.forEach((discount) => {
      var newDiscount = {
        CampaignId: discount.campaignId,
        BenefitId: discount.benefitId,
        CampaignType: discount.campaignType,
        OffersClassificationType: discount.offersClassificationType,
        BenefitType: discount.benefitType,
        Code: discount.code,
        Description: discount.description,
        DescriptionDetail: discount.descriptionDetail,
        Quantity: discount.quantity,
        Complimentary: discount.complimentary,
        OrderLevel: discount.orderLevel,
        PointsRedemption: discount.pointsRedemption,
        DiscountType: discount.discountType,
        DiscountValue: discount.discountValue,
        DiscountTotal: discount.discountTotal,
      };
      convertedDiscounts.push(newDiscount);
    });
    serviceItem = {
      ...serviceItem,
      Discounts: convertedDiscounts,
      DiscountTotal: item.discountTotal,
      TotalPriceIncludingTax: totalpriceIncludingTax,
      TaxAmount: taxamount,
    };
  }
  return serviceItem;
};

export const getServiceItemProductsFromProduct = (product) => {
  if (Utils.IsNullOrEmptyObject(product)) {
    return {};
  }

  var serviceItem = {
    Description: product.shortDesc,
    ProductCode: product.code,
    ProductId: product.id,
    Quantity: 1,
    MeasuringUnit: product.measuringUnit,
  };
  return serviceItem;
};

export const convertProductItemObject = (item) => {
  if (Utils.IsNullOrEmpty(item)) {
    return {};
  }
  var serviceItem = {
    Description: item.description,
    ProductCode: item.productCode,
    ProductId: item.productId,
    Quantity: item.quantity,
    MeasuringUnit: item.measuringUnit,
  };
  return serviceItem;
};
