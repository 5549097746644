import Autocomplete from "react-google-autocomplete";
import Utils from "../../../core/Utils";

const AddressField = ({
  value,
  setValue,
  countryISoCode,
  label,
  isValidate = false,
  ...others
}) => {
  const handleOnChange = (e) => {
    setValue(e);
  };

  return Utils.IsNullOrEmpty(countryISoCode) ? (
    <></>
  ) : (
    <Autocomplete
      {...others}
      apiKey={"AIzaSyCk2uVaMZuNW--_uwSDwq8GxYWNDD7KN2Q"}
      onPlaceSelected={(place) => {
        setValue(place.formatted_address);
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
      }}
      id="branchAddress"
      class={
        isValidate
          ? !value
            ? "pac-target-input-first"
            : "pac-target-input"
          : "pac-target-input"
      }
      label={label}
      variant="outlined"
      required
      options={{
        types: ["geocode", "establishment"],
        componentRestrictions: { country: countryISoCode },
      }}
      value={value}
      onChange={(e) => {
        handleOnChange(e.target.value);
      }}
    />
  );
};
export default AddressField;
