import TextField from "@mui/material/TextField";
import Utils from "../../../core/Utils";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Regexes } from "../../../core/Regexes";

const DecimalEntryField = ({ value, setValue, setError = false, ...other }) => {
  const [others, setOthers] = useState({});
  const [displayValue, setDisplayValue] = useState("");
  useEffect(() => {
    setDisplayValue(value);
    var iprops = {}
    if (!Utils.IsNullOrEmptyObject(other) && !Utils.IsNullOrEmptyObject(other.inputProps)) {
      iprops = { ...other.inputProps, maxLength: 12 }
    }
    else {
      iprops = { maxLength: 12 }
    }
    setOthers({ ...other, type: "text", inputProps: iprops });
  }, []);

  useEffect(() => {
    handleChangeFromOutside();
  }, [value]);

  const handleChangeFromOutside = () => {
    setDisplayValue(value);
  }

  const handleOnChange = (currentValue) => {
    if (Utils.IsNullOrEmpty(currentValue)) {
      setDisplayValue(currentValue);
      setValue("");
    }
    var val = currentValue;
    if (val.startsWith(".")) {
      val = "0" + val;
    }
    if (val.includes(".") && val.split(".")[0].length > 1 && val.startsWith("0")) {
      val = val.slice(1);
    }
    else if (!val.includes(".") && val.length > 1 && val.startsWith("0")) {
      val = val.slice(1);
    }
    if (Regexes.onlyDecimalValue.test(val)) {
      var newPrice = Utils.ConvertToFloat(val);
      setDisplayValue(val);
      setValue(newPrice);
    }
  };
  if (setError) {
    return (
      <TextField {...others} error={!displayValue} value={displayValue} onChange={(e) => handleOnChange(e.target.value)} />
    );
  }
  return (
    <TextField {...others} value={displayValue} onChange={(e) => handleOnChange(e.target.value)} />
  );
};

export default DecimalEntryField;

DecimalEntryField.propTypes = {
  value: PropTypes.number.isRequired,
  setValue: PropTypes.func.isRequired
};