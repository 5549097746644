import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Utils from "../../core/Utils";

export const ImageEnlarge = ({ open, imgSrc, onClose }) => {
  const handleClose = () => {
    onClose();
  };
  return open && !Utils.IsNullOrEmpty(imgSrc) ? (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="dialog-title"
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon style={{"font-size":"22px","color":"black"}} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <img src={imgSrc} style={{"maxWidth":"100%","height":"100%"}}/>
      </DialogContent>
    </Dialog>
  ) : null;
};

ImageEnlarge.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
