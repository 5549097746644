import { Grid, Stack, Button } from "@mui/material";
import DecimalEntryField from "./controls/DecimalEntryField";
import AddCircleOutlineIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/Remove";
import Utils from "../../core/Utils";
const actions = {
    incrementAction: "incrementAction",
    decrementAction: "decrementAction",
    editAction: "editAction",
};
const QuantityComponent = ({ quantity, handleActions }) => {
    const handleEditQuantity = (value) => {
        handleAction(actions.editAction, value);
    };
    const handleAction = (action, value) => {
        switch (action) {
            case actions.decrementAction:
                var oldQuantity = Utils.ConvertToFloat(quantity);
                if (oldQuantity < 1) {
                    return;
                }
                handleActions(Utils.ConvertToFloat((oldQuantity - 1).toFixed(2)));
                break;
            case actions.incrementAction:
                var oldQuantity = Utils.ConvertToFloat(quantity);
                handleActions(Utils.ConvertToFloat((oldQuantity + 1).toFixed(2)));
                break;
            case actions.editAction:
                handleActions(value);
                break;
        }
    };
    return (
        <Grid xs={4} lg={2} alignSelf="center">
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
            >
                <RemoveCircleOutlineIcon
                    fontSize="large"
                    alignSelf="center"
                    onClick={() => handleAction(actions.decrementAction)}
                    style={{ marginRight: "10px" }}
                />
                <DecimalEntryField
                    label=""
                    type="text"
                    alignSelf="center"
                    variant="outlined"
                    value={quantity}
                    setValue={handleEditQuantity}
                    className="inputField text-right"
                    style={{ width: "80px", margin: 0 }}
                    max={0}
                />
                <AddCircleOutlineIcon
                    fontSize="large"
                    alignSelf="center"
                    onClick={() => handleAction(actions.incrementAction)}
                />
            </Stack>
        </Grid>
    );
};
export default QuantityComponent;