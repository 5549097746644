import DialogPopup from "./DialogPopup";
import PropTypes from 'prop-types';

export default function InvoiceTypePopup(props) {
    const { invoiceOpen, onInvoiceClose, ...other } = props;
    return (
        <DialogPopup
            open={invoiceOpen}
            onClose={onInvoiceClose}
            content="Select invoice type"
            isConfirm={true}
            okText="Split Invoice"
            cancelText="Regular Invoice"
            {...other}
        />
    );
}

InvoiceTypePopup.propTypes = {
    onInvoiceClose: PropTypes.func.isRequired,
    invoiceOpen: PropTypes.bool.isRequired
};
