import React, { useReducer, useEffect, useState } from "react";
import "../../Style.css";
import "../../bootstrap.min.css";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CountryHelper from "../../core/CountryHelper";
import Button from "@mui/material/Button";
import { getGeocodeAddress, postAsync } from "../../core/serviceClient";
import {
  getBranch,
  getBusiness,
  getCountry,
  getMakeModels,
} from "../../data/localSettingsActions";
import Utils from "../../core/Utils";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  ConfirmTypes,
  EstimateStatus,
  NotApplicable,
  SideBarMenuNames,
  VisitTypeEnum,
} from "../../core/Constants";
import { serviceItemReducer } from "../reducers/serviceItem/serviceItemReducer";
import { serviceItemActionTypes } from "../reducers/serviceItem/serviceItemActionTypes";
import SideBarMenuItems from "../SideBarMenuItems";
import Loader from "../common/Loader";
import MessagePopup from "../common/MessagePopUp";
import CartComponent from "../common/CartComponent";
import {
  convertServiceItemObject,
  getServiceItemFromProduct,
} from "../../helpers/productHelper";
import { useDispatch } from "react-redux";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import JobCardIcon from "@mui/icons-material/FactCheck";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import PromptDialog from "../common/PromptDialog";
import NumericTextField from "../common/controls/NumericTextField";
import {
  EstimateDetailsPage,
  JobCardDetailsPage,
  LoginPage,
} from "../../core/PageConstants";
import UserHelper from "../../core/UserHelper";
import AddressField from "../common/controls/AddressField";
import { Switch } from "@mui/material";
import AutoMobileHelper from "../../core/AutomobileHelper";
import { ConfirmDialog } from "../common/ConfirmDialog";
import InsuranceClaimComponent from "../common/InsuranceClaimComponent";
import EstimateOptionsDialog from "../dialogs/EstimateOptionsDialog";
import InsuranceConfirmDialog from "../dialogs/InsuranceConfirmDialog";
import moment from "moment";

const alertActions = {
  Saved: "Saved",
  Error: "Error",
  ConvertToJobcard: "ConvertToJobcard",
  Void: "Void",
};

const EstimateDetails = () => {
  document.title = "Estimate Details";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [openCustomMakeModel, setOpenCustomMakeModel] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [isNewEstimate, setIsNewEstimate] = useState(true);

  //Alert hooks
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertAction, setAlertAction] = useState(alertActions.Error);

  //Confirm hooks
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");

  //Loader
  const [isLoading, setIsLoading] = useState(false);

  //Common Hooks
  const [business, setBusiness] = useState({});
  const [branch, setBranch] = useState({});
  const [countryInfo, setCountryInfo] = useState({});
  const [isUAEBusiness, setIsUAEBusiness] = useState(false);
  const [makeModelList, setMakeModelList] = useState([]);
  const [makeList, setMakeList] = useState([]);
  const [canSave, setCanSave] = useState(false);
  const [canDoActions, setCanDoActions] = useState(false);

  //Customer Hooks
  const [customerId, setCustomerId] = useState("");
  const [customerUserName, setCustomerUserName] = useState("");
  const [customerMobileNumber, setCustomerMobileNumber] = useState("");
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerTRNCode, setCustomerTRNCode] = useState("");
  const [customer, setCustomer] = useState({});
  const [customerPass, setCustomerPass] = useState({});

  //Vehicle Hooks
  const [vehicle, setVehicle] = useState({});
  const [customerVehicleNo, setCustomerVehicleNo] = useState("");
  const [customerPlateCode, setCustomerPlateCode] = useState("");
  const [modelList, setModelList] = useState([]);
  const [customerEmirate, setCustomerEmirate] = useState("");
  const [emirateDisabled, setEmirateDisabled] = useState(false);
  const [plateCodeDisabled, setPlateCodeDisabled] = useState(false);
  const [customerVehicleMake, setCustomerVehicleMake] = useState("");
  const [emirateType, setEmiratetype] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [customerVehicleModel, setCustomerVehicleModel] = useState("");
  const [customerVehicleYear, setCustomerVehicleYear] = useState("");
  const [customerVehicleColor, setCustomerVehicleColor] = useState("");
  const [customerVehicleFuelType, setCustomerVehicleFuelType] = useState("");

  const [customerVehicleEngineNo, setCustomerVehicleEngineNo] = useState("");
  const [customerVehicleChassisNo, setCustomerVehicleChassisNo] = useState("");
  const [odoMeterIn, setOdoMeterIn] = useState("");
  const [odoMeterOut, setOdoMeterOut] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [serviceItems, itemDispatch] = useReducer(serviceItemReducer, []);
  const [estimate, setEstimate] = useState({});
  const [customerComments, setCustomerComments] = useState("");
  const [itemComplimentaryList, setItemComplimentaryList] = useState([]);

  //Insurance hooks
  const [insuranceClaimNumber, setInsuranceClaimNumber] = useState("");
  const [insuranceEstimatedRepairDays, setInsuranceEstimatedRepairDays] =
    useState("");
  const [insuranceEstimateValidity, setInsuranceEstimateValidity] =
    useState("");
  const [insuranceType, setInsuranceType] = useState("");
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [insuranceInDate, setInsuranceInDate] = useState(
    Utils.GetCurrentDate().add(0, "days")
  );
  const [insuranceOutDate, setInsuranceOutDate] = useState(
    Utils.GetCurrentDate().add(1, "days")
  );
  const [showEstimateOptions, setShowEstimateOptions] = useState(false);
  const [isShowConfirmDialogInsDetails, setIsShowConfirmDialogInsDetails] =
    useState(false);

  const checkAuthentication = async () => {
    if (UserHelper.CheckPermission(EstimateDetailsPage, navigate)) {
      setIsLoading(true);
      if (!UserHelper.IsStateValid(location.state, navigate))
        return;
      var isNew =
        location.state.isNew ||
        Utils.IsNullOrEmptyObject(location.state.estimate);
      var country = getCountry();
      setCountryInfo(country);
      var makeModels = getMakeModels();
      var makes = makeModels.map((c) => c.make);
      setMakeList(makes);
      setMakeModelList(makeModels);
      setIsUAEBusiness(CountryHelper.IsBusinessCountryUAE(country));
      setEmiratetype(AutoMobileHelper.getEmirate());
      var currentBusiness = getBusiness();
      var currentBranch = getBranch();
      setBusiness(currentBusiness);
      setBranch(currentBranch);
      setIsNewEstimate(location.state.isNew);
      var customerSelected =
        !Utils.IsNullOrEmptyObject(location.state.pass) &&
        !Utils.IsNullOrEmptyObject(location.state.vehicleDetails);
      if (isNew) {
        if (customerSelected) {
          setCustomerPass(location.state.pass);
          setVehicle(location.state.vehicleDetails);
        } else {
          populateVehicleDetails();
        }
        setCanSave(true);
        setCanDoActions(false);
      } else {
        setEstimate(location.state.estimate);
        setVehicle(location.state.estimate.vehicleDetails);
        var isEnabled = location.state.estimate.status === EstimateStatus.Open;
        setCanSave(isEnabled);
        setCanDoActions(isEnabled);
        var response = await postAsync(
          "Customer/GetCustomerPass",
          {
            Id: currentBusiness.id + location.state.estimate.customerId,
            BusinessId: currentBusiness.id,
          },
          "",
          "Get"
        );
        if (!Utils.IsNullOrEmptyObject(response.data)) {
          setCustomerPass(response.data);
        }
        populateInsuranceClaimDetails(location.state.estimate.insuranceClaimDetails);
        populateFromVisitServiceItems(location.state.estimate.serviceItems);
        setCustomerComments(location.state.estimate.comments);
      }
      setIsLoading(false);
    }
  };

  const populateInsuranceClaimDetails = (insuranceClaim) => {
    if(Utils.IsNullOrEmptyObject(insuranceClaim))
    {
      return;
    }
    setInsuranceClaimNumber(insuranceClaim.insuranceClaimNumber);
    setInsuranceType(insuranceClaim.insuranceType);
    setInsuranceEstimateValidity(insuranceClaim.insuranceEstimateValidity);
    setInsuranceEstimatedRepairDays(insuranceClaim.insuranceEstimatedRepairDays);
    if (
      !Utils.IsDefaultDate(insuranceClaim.insuranceInDate)
    ) {
      setInsuranceInDate(
        moment(insuranceClaim.insuranceInDate)
      );
    }
    if (
      !Utils.IsDefaultDate(insuranceClaim.insuranceOutDate)
    ) {
      setInsuranceOutDate(
        moment(insuranceClaim.insuranceOutDate)
      );
    }
  }

  const populateFromVisitServiceItems = (serviceItemsFromVisit) => {
    var itemComps = [...itemComplimentaryList];
    if (!Utils.IsNullOrEmptyArray(serviceItemsFromVisit)) {
      serviceItemsFromVisit.forEach((serviceitem) => {
        var newItem = convertServiceItemObject(serviceitem, true);
        var comp = extractItemComplimentaryFromServiceItem(newItem);
        if (!Utils.IsNullOrEmptyObject(comp)) {
          itemComps.push(comp);
        }
        if (!Utils.IsNullOrEmpty(newItem)) {
          itemDispatch({
            type: serviceItemActionTypes.Add,
            value: newItem,
          });
        }
      });
      setItemComplimentaryList(itemComps);
    }
  };
  const extractItemComplimentaryFromServiceItem = (serviceItem) => {
    if (
      !Utils.IsNullOrEmptyArray(serviceItem.Discounts) &&
      serviceItem.Discounts.some((d) => d.Complimentary && !d.OrderLevel)
    ) {
      var discount = serviceItem.Discounts.filter(
        (d) => d.Complimentary && !d.OrderLevel
      )[0];
      if (Utils.IsNullOrEmptyObject(discount)) {
        return {};
      }
      return {
        orderLevel: false,
        productId: serviceItem.ProductId,
        index: serviceItem.Sequence,
        value: discount.DiscountValue,
        type: discount.DiscountType,
        discount: discount.DiscountTotal,
        compId: discount.CampaignId,
      };
    }
    return {};
  };

  useEffect(() => {
    checkAuthentication();
  }, []);

  useEffect(() => {
    triggerSetCustomer();
  }, [customerPass]);

  useEffect(() => {
    poulateCustomerDetails();
  }, [customer]);

  useEffect(() => {
    populateVehicleDetails();
  }, [vehicle]);

  const poulateCustomerDetails = () => {
    if (Utils.IsNullOrEmptyObject(customer)) {
      return;
    }
    setCustomerId(customer.id);
    setCustomerUserName(customer.userName);
    setCustomerMobileNumber(customer.mobileNumber);
    setCustomerEmailId(customer.emailId);
    setCustomerAddress(customer.homeLocation);
    setCustomerTRNCode(customer.registrationId);
  };

  const triggerSetCustomer = async () => {
    if (Utils.IsNullOrEmptyObject(customerPass)) {
      return;
    }
    setIsLoading(true);
    var response = await postAsync(
      "Customer/GetCustomer",
      { Id: customerPass.customerId, BusinessId: customerPass.customerId },
      "",
      "Get"
    );
    setIsLoading(false);
    if (!Utils.IsNullOrEmptyObject(response.data)) {
      setCustomer(response.data);
    }
  };

  const populateVehicleDetails = () => {
    if (Utils.IsNullOrEmptyObject(vehicle)) {
      setCustomerVehicleFuelType("Petrol");
      setPlateCodeDisabled(false);
      setEmirateDisabled(false);
      return;
    }
    setPlateCodeDisabled(true);
    setEmirateDisabled(true);
    setCustomerVehicleNo(vehicle.number);
    var cEmirate = !Utils.IsNullOrEmpty(vehicle.emirate)
      ? vehicle.emirate
      : NotApplicable;
    setCustomerEmirate(cEmirate);
    setCustomerPlateCode(vehicle.plateCode);
    var make = Utils.IsNullOrEmpty(vehicle.make) ? "" : vehicle.make.trim();
    var model = Utils.IsNullOrEmpty(vehicle.model) ? "" : vehicle.model.trim();
    setCustomerVehicleMake(make);
    setCustomerVehicleModel(model);
    var makeModel = makeModelList.find((c) => c.make === make);
    var models = Utils.IsNullOrEmptyObject(makeModel) ? [] : makeModel.model;
    setModelList(models);
    var modelFound = !Utils.IsNullOrEmptyArray(models) && models.includes(model);
    setOpenCustomMakeModel(Utils.IsNullOrEmptyObject(makeModel) || modelFound === false);
    setCustomerVehicleYear(vehicle.year);
    setCustomerVehicleColor(vehicle.color);
    setCustomerVehicleFuelType(vehicle.fuelType);
    setCustomerVehicleChassisNo(vehicle.chassisNumber);
    setCustomerVehicleEngineNo(vehicle.engineNumber);
    setVehicleType(vehicle.vehicleType);
  };

  const setAlert = (msg) => {
    setAlertMessage(msg);
    setShowAlert(true);
  };

  useEffect(() => {
    setOdoMeterOut(Utils.ConvertToString(odoMeterIn));
  }, [odoMeterIn]);

  // ShareEstimate
  const shareEstimate = async () => {
    var currentEstimate = estimate;
    if (
      Utils.IsNullOrEmptyObject(currentEstimate) ||
      (currentEstimate.status !== EstimateStatus.Void &&
        currentEstimate.status !== EstimateStatus.Closed)
    ) {
      var response = await proceedSaveEstimate();
      if (response.error) {
        setIsNavigateToLogin(response.isNavigateToLogin);
        setAlert(response.errorMessage);
        setAlertAction(alertActions.Error);
        return;
      }
      if (!Utils.IsNullOrEmptyObject(response.data)) {
        setIsNewEstimate(false);
        setEstimate(response.data.customerEstimate);
        // if (Utils.IsNullOrEmptyObject(location.state.pass)) {
        //   setCustomerPass(response.data.customerPass);
        // }
        currentEstimate = response.data.customerEstimate;
      } else {
        setAlert("Something went wrong");
        setAlertAction(alertActions.Error);
        return;
      }
    }
    var url =
      Utils.GetServiceUrl() + "home/est?v=" + currentEstimate.id + "&m=0";
    window.open(encodeURI(url), "_blank");
  };

  //saveEstimate
  const saveEstimate = async () => {
    setShowDialog(false);
    var response = await proceedSaveEstimate();
    if (response.error) {
      setIsNavigateToLogin(response.isNavigateToLogin);
      setAlert(response.errorMessage);
      setAlertAction(alertActions.Error);
      return;
    }
    if (!Utils.IsNullOrEmptyObject(response.data)) {
      setIsNewEstimate(false);
      setEstimate(response.data.customerEstimate);
      // if (Utils.IsNullOrEmptyObject(location.state.pass)) {
      //   setCustomerPass(response.data.customerPass);
      // }

      setAlert("Estimate saved successfully");
      setAlertAction(alertActions.Saved);
    } else {
      setAlert("Something went wrong");
      setAlertAction(alertActions.Error);
    }
  };

  const proceedSaveEstimate = async () => {
    setIsLoading(true);
    var addressDetails = null;
    if (
      !Utils.IsNullOrEmpty(customerAddress) &&
      Utils.IsNullOrEmptyObject(customer)
    ) {
      var geocodeResponse = await getGeocodeAddress(customerAddress);
      if (
        !Utils.IsNullOrEmptyObject(geocodeResponse) &&
        geocodeResponse.status === "OK"
      ) {
        var locality = "";
        var county = "";
        var state = "";
        var country = "";
        var zipcode = "";
        var streetNumber = "";
        var route = "";
        var address = "";
        var lng = 0;
        var lat = 0;
        address = geocodeResponse.results[0].formatted_address;
        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("street_number") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("street_number")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            streetNumber = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("route") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("route")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            route = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("locality") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("locality")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            locality = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_1") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_1")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            state = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("administrative_area_level_2") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("administrative_area_level_2")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            county = ac.long_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("country") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("country")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            country = ac.short_name;
          }
        }

        if (
          geocodeResponse.results[0].address_components
            .map((c) => c.types)
            .reduce((a, b) => a.concat(b))
            .includes("postal_code") === true
        ) {
          var ac = geocodeResponse.results[0].address_components.find((c) =>
            c.types.includes("postal_code")
          );
          if (!Utils.IsNullOrEmptyObject(ac)) {
            zipcode = ac.long_name;
          }
        }
        lat = geocodeResponse.results[0].geometry.location.lat;
        lng = geocodeResponse.results[0].geometry.location.lng;
        addressDetails = {
          HomeLocation: customerAddress,
          Address: streetNumber + " " + route,
          City: locality,
          State: state,
          Country: country,
          County: county,
          ZipCode: zipcode,
          Locality: locality,
          Latitude: Utils.ConvertToFloat(lat),
          Longitude: Utils.ConvertToFloat(lng),
        };
      } else {
        setIsLoading(false);
        setAlert("Address is invalid");
        setAlertAction(alertActions.Error);
        return;
      }
    }
    var estimatedRepairDays = Utils.ConvertToInteger(insuranceEstimatedRepairDays);
    var estimateValidity = Utils.ConvertToInteger(insuranceEstimateValidity);
    var request = {
      EstimateId: Utils.IsNullOrEmptyObject(estimate) ? "" : estimate.id,
      BusinessId: business.id,
      CustomerId: Utils.IsNullOrEmptyObject(customer) ? "" : customer.id,
      Comments: Utils.IsNullOrEmpty(customerComments) ? "" : customerComments,
      ServiceItems: serviceItems,
      VisitType: VisitTypeEnum.Estimate,
      NewEstimate: isNewEstimate,
      CountryCode: countryInfo.Code,
      EnterpriseId: business.enterpriseId,
      AdditionalData: {
        AddressDetails: addressDetails,
        VehicleDetails: {
          Id: Utils.IsNullOrEmptyObject(vehicle) ? 0 : vehicle.id,
          Number: customerVehicleNo,
          VehicleType: Utils.IsNullOrEmpty(vehicleType)
            ? getBranch().vehicleType
            : vehicleType,
          PlateCode: customerPlateCode,
          Emirate: customerEmirate,
          Make: customerVehicleMake,
          Model: customerVehicleModel,
          Color: customerVehicleColor,
          Year: Utils.ConvertToInteger(customerVehicleYear),
          OwnerContactNo: customerMobileNumber,
          EngineNumber: customerVehicleEngineNo,
          ChassisNumber: customerVehicleChassisNo,
          FuelType: customerVehicleFuelType,
          KmDriven: Utils.ConvertToInteger(odoMeterIn),
          KmDrivenOut: Utils.ConvertToInteger(odoMeterOut),
        },
        InsuranceClaimDetails:{
          InsuranceClaimNumber: insuranceClaimNumber,
          InsuranceEstimatedRepairDays: estimatedRepairDays,
          InsuranceEstimateValidity: estimateValidity,
          InsuranceType: insuranceType,
          InsuranceInDate: Utils.GetISODateTimeString(insuranceInDate),
          InsuranceOutDate: Utils.GetISODateTimeString(insuranceOutDate),
        },
        CustomerEmailId: customerEmailId,
        CustomerMobileNumber: customerMobileNumber,
        CustomerTaxId: customerTRNCode,
        CustomerName: customerUserName,
      },
    };
    var response = await postAsync("Customer/UpdateEstimate", request);
    setIsLoading(false);
    return response;
  };

  //void
  const voidEstimate = () => {
    setShowDialog(false);
    setAlertAction(alertActions.Void);
    setConfirmMessage("Are you sure you want to void this estimate?");
    setShowConfirmDialog(true);
  };

  const convertToJobcard = () => {
    setShowDialog(false);
    setAlertAction(alertActions.ConvertToJobcard);
    setConfirmMessage(
      "Are you sure you want to convert this estimate to jobcard?"
    );
    setShowConfirmDialog(true);
  };

  const handleServiceItemActions = (action) => {
    configureDialog();
    itemDispatch(action);
  };
  const handleAlertClose = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
      return;
    }
    if (
      alertAction === alertActions.Saved ||
      alertAction === alertActions.Void
    ) {
      navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
        state: {
          ...location.state,
          navigationStack: Utils.RemoveLastElementFromArray(
            location.state.navigationStack
          ),
        },
      });
    }
  };

  const addItemToCart = (product, isCustomProduct = false) => {
    configureDialog();
    var serviceItem = isCustomProduct
      ? product
      : getServiceItemFromProduct(product);
    if (Utils.IsNullOrEmptyArray(serviceItems)) {
      serviceItem.Sequence = 1;
    } else {
      serviceItem.Sequence = serviceItems[serviceItems.length - 1].Sequence + 1;
    }
    itemDispatch({
      type: serviceItemActionTypes.Add,
      value: serviceItem,
    });
  };

  const backNavigation = () => {
    setShowDialog(false);
    navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
      state: {
        ...location.state,
        navigationStack: Utils.RemoveLastElementFromArray(
          location.state.navigationStack
        ),
      },
    });
  };

  useEffect(() => {
    checkDetailsFilled();
  }, [
    customerMobileNumber,
    customerVehicleNo,
    customerEmirate,
    customerPlateCode,
    customerVehicleMake,
    customerVehicleModel,
    odoMeterIn,
    customerUserName,
    customerVehicleFuelType,
    serviceItems,
    customerComments,
    openCustomMakeModel,
  ]);

  const checkDetailsFilled = () => {
    var vehicleDetailsValid =
      !Utils.IsNullOrEmpty(customerEmirate) &&
      (customerEmirate === NotApplicable ||
        !Utils.IsNullOrEmpty(customerPlateCode)) &&
      !Utils.IsNullOrEmpty(customerVehicleNo) &&
      !Utils.IsNullOrEmpty(customerVehicleMake) &&
      !Utils.IsNullOrEmpty(customerVehicleModel) &&
      !Utils.IsNullOrEmpty(customerVehicleFuelType);
    var isDetailsFilled =
      Utils.IsMobileValid(customerMobileNumber) &&
      !Utils.IsNullOrEmpty(customerUserName) &&
      vehicleDetailsValid &&
      (!Utils.IsNullOrEmptyArray(serviceItems) ||
        !Utils.IsNullOrEmpty(customerComments));
    setSaveDisabled(!isDetailsFilled);
  };

  const handleChange = (value) => {
    setCustomerVehicleFuelType(value);
    configureDialog();
  };

  const onChangeMobileNumber = (value) => {
    setCustomerMobileNumber(value);
    configureDialog();
  };

  const onChangeAddress = (value) => {
    setCustomerAddress(value);
    configureDialog();
  };

  const onChangeVehicleYear = (value) => {
    setCustomerVehicleYear(value);
    configureDialog();
  };

  const onChangeOdoMeterIn = (value) => {
    setOdoMeterIn(value);
    configureDialog();
  };

  const configureDialog = () => {
    if (!showDialog) {
      setShowDialog(true);
    }
  };

  const handeEmirateChange = (e) => {
    var newEmirate = e.target.value;
    setCustomerEmirate(newEmirate);
    switch (newEmirate) {
      case NotApplicable:
        setPlateCodeDisabled(true);
        setCustomerPlateCode("");
        break;
      default:
        setPlateCodeDisabled(false);
        break;
    }
  };
  function toggleCustomMakeModel() {
    setOpenCustomMakeModel(!openCustomMakeModel);
    setCustomerVehicleMake("");
    setCustomerVehicleModel("");
  }
  const vehicleMakeHandleChange = (e) => {
    var make = e.target.value;
    var models = makeModelList.find((c) => c.make === make).model;
    setCustomerVehicleMake(make);
    setModelList(models);
  };
  const modelHandleChange = (event) => {
    setCustomerVehicleModel(event.target.value);
  };

  const handleConfirmClose = async (confirmResult) => {
    switch (confirmResult) {
      default:
      case ConfirmTypes.CANCEL:
      case ConfirmTypes.CLOSE:
      case ConfirmTypes.NO:
        setShowConfirmDialog(false);
        setConfirmMessage("");
        break;
      case ConfirmTypes.YES:
      case ConfirmTypes.OK:
        setShowConfirmDialog(false);
        setConfirmMessage("");
        switch (alertAction) {
          case alertActions.ConvertToJobcard:
            navigate(JobCardDetailsPage.Path, {
              state: {
                ...location.state,
                navigationStack: Utils.AddElementToArray(
                  location.state.navigationStack,
                  EstimateDetailsPage.Path
                ),
                estimate: estimate,
              },
            });
            break;
          case alertActions.Void:
            setIsLoading(true);
            var response = await postAsync("Customer/EstimateCancel", {
              BusinessId: business.id,
              EstimateId: !Utils.IsNullOrEmptyObject(estimate)
                ? estimate.id
                : "",
            });
            if (response.error) {
              setIsNavigateToLogin(response.isNavigateToLogin);
              setAlert(response.errorMessage);
              setAlertAction(alertActions.Error);
            } else if (!Utils.IsNullOrEmpty(response.data)) {
              setAlert("Estimate voided successfully.");
              setAlertAction(alertActions.Void);
            }
            setIsLoading(false);
            break;
        }
    }
  };

  const onAddComp = (comp) => {
    var tempCompList = [...itemComplimentaryList];
    tempCompList.push(comp);
    setItemComplimentaryList(tempCompList);
  }

  const onRemoveComp = (comp) => {
    var tempCompList = [...itemComplimentaryList];
    tempCompList = tempCompList.filter((t) => t.compId !== comp.compId);
    setItemComplimentaryList(tempCompList);
  }

  const handleEditItemComp = (comp, i) => {
    var comps = [...itemComplimentaryList]
    comps[i] = comp;
    setItemComplimentaryList(comps);
  };

  const viewInsuranceEstimate = async (bool) => {
    var currentEstimate = estimate;
    if (
      Utils.IsNullOrEmptyObject(currentEstimate) ||
      (currentEstimate.status !== EstimateStatus.Void &&
        currentEstimate.status !== EstimateStatus.Closed)
    ) {
      var response = await proceedSaveEstimate();
      if (response.error) {
        setIsNavigateToLogin(response.isNavigateToLogin);
        setAlert(response.errorMessage);
        setAlertAction(alertActions.Error);
        return;
      }
      if (!Utils.IsNullOrEmptyObject(response.data)) {
        setIsNewEstimate(false);
        setEstimate(response.data.customerEstimate);
        // if (Utils.IsNullOrEmptyObject(location.state.pass)) {
        //   setCustomerPass(response.data.customerPass);
        // }
        currentEstimate = response.data.customerEstimate;
      } else {
        setAlert("Something went wrong");
        setAlertAction(alertActions.Error);
        return;
      }
    }
    if (isUAEBusiness) {
      var show = bool;
    } else {
      var show = false;
    }
    // if (
    //   Utils.IsNullOrEmptyObject(vehicle.vehicleInsuranceDetails) ||
    //   Utils.IsNullOrEmpty(vehicle.vehicleInsuranceDetails.policyNumber) ||
    //   Utils.ConvertToDate(vehicle.vehicleInsuranceDetails.expiryDate).isBefore(
    //     moment()
    //   )
    // ) {
    //   setShowEstimateOptions(false);
    //   setAlert(
    //     "Please add insurance details for the selected vehicle before proceeding"
    //   );
    //   return;
    // }
    var url =
      Utils.GetServiceUrl() +
      "home/InsuranceEstimateNew?culture=" +
      CountryHelper.GetAPICulture(countryInfo.Culture) +
      "&bid=" +
      business.id +
      "&cid=" +
      currentEstimate.customerId +
      "&eid=" +
      currentEstimate.id +
      "&showPrices=" +
      show +
      "&m=0";
    window.open(encodeURI(url), "_blank");
    setIsShowConfirmDialogInsDetails(false);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <Box>
        {/* Popups */}
        <PromptDialog
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />

        <MessagePopup
          msgOpen={showAlert}
          msgText={alertMessage}
          onMsgClose={handleAlertClose}
        />

        <ConfirmDialog
          open={showConfirmDialog}
          message={confirmMessage}
          yesText="Yes"
          noText="No"
          onClose={handleConfirmClose}
        />

        <EstimateOptionsDialog
          open={showEstimateOptions}
          close={() => setShowEstimateOptions(false)}
          regularEstimate={async () => await shareEstimate()}
          insuranceEstimate={() => setIsShowConfirmDialogInsDetails(true)}
        />

        <InsuranceConfirmDialog
          open={isShowConfirmDialogInsDetails}
          close={() => setIsShowConfirmDialogInsDetails(false)}
          yes={async () => await viewInsuranceEstimate(true)}
          no={async () => await viewInsuranceEstimate(false)}
        />

        {/* Main Container */}
        <Grid container m={0}>
          <SideBarMenuItems
            selectedTab={
              !Utils.IsNullOrEmptyObject(location.state) &&
              !Utils.IsNullOrEmpty(location.state.activeMenu)
                ? location.state.activeMenu
                : location.state && location.state.fromJobcard
                ? SideBarMenuNames.JobCard
                : SideBarMenuNames.Customers
            }
          />
          <Grid
            xs={12}
            className="content-sec"
            style={{ background: "#ffffff" }}
          >
            <Grid container className="title-sec">
              <Typography
                level="h2"
                component="h2"
                fontWeight="600"
                fontSize="2rem"
                className="page-title text-center"
              >
                <Button onClick={backNavigation} className="back-btn">
                  <NavigateBeforeIcon />
                </Button>
                Estimate Details
              </Typography>
              <Grid paddingRight="15px" alignSelf="center" className="toolbar">
                <Stack direction="row" spacing={1}>
                  <Button
                    className="btn btn-secondary"
                    variant="contained"
                    disabled={saveDisabled}
                    onClick={() => setShowEstimateOptions(true)}
                  >
                    <ShareIcon style={{ color: "#673ab7" }} />
                    <span className="hidden-xs ml-h">Share</span>
                  </Button>
                  {canSave && (
                    <Button
                      className="btn btn-secondary"
                      variant="contained"
                      disabled={saveDisabled}
                      onClick={() => saveEstimate()}
                    >
                      <CheckCircleOutlineIcon style={{ color: "#8BC34A" }} />
                      <span className="hidden-xs ml-h">Save</span>
                    </Button>
                  )}
                  {canDoActions && (
                    <>
                      <Button
                        className="btn btn-secondary"
                        variant="contained"
                        disabled={saveDisabled}
                        onClick={() => convertToJobcard()}
                      >
                        <JobCardIcon
                          fontSize="large"
                          style={{ color: "#673ab7" }}
                        />
                        <span className="hidden-xs ml-h">Job Card</span>
                      </Button>
                      <Button
                        className="btn btn-secondary"
                        variant="contained"
                        disabled={saveDisabled}
                        onClick={() => voidEstimate()}
                      >
                        <DeleteIcon style={{ color: "#D11A2A" }} />
                        <span className="hidden-xs ml-h">VOID</span>
                      </Button>
                    </>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <form
              method="POST"
              className="cs-form text-left mt-xs-3"
              autoComplete="off"
            >
              <Typography
                level="h3"
                component="h3"
                fontWeight="600"
                fontSize="2rem"
                className="h3"
              >
                Customer Info :
              </Typography>
              <Stack sx={{ minWidth: 120 }} spacing={2} className="mt-2">
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardName"
                      label="Name"
                      variant="outlined"
                      value={customerUserName}
                      onChange={(e) => {
                        setCustomerUserName(e.target.value);
                        configureDialog();
                      }}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardGSTIN"
                      label={"TRN NO"}
                      variant="outlined"
                      value={customerTRNCode}
                      onChange={(e) => {
                        setCustomerTRNCode(e.target.value);
                        configureDialog();
                      }}
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <NumericTextField
                      id="jobcardMobileNumber"
                      label="Mobile Number"
                      variant="outlined"
                      value={customerMobileNumber}
                      inputProps={{
                        maxLength: CountryHelper.GetMobileNumberMaxLength(),
                      }}
                      setValue={onChangeMobileNumber}
                      required
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardEmailId"
                      label="Email"
                      variant="outlined"
                      value={customerEmailId}
                      onChange={(e) => {
                        setCustomerEmailId(e.target.value);
                        configureDialog();
                      }}
                    />
                  </FormControl>
                </Stack>
                {!Utils.IsNullOrEmptyObject(countryInfo) && (
                  <FormControl fullWidth>
                    <AddressField
                      id="jobcardAddress"
                      variant="outlined"
                      label="Address"
                      required
                      countryISoCode={countryInfo.ISOCode}
                      value={customerAddress}
                      setValue={onChangeAddress}
                    />
                  </FormControl>
                )}
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h3"
                >
                  Vehicle Details :
                </Typography>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth className="mt-2">
                    <InputLabel id="demo-simple-select-label">
                      Emirate
                    </InputLabel>
                    <Select
                      id="Emirate"
                      value={customerEmirate}
                      label="Emirate"
                      required
                      disabled={emirateDisabled}
                      variant="outlined"
                      onChange={(e) => handeEmirateChange(e)}
                    >
                      {!Utils.IsNullOrEmptyArray(emirateType) &&
                        emirateType.map((data) => (
                          <MenuItem value={data}>{data}</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth className="mt-2">
                    <TextField
                      id="plateCode"
                      label={plateCodeDisabled ? "Plate Code" : "Plate Code*"}
                      variant="outlined"
                      disabled={plateCodeDisabled}
                      inputProps={{ maxLength: 20 }}
                      value={customerPlateCode}
                      onChange={(e) => setCustomerPlateCode(e.target.value)}
                    />
                  </FormControl>
                </Stack>
                <FormControl fullWidth>
                  <TextField
                    id="jobcardVehicleNumber"
                    label="Vehicle Number"
                    variant="outlined"
                    required
                    value={customerVehicleNo}
                    onChange={(e) => {
                      setCustomerVehicleNo(e.target.value);
                      configureDialog();
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormControl>
                <FormControl onClick={toggleCustomMakeModel}>
                  <FormControlLabel
                    control={<Switch checked={openCustomMakeModel} />}
                    label="Custom Select"
                  />
                </FormControl>
                <Stack direction="row" spacing={2}>
                  {openCustomMakeModel ? (
                    <>
                      <FormControl fullWidth>
                        <TextField
                          id="vehicleMakeSelect"
                          label="Make"
                          variant="outlined"
                          value={customerVehicleMake}
                          onChange={(e) =>
                            setCustomerVehicleMake(e.target.value)
                          }
                          required
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="vehicleModelSelect"
                          label="Model"
                          variant="outlined"
                          value={customerVehicleModel}
                          onChange={(e) =>
                            setCustomerVehicleModel(e.target.value)
                          }
                          required
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Make
                        </InputLabel>
                        <Select
                          id="vehicleMake"
                          value={customerVehicleMake}
                          label="Make"
                          required
                          variant="outlined"
                          onChange={(e) => vehicleMakeHandleChange(e)}
                        >
                          {makeList.map((data) => (
                            <MenuItem value={data}>{data}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Model
                        </InputLabel>
                        <Select
                          id="vehicleModel"
                          required
                          value={customerVehicleModel}
                          label="Model"
                          variant="outlined"
                          onChange={modelHandleChange}
                        >
                          {modelList.map((data) => (
                            <MenuItem value={data}>{data}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </>
                  )}
                </Stack>
                <Stack direction="row" spacing={2}>
                  <FormControl fullWidth>
                    <NumericTextField
                      id="jobcardYear"
                      label="Year"
                      variant="outlined"
                      value={customerVehicleYear}
                      setValue={onChangeVehicleYear}
                      inputProps={{ maxLength: 4 }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      id="jobcardColor"
                      label="Color"
                      variant="outlined"
                      value={customerVehicleColor}
                      onChange={(e) => {
                        setCustomerVehicleColor(e.target.value);
                        configureDialog();
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Fuel Type
                    </InputLabel>
                    <Select
                      id="jobcardFuelType"
                      value={customerVehicleFuelType}
                      label="Fuel Type"
                      variant="outlined"
                      onChange={(e) => handleChange(e.target.value)}
                    >
                      <MenuItem value="Petrol">Petrol</MenuItem>
                      <MenuItem value="Diesel">Diesel</MenuItem>
                      <MenuItem value="CNG">CNG</MenuItem>
                      <MenuItem value="LPG">LPG</MenuItem>
                      <MenuItem value="Electric">Electric</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} className="xs-column">
                  <FormControl fullWidth className="my-xs-1">
                    <NumericTextField
                      id="jobcardOdometerReadingIn"
                      value={odoMeterIn}
                      setValue={onChangeOdoMeterIn}
                      label="Odometer Reading In"
                      variant="outlined"
                      required={branch.isOdometerReadingsMandatory}
                    />
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardOdometerReadingOut"
                      value={odoMeterOut}
                      disabled={true}
                      label="Odometer Reading Out"
                      variant="outlined"
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" spacing={2} className="xs-column">
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardChassisNumber"
                      label="Chassis Number"
                      variant="outlined"
                      value={customerVehicleChassisNo}
                      onChange={(e) => {
                        setCustomerVehicleChassisNo(e.target.value);
                        configureDialog();
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth className="my-xs-1">
                    <TextField
                      id="jobcardEngineNumber"
                      label="Engine Number"
                      variant="outlined"
                      value={customerVehicleEngineNo}
                      onChange={(e) => {
                        setCustomerVehicleEngineNo(e.target.value);
                        configureDialog();
                      }}
                    />
                  </FormControl>
                </Stack>
                {isUAEBusiness && (
                  <InsuranceClaimComponent
                    insuranceClaimNumber={insuranceClaimNumber}
                    setInsuranceClaimNumber={setInsuranceClaimNumber}
                    insuranceType={insuranceType}
                    setInsuranceType={setInsuranceType}
                    insuranceEstimatedRepairDays={insuranceEstimatedRepairDays}
                    setInsuranceEstimatedRepairDays={
                      setInsuranceEstimatedRepairDays
                    }
                    insuranceEstimateValidity={insuranceEstimateValidity}
                    setInsuranceEstimateValidity={setInsuranceEstimateValidity}
                    insuranceInDate={insuranceInDate}
                    setInsuranceInDate={setInsuranceInDate}
                    insuranceOutDate={insuranceOutDate}
                    setInsuranceOutDate={setInsuranceOutDate}
                  />
                )}
                <Box>
                  <Typography
                    level="h3"
                    component="h3"
                    fontWeight="600"
                    fontSize="2rem"
                    className="h3"
                  >
                    Service Items :
                  </Typography>
                  <Stack className="card">
                    <CartComponent
                      includePreview={false}
                      serviceItems={serviceItems}
                      handleItemAction={handleServiceItemActions}
                      onAddItem={addItemToCart}
                      onChangeTotal={setTotalPrice}
                      visitType={VisitTypeEnum.Estimate}
                      invoiceLabels={branch.invoiceLabels}
                      itemComplimentaryList={itemComplimentaryList}
                      onAddComp={onAddComp}
                      onRemoveComp={onRemoveComp}
                      onEditComp={handleEditItemComp}
                    />
                    <Typography
                      level="h4"
                      component="h4"
                      className="my-1"
                      fontWeight="600"
                      fontSize="20px"
                      style={{ textAlign: "right" }}
                    >
                      <small>Total:</small> &nbsp;
                      {Utils.GetCurrency(totalPrice, countryInfo)}
                    </Typography>
                  </Stack>
                </Box>
                <Typography
                  level="h3"
                  component="h3"
                  fontWeight="600"
                  fontSize="2rem"
                  className="h3"
                >
                  Customer's Request :
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    id="jobcardComments"
                    label="Comments"
                    variant="outlined"
                    value={customerComments}
                    onChange={(e) => setCustomerComments(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    multiline
                  />
                </FormControl>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default EstimateDetails;
