import React from "react";
import { Box, Stack } from "@mui/system";
import PropTypes from "prop-types";
import "../../Loader.css";

export default function Loader(props) {
  const { open } = props;
  return (
    <>
      {open ? (
        <Box className="loader-wrap">
          <Stack alignSelf="center">
            <Stack className="loader">
              <Box></Box>
              <Box></Box>
              <Box></Box>
              <Box></Box>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <span></span>
      )}
    </>
  );
}

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
};
