import React, { useEffect, useState } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import {
  Button,
  Container,
  Box,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
// import Container from "@mui/material/Container";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import Stack from "@mui/material/Stack";
// import Typography from "@mui/material/Typography";
import { useNavigate, Link } from "react-router-dom";
import Utils from "../core/Utils";
import Loader from "./common/Loader";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import { useDispatch } from "react-redux";
import UserHelper from "../core/UserHelper";
import MessagePopup from "./common/MessagePopUp";
import { postAsync } from "../core/serviceClient";
import { getCountry } from "../data/localSettingsActions";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { LoginPage, ServiceReminderPage, OverviewPage } from "../core/PageConstants";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import { useLocation } from "react-router-dom";
import CountryHelper from "../core/CountryHelper";

const ServiceReminder = (props) => {
  document.title = "Service Reminder";
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);

  const [serviceList, setServiceList] = useState([]);

  useEffect(() => {
    checkState();
    if (UserHelper.CheckPermission(ServiceReminderPage, navigate)) {
      fetchServiceReminderDetails(location.state.startDT, location.state.endDT);
    }
  }, []);

  const checkState = () => {
    if (!Utils.IsNullOrEmptyObject(location.state) && !Utils.IsNullOrEmpty(location.state.startDT) && !Utils.IsNullOrEmpty(location.state.endDT)) {
      setSelectedDate([location.state.startDT, location.state.endDT])
    }

  }



  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  async function fetchServiceReminderDetails(startDate, endDate) {
    setIsLoading(true);
    var request = {
      FromDate: Utils.GetISODateTimeString(moment(startDate)),
      ToDate: Utils.GetISODateTimeString(moment(endDate)),
    };

    let result = await postAsync(
      "Customer/GetServiceReminderByNextServiceDate",
      request
    );
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    if (!Utils.IsNullOrEmptyArray(result.data.customerVisitsList)) {
      getServiceData(result.data.customerVisitsList);
    }
    if (!Utils.IsNullOrEmptyArray(result.data.customerVisitsList.vehicleServiceDetails)) {
      getServiceData(result.data.customerVisitsList.vehicleServiceDetails);
    }
    setIsLoading(false);
  }

  const onDateChange = (date) => {
    setSelectedDate(date);
    fetchServiceReminderDetails(date[0], date[1]);
  };

  const columns = [
    {
      field: "vehicleNo",
      headerName: " Vehicle #",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "make",
      headerName: "Make",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      headerClassName: "headerStyle",
      flex: 1,
    },

    {
      field: "lastServiceDate",
      headerName: "last Service Date",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "nextServiceOn",
      headerName: "Next Service On",
      headerClassName: "headerStyle",
      flex: 1,
    },

  ];

  async function getServiceData(data) {

    const serviceRows = [];
    for (var i = 0; i < data.length; i++) {
      serviceRows.push({
        id: i,
        vehicleNo: data[i].vehicleDetails.number,
        make: data[i].vehicleDetails.make,
        model: data[i].vehicleDetails.model,
        customerName: data[i].customerName,
        mobile: data[i].customerMobileNumber,
        lastServiceDate: Utils.GetDate(data[i].checkOut),
        nextServiceOn: Utils.GetDate(data[i].vehicleServiceDetails.nextServiceOn),
      });
    }
    setServiceList(serviceRows);
  };



  const backNavigation = () => {
    navigate(OverviewPage.Path, {
      state: {
        startDate: Utils.GetISODateTimeString(moment(location.state.startDT)),
        endDate: Utils.GetISODateTimeString(moment(location.state.endDT)),
      }
    });
  }


  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
          <Grid xs={12} className="content-sec c-sec2">
            <Grid container direction="row" className="">
              <Grid
                flex="1"
                spacing={2}
                padding="20px"
                className="form-sec xs-pd-0"
              >
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title"
                  >
                    <Button onClick={backNavigation} className="back-btn">
                      <NavigateBeforeIcon />
                    </Button>
                    Service Reminder
                  </Typography>
                  {/* </Link> */}

                  <Stack direction="row" className="text-right date-picker-sec">
                    <DateRangePicker
                      showLeadingZeros={true}
                      onChange={onDateChange}
                      value={selectedDate}
                      locale={CountryHelper.GetDateCulture(getCountry().Culture)}
                      clearIcon={null}
                      calendarIcon={
                        <CalendarMonthOutlinedIcon
                          style={{
                            "font-size": "24px",
                            "align-self": "center",
                            color: "#666666",
                          }}
                        />
                      }
                    />
                  </Stack>
                </Grid>
                <Grid container xs={12} className="font-size-14 data-grid-box">
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={serviceList}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      className="data-grid font-size-14"
                      slots={{ toolbar: CustomToolbar }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default ServiceReminder;
