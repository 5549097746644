import React from "react";
import "../Style.css";
import "../bootstrap.min.css";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import { Regexes } from "../core/Regexes";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connectToStore } from "../data/store";
import { postAsync } from "../core/serviceClient";
import Button from "@mui/material/Button";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import SettingsMenuItems from "./SettingsMenuItems";
import { SettingsMenuList } from "../core/Constants";
import { useDispatch } from "react-redux";
import UserHelper from "../core/UserHelper";

// Images
import { setBranch } from "../data/localSettingsActions";
import Utils from "../core/Utils";
import { LoginPage, UPISetupPage } from "../core/PageConstants";

const UPISetup = (props) => {
  document.title = "UPI Setup";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [upiPayeeName, setupiPayeeName] = useState("");
  const [upiId, setupiId] = useState("");
  const [ReUpiId, setReUpiId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };
  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(UPISetupPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
      setupiPayeeName(localSetting.branch.upi ? localSetting.branch.upi.upiPayeeName : "");
      setupiId(localSetting.branch.upi ? localSetting.branch.upi.upiId : "");
    }
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (ReUpiId === upiId) {
      upiSubmit();
    } else {
      setMsgText("UPI id does not match");
      setMsgOpen(true);
    }
  };

  useEffect(() => {
    checkDetailsFilled();
  }, [upiPayeeName, upiId, ReUpiId]);

  const checkDetailsFilled = () => {
    var falseCondition =
      Utils.IsNullOrEmpty(upiPayeeName) ||
      Utils.IsNullOrEmpty(upiId) ||
      Utils.IsNullOrEmpty(ReUpiId) ||
      !Regexes.onlyUPIid.test(upiId);
    setDisabled(falseCondition);
  };

  async function upiSubmit() {
    let result = await postAsync(
      "Payout/SetupUPI",
      {
        BusinessId: localSettings.business.id,
        BranchId: localSettings.branch.branchId,
        UPIId: upiId,
        UPIPayeeName: upiPayeeName,
      },
    );
    if (result.error == false) {
      var tempbranch = localSettings.branch;
      tempbranch = {
        ...tempbranch,
        upi: { upiId: upiId, upiPayeeName: upiPayeeName },
      };
      setBranch(tempbranch, dispatch);
      setMsgText("UPI details updated successfully");
    }
    else setMsgText("Unable to process the request");
    setMsgOpen(true);
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec white-bg">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.BusinessSettings.UPISetup}
              />

              <Grid flex="1" spacing={2} className="form-sec">
                <Grid container className="title-sec title-xs">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    UPI Setup
                  </Typography>
                </Grid>
                <form
                  className="cs-form"
                  style={{ padding: "0" }}
                  onSubmit={handleSubmit}
                >
                  <Stack sx={{ minWidth: 120 }} spacing={2}>
                    <FormControl fullWidth>
                      <TextField
                        label="UPI payee Name"
                        type="text"
                        value={upiPayeeName}
                        error={!upiPayeeName}
                        onChange={(e) => setupiPayeeName(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        label="UPI Id"
                        type="text"
                        value={upiId}
                        error={!upiId}
                        onChange={(e) => setupiId(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        label=" Re enter UPI Id "
                        type="text"
                        value={ReUpiId}
                        error={!ReUpiId}
                        onChange={(e) => setReUpiId(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      <Button
                        position=""
                        variant="contained"
                        className="btn btn-primary"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={disabled}
                      >
                        Save
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default UPISetup;
