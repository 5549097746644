import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Utils from "../../core/Utils";
import { Enterprises } from "../../core/Constants";
import CustomerBookingXUVI from "./CustomerBookingXUVI";
import CustomerBookingBull from "./CustomerBookingBull";

const CustomerBooking = () => {
  const [params] = useSearchParams();
  const [businessId, setBusinessId] = useState(params.get("bid"));
  const [enterpriseId, setEnterpriseId] = useState(params.get("eid"));
  const [passId, setPassId] = useState(params.get("cpid"));
  const [branchId, setBranchId] = useState(params.get("brid"));


  return (
    <>
      {
        (Utils.IsNullOrEmpty(enterpriseId) || enterpriseId === Enterprises.XUVI) &&
        <CustomerBookingXUVI businessId={businessId} passId={passId} branchId={branchId} />
      }
      {
        (enterpriseId === Enterprises.DetailingBull) &&
        <CustomerBookingBull businessId={businessId} passId={passId} branchId={branchId} />
      }
    </>
  );
};
export default CustomerBooking;
