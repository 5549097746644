import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent, Typography,IconButton } from "@mui/material";
import Success_Icon from "../../images/success.gif";
import CloseIcon from "@mui/icons-material/Close";

export const SuccessDialog = ({ open, message, onClose }) => {
  const handleClose = () => {
    onClose();
  };
  return open ? (
    <Dialog
      open={open}
      sx={{ "& .MuiDialog-paper": { width: "70%" } }}
      maxWidth="xs"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ border: "0" }}
        className="font-size-14 text-center"
      >
        <img src={Success_Icon} alt="loading..." style={{ width: "160px" }} />
        <Typography
          level="h2"
          component="h2"
          fontWeight="600"
          fontSize="2rem"
          className="page-title text-center"
        >
          {message}
        </Typography>
      </DialogContent>
    </Dialog>
  ) : null;
};

SuccessDialog.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
