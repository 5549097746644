import { Box } from "@mui/material";
import unauthorizedImg from "../../images/unauthorized.png";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Utils from "../../core/Utils";

const CustomerBookingError = () => {
  document.title = "Error";
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(
    "You are not allowed to access this page."
  );
  useEffect(() => {
    setMessage();
  }, []);

  const setMessage = () => {
    if(!Utils.IsNullOrEmptyObject(location.state) && !Utils.IsNullOrEmpty(location.state.errorMessage))
    {
      setErrorMessage(location.state.errorMessage);
    }
  }
  
  return (
    <>
      <div id="indexLoading">
        <div className="align-center">
          <Box component="img" src={unauthorizedImg}></Box>
          <h1>Error</h1>
          <p>{errorMessage}</p>
        </div>
      </div>
    </>
  );
};
export default CustomerBookingError;
