import "./App.css";
import React from "react";
import {
  unstable_HistoryRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { LandingPage, Pages, UnAuthorizedPage } from "./core/PageConstants";
import history from "./history";

export default function App() {
  var el = document.getElementById("indexLoading");
  if (el) el.remove();
  return (
    <Router history={history}>
      <Routes>
        <Route path="*" element={<Navigate to={UnAuthorizedPage.Path} />} />
        <Route path="/" element={<Navigate to={LandingPage.Path} />} />
        {Pages.map((page, index) => (
          <Route path={page.Path} element={page.Element} />
        ))}
      </Routes>
    </Router>
  );
}
