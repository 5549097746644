import React, { useState, useEffect } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Utils from "../core/Utils";
import Loader from "./common/Loader";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import { useDispatch } from "react-redux";
import UserHelper from "../core/UserHelper";
import MessagePopup from "./common/MessagePopUp";
import { postAsync } from "../core/serviceClient";

import { getCountry } from "../data/localSettingsActions";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { InsurancePage, LoginPage } from "../core/PageConstants";
import "../DateRangePickerWithYear.css";
import "react-calendar/dist/Calendar.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import CountryHelper from "../core/CountryHelper";

const Insurance = (props) => {
  document.title = "Insurance";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [insuranceList, setInsuranceList] = useState([]);

  useEffect(() => {
    if (UserHelper.CheckPermission(InsurancePage, navigate)) {
      fetchInsuranceDetails(selectedDate[0], selectedDate[1]);
    }
  }, []);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const onDateChange = (date) => {
    setSelectedDate(date);
    fetchInsuranceDetails(date[0], date[1]);
  };

  async function fetchInsuranceDetails(startDate, endDate) {
    setIsLoading(true);
    var startISO = Utils.GetISODateTimeString(moment(startDate));
    var endISO = Utils.GetISODateTimeString(moment(endDate));
    var request = {
      InsuranceExpStartDate: startISO,
      InsuranceExpEndDate: endISO,
    };

    let result = await postAsync(
      "Customer/GetCustomerPassByVehicleInsuranceExpDate",
      request
    );
    setIsNavigateToLogin(result.isNavigateToLogin);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
    }
    var vehilceList = [];
    if (!Utils.IsNullOrEmptyArray(result.data.customerPassList)) {
      result.data.customerPassList.forEach((pass) => {
        if (!Utils.IsNullOrEmptyArray(pass.vehicleDetails)) {
          var validVehicles = pass.vehicleDetails.filter(
            (x) =>
              Utils.ConvertToDate(x.vehicleInsuranceDetails.expiryDate) >=
              startDate &&
              Utils.ConvertToDate(x.vehicleInsuranceDetails.expiryDate) <=
              endDate
          );
          if (!Utils.IsNullOrEmptyArray(validVehicles)) {
            validVehicles.forEach((vehicle) => {
              var insObj = {
                customer: pass,
                vehicle: vehicle,
              };
              vehilceList.push(insObj);
            });
          }
        }
      });
      getInsData(vehilceList);
    }
    setIsLoading(false);
  }

  var pageTitle = "Insurance";

  const columns = [
    {
      field: "customerName",
      headerName: "Customer Name",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "make",
      headerName: "Make",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "model",
      headerName: "Model",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "number",
      headerName: "number",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "fuelType",
      headerName: "Fuel Type",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "expiryDate",
      headerName: "Expiry Date",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "policyNumber",
      headerName: "Policy Number",
      headerClassName: "headerStyle",
      flex: 1,
    },
  ];

  async function getInsData(data) {
    var country = getCountry();
    const vRows = [];
    for (var i = 0; i < data.length; i++) {
      vRows.push({
        id: i,
        customerName: data[i].customer.userName,
        mobile: data[i].customer.mobileNumber,
        make: data[i].vehicle.make,
        model: data[i].vehicle.model,
        number: data[i].vehicle.number,
        fuelType: data[i].vehicle.fuelType,
        expiryDate: Utils.GetDate(
          data[i].vehicle.vehicleInsuranceDetails.expiryDate,
          country
        ),
        policyNumber: data[i].vehicle.vehicleInsuranceDetails.policyNumber,
      });
    }
    setInsuranceList(vRows);
  }

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Overview} />
          <Grid xs={12} className="content-sec c-sec2">
            <Grid container direction="row" className="">
              <Grid
                flex="1"
                spacing={2}
                padding="20px"
                className="form-sec xs-pd-0"
              >
                <Grid container className="title-sec">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    {pageTitle}
                  </Typography>
                  <Stack
                    direction="row"
                    className="text-right date-picker-sec"
                    paddingRight="15px"
                  >
                    <DateRangePicker
                      showLeadingZeros={true}
                      onChange={onDateChange}
                      maxDate={new Date()}
                      value={selectedDate}
                      locale={CountryHelper.GetDateCulture(getCountry().Culture)}
                      clearIcon={null}
                      calendarIcon={
                        <CalendarMonthOutlinedIcon
                          style={{
                            "font-size": "24px",
                            "align-self": "center",
                            color: "#666666",
                          }}
                        />
                      }
                    />
                  </Stack>
                </Grid>
                <Grid container xs={12} className="font-size-14 data-grid-box">
                  <Box sx={{ height: 678, width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={insuranceList}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pageSizeOptions={[10]}
                      className="data-grid font-size-14"
                      slots={{ toolbar: CustomToolbar }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}

export default Insurance;
