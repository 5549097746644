import {
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const PromptDialog = ({ showDialog, cancelNavigation, confirmNavigation }) => {
  return (
    <Dialog
      open={showDialog}
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 300 } }}
      maxWidth="xs"
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="dialog-title"
      >
        Warning
        <IconButton aria-label="close" onClick={cancelNavigation}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Typography
            variant="h5"
            className="py-2"
            dangerouslySetInnerHTML={{
              __html:
                "You have unsaved changes. Please click on ok if you want to proceed with exiting this screen.",
            }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="btn btn-secondary"
          onClick={cancelNavigation}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          variant="contained"
          className="btn btn-primary"
          onClick={confirmNavigation}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PromptDialog;
