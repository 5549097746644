import Utils from "../../../core/Utils";
import {
  apiIntervals,
  errorResponse,
  generateErrorResult,
  getCurrentLocalDTISOString,
  internalGenericErrorMessage,
  maxTimeout,
} from "../../../core/serviceClient";

export const get = async (url, params) => {
  let retryCount = 0;
  const maxRetries = apiIntervals.length;
  let currentDelay = 0;
  const makeFetchCall = async (delay) => {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, maxTimeout);
        await fetch(Utils.GetServiceUrl() + url + "?" + params, {
          signal: controller.signal,
          crossDomain: true,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => {
            clearTimeout(timeoutId);
            resolve(response);
          })
          .catch((error) => {
            clearTimeout(timeoutId);
            resolve({ status: 500, statusText: internalGenericErrorMessage });
          });
      }, delay);
    });
  };

  while (retryCount <= maxRetries) {
    try {
      const response = await makeFetchCall(currentDelay);
      if (response) {
        switch (response.status) {
          default:
            if (retryCount === maxRetries) {
              return generateErrorResult(response, Utils.GetServiceUrl() + url);
            }
            break;
          case 200:
            return await response.json();
          case 401:
          case 403:
            return generateErrorResult(response, Utils.GetServiceUrl() + url);
        }
      }
    } catch (error) {}
    retryCount++;
    currentDelay = apiIntervals[retryCount - 1];

    if (retryCount > maxRetries) {
      return errorResponse(internalGenericErrorMessage);
    }
  }
};

export const post = async (url, data) => {
  let retryCount = 0;
  const maxRetries = apiIntervals.length;
  let currentDelay = 0;
  const makeFetchCall = async (delay) => {
    return new Promise(async (resolve, reject) => {
      setTimeout(async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, maxTimeout);
        await fetch(Utils.GetServiceUrl() + url, {
          signal: controller.signal,
          crossDomain: true,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            Data: data,
            Operation: "Execute",
            CurrentLocalDT: getCurrentLocalDTISOString(),
            IsWebApp: true,
          }),
        })
          .then((response) => {
            clearTimeout(timeoutId);
            resolve(response);
          })
          .catch((error) => {
            clearTimeout(timeoutId);
            resolve({ status: 500, statusText: internalGenericErrorMessage });
          });
      }, delay);
    });
  };

  while (retryCount <= maxRetries) {
    try {
      const response = await makeFetchCall(currentDelay);
      if (response) {
        switch (response.status) {
          default:
            if (retryCount === maxRetries) {
              return generateErrorResult(response, Utils.GetServiceUrl() + url);
            }
            break;
          case 200:
            return await response.json();
          case 401:
          case 403:
            return generateErrorResult(response, Utils.GetServiceUrl() + url);
        }
      }
    } catch (error) {}
    retryCount++;
    currentDelay = apiIntervals[retryCount - 1];

    if (retryCount > maxRetries) {
      return errorResponse(internalGenericErrorMessage);
    }
  }
};
