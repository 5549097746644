import { configureStore } from "@reduxjs/toolkit";
import { initialState, localSettingsReducer } from "./localSettingsReducer";
import { applyMiddleware } from "redux";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";
import {
  persistStore,
  persistReducer
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, localSettingsReducer);
const middlewares = [
  createStateSyncMiddleware({
    blacklist: ["persist/PERSIST", "persist/REHYDRATE"],
  }),
];

const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: middlewares,
});

initStateWithPrevTab(store);

export const storeConfiguration = () => {
  let persistor = persistStore(store);
  return { store: store, persistor: persistor };
};
export const connectToStore = () => {
  return storeConfiguration().store.getState();
};
