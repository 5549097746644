import Utils from "./Utils";
import { getBranch } from "../data/localSettingsActions";
import { VehicleTypes } from "../core/Constants";
export default class AutoMobileHelper {
  static GetFuelTypes() {
    var branch = getBranch();
    if (!Utils.IsNullOrEmptyObject(branch)) {
      if (branch.vehicleType == VehicleTypes.FourWheeler)
        return ["Petrol", "Diesel", "CNG", "LPG", "Electric"];
      else if (branch.vehicleType == VehicleTypes.TwoWheeler)
        return ["Petrol", "Electric"];
    }
  }
  static GetJobTypes() {
    var branch = getBranch();
    if (!Utils.IsNullOrEmptyObject(branch)) {
      if (branch.vehicleType == VehicleTypes.FourWheeler) {
        return [
          "REPEAT JOB",
          "MINOR",
          "BODY SHOP",
          "TYRE SERVICE",
          "WATER WASH",
          "G/S",
          "A/C",
          "ECE",
          "TINKERING & PAINTING",
          "CARBON CLEANING",
        ];
      } else if (branch.vehicleType == VehicleTypes.TwoWheeler)
        return [
          "REPEAT JOB",
          "MINOR",
          "BODY SHOP",
          "TYRE SERVICE",
          "WATER WASH",
          "G/S",
          "ECE",
          "TINKERING & PAINTING",
          "CARBON CLEANING",
        ];
    }
  }
  static GetInventoryItems() {
    var branch = getBranch();
    if (!Utils.IsNullOrEmptyObject(branch)) {
      if (branch.vehicleType == VehicleTypes.FourWheeler) {
        return [
          { name: "JACK & HANDLE", isChecked: false },
          { name: "STEPNEY", isChecked: false },
          { name: "TOOL KIT", isChecked: false },
          { name: "AUDIO PLAYER", isChecked: false },
          { name: "AF", isChecked: false },
          { name: "MOBILE CHARGER", isChecked: false },
          { name: "SIDE/CENTER MIRRORS", isChecked: false },
          { name: "BATTERY", isChecked: true },
          { name: "MUDFLAP", isChecked: true },
          { name: "MATS", isChecked: true },
          { name: "TYRE", isChecked: true },
          { name: "WHEEL CUP", isChecked: true },
          { name: "WHEEL COVERS", isChecked: true },
        ];
      } else if (branch.vehicleType == VehicleTypes.TwoWheeler) {
        return [
          { name: "TOOL KIT", isChecked: false },
          { name: "LIGHTS", isChecked: true },
          { name: "INDICATORS", isChecked: true },
          { name: "FUEL TANK COVER", isChecked: false },
          { name: "HANDLEBAR GRIPS", isChecked: false },
          { name: "FLOOR MAT", isChecked: false },
          { name: "FIRST AID", isChecked: false },
          { name: "VEHICLE DOCUMENTS", isChecked: false },
        ];
      }
    }
  }
  static getEmirate() {
    return [
      "Not Applicable",
      "Abu Dhabi",
      "Ajman",
      "Dubai",
      "Fujairah",
      "Ras Al Khaimah",
      "Sharjah",
      "Umm Al Quwain",
    ];
  }
}
