import React, { useState, useEffect } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom/";
import BusinessHelper from "../core/BusinessHelper";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import NumericTextField from "./common/controls/NumericTextField";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import SideBarMenuItems from "./SideBarMenuItems";
import SettingsMenuItems from "./SettingsMenuItems";
import {
  SideBarMenuNames,
  SettingsMenuList,
  UserRoles,
  AlertSMSNotSupported,
} from "../core/Constants";
import CountryHelper from "../core/CountryHelper";
import ConfirmPopup from "./common/ConfirmPopup";
import { ConfirmTypes } from "../core/Constants";
import { Enterprises } from "../core/Constants";
import { postAsync } from "../core/serviceClient";
import { useDispatch } from "react-redux";
import { connectToStore } from "../data/store";
import UserHelper from "../core/UserHelper";
import { useLocation } from "react-router-dom/";
import Utils from "../core/Utils";
import { LoginPage, AddNewUserPage, UsersPage } from "../core/PageConstants";

const AddNewUser = () => {
  document.title = "AddNewUser";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [localSettings, setLocalSettings] = useState({});
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [Jobtitle, setJobTitle] = useState("");

  const [active, setActive] = useState(true);
  const [isUserSetDisabled, setIsUserSetDisabled] = useState(true);
  const [serviceStaff, setServiceStaff] = useState(true);
  const [POSid, setPOSid] = useState("");
  const [POSpin, setPOSpin] = useState("");
  const [role, setRole] = useState("");

  // payment Setting links
  const [createLink, setCreateLink] = useState(false);
  const [cancelLink, setCancelLink] = useState(false);
  const [refundLink, setRefundLink] = useState(false);

  const [userloginip, setUserLoginIp] = useState("Email");
  const [hidecusdetails, setHidecusdetails] = useState(false);
  const [canadditm, setCanadditm] = useState(false);
  const [canedititm, setCanEdititm] = useState(false);
  const [candeleteitm, setcanDeleteitm] = useState(false);

  const [isNavigateToUser, setIsNavigateToUser] = useState(false);
  const [branches, setBranches] = useState([]);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [confirmFor, setConfirmFor] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedUser, setSelectedUser] = useState(
    location.state && location.state.selectedUser
      ? location.state.selectedUser
      : {}
  );

  const [editUser, setEditUser] = useState(
    !Utils.IsNullOrEmptyObject(location.state) &&
    !Utils.IsNullOrEmptyObject(location.state.selectedUser)
  );
  const handleInputforuser = (e) => {
    if(!localSettings.country.SMSEnabled && e.target.value === "Mobile")
      {
        setMsgOpen(true);
        setMsgText(AlertSMSNotSupported);
        setUserLoginIp("Email");
        return;
      }
    setUserLoginIp(e.target.value);
  };
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    } else if (isNavigateToUser) navigate(UsersPage.Path);
  };
  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };
  const handleInputChange = (e) => {
    setPOSid(parseInt(e.target.value) + 1);
  };
  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (confirmFor == "Submit") {
        UserSubmit();
      }
      if (confirmFor == "Update") {
        editUserDetails();
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsg = "";
    if (userloginip === "Email") {
      if (!Utils.IsEmailValid(email)) {
        errorMsg += "Please enter a valid Email.<br />";
      }
    } else {
      if (!Utils.IsNullOrEmpty(email) && !Utils.IsEmailValid(email))
        errorMsg += "Please enter a valid Email.<br />";
    }

    if (Utils.IsNullOrEmpty(firstName)) {
      errorMsg += "Please enter a proper First Name.<br />";
    }
    if (Utils.IsNullOrEmpty(Jobtitle)) {
      errorMsg += "Please enter a proper Jobtitle.<br />";
    }
    if (!Utils.IsMobileValid(mobileNumber)) {
      errorMsg += "Please enter a Valid Mobile Number.<br />";
    }
    if (Utils.IsNullOrEmpty(role)) {
      errorMsg += "Please enter a Role for a User.<br />";
    }
    if (Utils.IsNullOrEmpty(selectedBranch)) {
      errorMsg += "Please Select Branch.<br />";
    }
    if (!Utils.IsNullOrEmpty(errorMsg)) {
      setMsgText(errorMsg);
      setMsgOpen(true);
    } else {
      if (!Utils.IsNullOrEmptyObject(selectedUser)) {
        setConfirmFor("Update");
      } else {
        setConfirmFor("Submit");
      }
      setConfirmText("Are you sure you want to save changes?");
      setConfirmOpen(true);
    }
  };
  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(AddNewUserPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
      if (
        !Utils.IsNullOrEmptyObject(localSetting.business) &&
        !Utils.IsNullOrEmptyArray(localSetting.business.branchLocations)
      ) {
        setBranches(localSetting.business.branchLocations);
        setSelectedBranch(localSetting.branch.branchId);
      }
    }
  }, []);
  useEffect(() => {
    getUsers();
  }, [localSettings, branches]);
  const getUsers = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result = await postAsync(
        "Account/GetAccount",
        {},
        "",
        "GetAll",
        "AllUsersForBusiness",
        [localSettings.business.id]
      );

      if (!Utils.IsNullOrEmptyArray(result.data)) {
        var filteredPOSID = result.data.filter((item) => item.posId);
        var posIdArray = filteredPOSID.map((item) => item.posId);
        var highestNumber = Math.max(...posIdArray);
        var incrementedNumber = highestNumber + 1;

        if (POSid === "") {
          setPOSid(incrementedNumber);
        } else {
          setPOSid(selectedUser.posId);
        }
      } else if (result.error) {
        setMsgOpen(true);
        setMsgText(result.errorMessage);
      }
      setIsLoading(false);
    }
  };
  async function UserSubmit() {
    let User = {
      Id: userloginip === "Email" ? email : mobileNumber,
      BusinessId: localSettings.business.id,
      Branchid: selectedBranch,
      Active: active,
      FirstName: firstName,
      LastName: lastname,
      EmailId: email,
      POSId: POSid,
      POSPin: POSpin,
      Role: role,
      LogInWith: userloginip,
      MobileNumber: mobileNumber,
      ServiceStaff: serviceStaff,
      CanAddProduct: canadditm,
      CanEditProduct: canedititm,
      CanDeleteProduct: candeleteitm,
      JobTitle: Jobtitle,
      maskCustomerDetails: hidecusdetails,
      CompanyName: localSettings.business.companyName,
      UserName: firstName + " " + lastname,
      CountryCode: localSettings.branch.countryCode,
      EnterpriseCode: Enterprises.XUVI,
    };

    let result = await postAsync(
      "Account/SaveUser",
      {
        BusinessId: localSettings.business.id,
        UserId: userloginip === "Email" ? email : mobileNumber,
        NewUser: true,
        User: User,
        ProfileImage: "",
      },
      "",
      "Execute",
      "",
      []
    );

    if (result && result.data) {
      setIsNavigateToUser(true);
      setMsgText("User has been added sucessfully");
      setMsgOpen(true);
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }
  async function editUserDetails() {
    let ss = selectedUser;
    ss["Id"] = selectedUser.id;

    ss["BusinessId"] = localSettings.business.id;
    ss["Active"] = active;
    ss["LogInWith"] = userloginip;
    ss["FirstName"] = firstName;
    ss["LastName"] = lastname;
    ss["BranchId"] = selectedBranch;
    ss["EmailId"] = email;
    ss["POSId"] = POSid;
    ss["POSPin"] = POSpin;
    ss["Role"] = role;
    ss["ServiceStaff"] = serviceStaff;
    ss["CanAddProduct"] = canadditm;
    ss["MaskCustomerDetails"] = hidecusdetails;
    ss["CanEditProduct"] = canedititm;
    ss["CanDeleteProduct"] = candeleteitm;
    ss["MobileNumber"] = mobileNumber;
    ss["CompanyName"] = branches.find(
      (b) => b.branchId === selectedBranch
    ).branchName;

    let result = await postAsync(
      "Account/SaveUser",
      {
        BusinessId: localSettings.business.id,
        NewUser: false,
        User: ss,
      },
      "",
      "Execute",
      "",
      []
    );

    if (result && result.data) {
      setIsNavigateToUser(true);

      setMsgText("Changes saved Sucessfully");
      setMsgOpen(true);
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  const applyUserSettings = (value) => {
    setIsUserSetDisabled(value);
    setCanadditm(value);
    setCanEdititm(value);
    setcanDeleteitm(value);
  };
  useEffect(() => {
    populateUser();
  }, [selectedUser]);

  const populateUser = () => {
    if (Utils.IsNullOrEmptyObject(selectedUser)) {
      return;
    }
    setUserLoginIp(selectedUser.logInWith);
    setEmail(selectedUser.emailId);
    setCanEdititm(selectedUser.canEditProduct);
    setCanadditm(selectedUser.canAddProduct);
    setFirstName(selectedUser.firstName);
    setHidecusdetails(selectedUser.maskCustomerDetails);
    setMobileNumber(selectedUser.mobileNumber);
    setActive(selectedUser.active);
    setServiceStaff(selectedUser.serviceStaff);
    setPOSid(selectedUser.posId);
    setPOSpin(selectedUser.posPin);
    setRole(selectedUser.role);
    setLastname(selectedUser.lastName);
    setJobTitle(selectedUser.jobTitle);
    setcanDeleteitm(selectedUser.canDeleteProduct);
    setSelectedBranch(selectedUser.branchId);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec white-bg">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.BusinessSettings.UserSetup}
              />
              <Grid flex="2" spacing={2} className="form-sec">
                <Grid container className="title-sec title-xs">
                  <Stack
                    direction="row"
                    spacing={2}
                    className="justify-content-between"
                  >
                    <Typography
                      level="h2"
                      component="h2"
                      fontWeight="600"
                      fontSize="2rem"
                      className="page-title text-center"
                    >
                      User Setup
                    </Typography>
                  </Stack>
                </Grid>
                <form
                  container
                  spacing={2}
                  onSubmit={handleSubmit}
                  style={{ paddingBottom: "30px" }}
                  className="font-size-14"
                >
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <Grid className="card" padding={2}>
                        <h3 fontWeight="600" fontSize="16px" className="mt-0">
                          Select the method of signing in for User
                        </h3>
                        <RadioGroup
                          aria-label="role"
                          name="role"
                          value={userloginip}
                          onChange={(e) => handleInputforuser(e)}
                          InputLabelProps={{ shrink: true }}
                        >
                          <Stack direction="row">
                            <FormControlLabel
                              value={"Email"}
                              disabled={editUser}
                              control={<Radio />}
                              label="Email"
                            />
                            <FormControlLabel
                              value={"Mobile"}
                              disabled={editUser}
                              control={<Radio />}
                              label="Mobile Number"
                            />
                          </Stack>
                        </RadioGroup>
                      </Grid>
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack spacing={2}>
                    {userloginip === "Email" ? (
                      <>
                        <FormControl fullWidth>
                          <TextField
                            label="Email"
                            value={email}
                            disabled={selectedUser.logInWith == "Email"}
                            onChange={(e) => setEmail(e.target.value)}
                            error={!email}
                          />
                        </FormControl>
                      </>) : (
                      <>
                        <FormControl fullWidth>
                          <TextField
                            label="Email"
                            value={email}
                            disabled={selectedUser.logInWith == "Email"}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>
                      </>)}
                  </Stack>
                  <br />
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <TextField
                        label="First Name"
                        type="text"
                        error={!firstName}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        label="Last Name"
                        type="text"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                      />
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack>
                    <FormControl fullWidth>
                      <TextField
                        label="Job title"
                        type="text"
                        value={Jobtitle}
                        error={!Jobtitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                      />
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack direction="row" spacing={1}>
                    <FormControl fullWidth>
                      <NumericTextField
                        label="Mobile Number "
                        type="tel"
                        disabled={selectedUser.logInWith == "Mobile"}
                        value={mobileNumber}
                        setValue={setMobileNumber}
                        error={!Utils.IsMobileValid(mobileNumber)}
                        inputProps={{
                          maxLength: CountryHelper.GetMobileNumberMaxLength(),
                        }}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <Box>
                        <FormControlLabel style={{ marginLeft: "50px", marginTop: "10px" }}
                          control={
                            <Switch
                              checked={active}
                              onChange={(e) => setActive(e.target.checked)}
                              InputLabelProps={{ shrink: true }}
                              name="Active"
                              color="primary"
                            />
                          }
                          label="Active"
                          labelPlacement="start"
                        />
                      </Box>
                    </FormControl>

                    <FormControl fullWidth style={{ marginTop: "10px" }}>
                      <Box>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={serviceStaff}
                              onChange={(e) =>
                                setServiceStaff(e.target.checked)
                              }
                              InputLabelProps={{ shrink: true }}
                              name="Service Staff"
                              color="primary"
                            />
                          }
                          label="Service Staff"
                          labelPlacement="start"
                        />
                      </Box>
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack direction="row" spacing={1}>
                    <FormControl fullWidth>
                      <TextField
                        label="POS Id"
                        type="number"
                        autoComplete="on"
                        value={POSid}
                        disabled
                        onChange={handleInputChange}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <NumericTextField
                        label="POS Pin"
                        inputProps={{ maxLength: 4 }}
                        value={POSpin}
                        setValue={setPOSpin}
                        placeholder="PIN"
                      />
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack
                    direction="column"
                    spacing={1}
                    className="card"
                    padding="10px"
                    style={{
                      borderColor: !role ? '#f00' : 'lightgray',
                      borderWidth: '1px',
                      borderStyle: 'solid'
                    }}
                  >
                    <h3 fontWeight="600" fontSize="16px" className="mt-0">
                      Role
                    </h3>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="role"
                        name="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <Stack direction="row" justifyContent="space-between">
                          <FormControlLabel
                            value={UserRoles.GlobalAdmin}
                            control={<Radio />}
                            label="Global Admin"
                            onClick={() => {
                              if (
                                !Utils.IsNullOrEmptyObject(
                                  localSettings.user
                                ) &&
                                localSettings.user.role ===
                                UserRoles.GlobalAdmin
                              )
                                applyUserSettings(true);
                            }}
                            disabled={
                              !Utils.IsNullOrEmptyObject(localSettings.user) &&
                              localSettings.user.role == UserRoles.BranchAdmin
                            }
                          />

                          <FormControlLabel
                            value={UserRoles.BranchAdmin}
                            control={<Radio />}
                            label="Branch Admin"
                            onClick={() => applyUserSettings(true)}
                          />

                          <FormControlLabel
                            value={UserRoles.Operator}
                            control={<Radio />}
                            label="Operator"
                            onClick={() => applyUserSettings(false)}
                          />
                          {BusinessHelper.IsAutomobile() && (
                            <FormControlLabel
                              value={UserRoles.Technician}
                              control={<Radio />}
                              label="Technician"
                              onClick={() => applyUserSettings(false)}
                            />
                          )}
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack className="card" padding="10px">
                    <h3 fontWeight="600" fontSize="16px" className="mt-0">
                      User settings
                    </h3>
                    <Stack direction="row">
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isUserSetDisabled}
                                checked={hidecusdetails}
                                onChange={(e) =>
                                  setHidecusdetails(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Hide Customer Details"
                                color="primary"
                              />
                            }
                            label="Hide Customer Details"
                            labelPlacement="end"
                          />
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isUserSetDisabled}
                                checked={canadditm}
                                onChange={(e) => setCanadditm(e.target.checked)}
                                InputLabelProps={{ shrink: true }}
                                name="Hide Customer Details"
                                color="primary"
                              />
                            }
                            label="Can Add Item"
                            labelPlacement="end"
                          />
                        </Box>
                      </FormControl>
                    </Stack>
                    <Stack direction="row">
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isUserSetDisabled}
                                checked={canedititm}
                                onChange={(e) =>
                                  setCanEdititm(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Hide Customer Details"
                                color="primary"
                              />
                            }
                            label="Can Edit Item"
                            labelPlacement="end"
                          />
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isUserSetDisabled}
                                checked={candeleteitm}
                                onChange={(e) =>
                                  setcanDeleteitm(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Hide Customer Details"
                                color="primary"
                              />
                            }
                            label="Can Delete Item"
                            labelPlacement="end"
                          />
                        </Box>
                      </FormControl>
                    </Stack>
                  </Stack>
                  <br />
                  <Stack className="card" padding="10px">
                    <h3 fontWeight="600" fontSize="16px" className="mt-0">
                      Payment Link Settings
                    </h3>
                    <Stack direction="row">
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isUserSetDisabled}
                                checked={createLink}
                                onChange={(e) =>
                                  setCreateLink(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Create"
                                color="primary"
                              />
                            }
                            label="Create"
                            labelPlacement="end"
                          />
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isUserSetDisabled}
                                checked={cancelLink}
                                onChange={(e) => setCancelLink(e.target.checked)}
                                InputLabelProps={{ shrink: true }}
                                name="Cancel"
                                color="primary"
                              />
                            }
                            label="Cancel"
                            labelPlacement="end"
                          />
                        </Box>
                      </FormControl>
                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                disabled={isUserSetDisabled}
                                checked={refundLink}
                                onChange={(e) =>
                                  setRefundLink(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Refund"
                                color="primary"
                              />
                            }
                            label="Refund"
                            labelPlacement="end"
                          />
                        </Box>
                      </FormControl>
                    </Stack>
                  </Stack>
                  <br />
                  <Stack className="card">
                    <Typography
                      component="small"
                      fontWeight="790"
                      style={{ fontSize: "18px" }}
                      padding="10px 10px 0"
                    >
                      Select Branch
                    </Typography>
                    <Stack direction="row">
                      <RadioGroup
                        value={selectedBranch}
                        onChange={handleBranchChange}
                        style={{ width: "100%" }}
                      >
                        <Stack>
                          {branches.map((rows, index) => (
                            <FormControlLabel
                              className="branch-list m-0"
                              key={index}
                              value={rows.branchId}
                              label={
                                <Stack>
                                  <Typography
                                    component="strong"
                                    fontSize={"1.5rem"}
                                  >
                                    {rows.branchName}
                                  </Typography>
                                  <Typography component="small">
                                    {rows.city}
                                  </Typography>
                                </Stack>
                              }
                              control={
                                <Radio
                                  checked={rows.branchId === selectedBranch}
                                  size="medium"
                                />
                              }
                            />
                          ))}
                        </Stack>
                      </RadioGroup>
                    </Stack>
                  </Stack>
                  <br />

                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="flex-end"
                    className="fixed-btn"
                  >
                    <FormControl>
                      <Button
                        className="btn btn-primary"
                        variant="contained"
                        type="submit"
                      >
                        Save
                      </Button>
                    </FormControl>
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
export default AddNewUser;
