import {
  resetStore,
  getUser,
  getAdmin,
  getAuthentication,
  getBusiness,
} from "../data/localSettingsActions";
import { UserRoles } from "../core/Constants";
import Utils from "./Utils";
import {
  AMCPlanPage,
  AddNewBranchPage,
  AddNewConsumablePage,
  AddNewServicePage,
  AddNewSparePage,
  AddNewUserPage,
  BranchSetupPage,
  BusinessSetupPage,
  DeliveredJobCardDetailsPage,
  EmployeeReportPage,
  ExpensePage,
  ExpensesReportPage,
  InsurancePage,
  InvoiceReportPage,
  JobCardDetailsPage,
  JobcardsReportPage,
  LoginPage,
  OverviewPage,
  PaymentLinksPage,
  ProfitAndLossReportPage,
  StaffReportPage,
  StatementReportPage,
  UPISetupPage,
  UnAuthorizedPage,
  UsersPage,
  VehiclesPage,
  AMCViewPlanPage,
} from "./PageConstants";
import BusinessHelper from "./BusinessHelper";
import { CanRaisePaymentLink } from "./ModuleHelper";

export default class UserHelper {
  static CheckPermission(page, navigate, localSettings = null) {
    let rValue = false,
      isAuthenticated = false;
    if (!Utils.IsNullOrEmptyObject(localSettings))
      isAuthenticated = localSettings.authenticated;
    else isAuthenticated = getAuthentication();
    if (!isAuthenticated) {
      navigate(LoginPage.Path);
      rValue = false;
      return rValue;
    } else {
      switch (page.Name) {
        case VehiclesPage.Name:
        case JobCardDetailsPage.Name:
        case DeliveredJobCardDetailsPage.Name:
        case InsurancePage.Name:
          if (!BusinessHelper.IsAutomobile()) rValue = false;
          else rValue = true;
          break;
        case AMCPlanPage.Name:
        case AMCViewPlanPage.Name:
          if (BusinessHelper.IsAutomobile() || BusinessHelper.IsSalon())
            rValue = true;
          else rValue = false;
          break;
        case BusinessSetupPage.Name:
          if (!this.IsGlobalAdmin()) rValue = false;
          else rValue = true;
          break;
        case BranchSetupPage.Name:
        case AddNewBranchPage.Name:
        case UsersPage.Name:
        case AddNewUserPage.Name:
        case UPISetupPage.Name:
        case InvoiceReportPage.Name:
        case StaffReportPage.Name:
        case EmployeeReportPage.Name:
        case ProfitAndLossReportPage.Name:
        case JobcardsReportPage.Name:
        case StatementReportPage.Name:
        case InvoiceReportPage.Name:
          if (this.IsOperator() || this.IsTechnician()) rValue = false;
          else rValue = true;
          break;
        case AddNewConsumablePage.Name:
        case AddNewServicePage.Name:
        case AddNewSparePage.Name:
        case ExpensesReportPage.Name:
          if (
            this.IsOperator() ||
            this.IsTechnician() ||
            !this.CanAdd() ||
            !this.CanEdit()
          )
            rValue = false;
          else rValue = true;
          break;
        case PaymentLinksPage.Name:
          var _business = Utils.IsNullOrEmptyObject(localSettings)
            ? getBusiness()
            : localSettings.business;
          if (
            Utils.IsNullOrEmptyObject(_business) ||
            !CanRaisePaymentLink(_business)
          ) {
            rValue = false;
          } else {
            rValue = true;
          }
        default:
          rValue = true;
          break;
      }
    }
    if (!rValue) navigate(UnAuthorizedPage.Path);

    return rValue;
  }

  static IsStateValid(state, navigate) {
    if (Utils.IsNullOrEmptyObject(state)) {
      navigate(OverviewPage.Path);
      return false;
    }
    return true;
  }

  static IsXUVIAdmin() {
    return getAdmin();
  }
  static IsGlobalAdmin() {
    let userRole = this.GetUserRole();
    return userRole === UserRoles.GlobalAdmin;
  }
  static IsBranchAdmin() {
    let userRole = this.GetUserRole();
    return userRole === UserRoles.BranchAdmin;
  }
  static IsOperator() {
    let userRole = this.GetUserRole();
    return userRole === UserRoles.Operator;
  }
  static IsTechnician() {
    let userRole = this.GetUserRole();
    return userRole === UserRoles.Technician;
  }
  static GetUserRole() {
    let user = getUser();
    return user.role;
  }

  static CanAdd() {
    let canAdd = false;
    let user = getUser();
    if (
      user.role === UserRoles.GlobalAdmin ||
      user.role == UserRoles.BranchAdmin
    )
      canAdd = true;
    else canAdd = user.canAddProduct;
    return canAdd;
  }

  static CanEdit() {
    let canEdit = false;
    let user = getUser();
    if (
      user.role === UserRoles.GlobalAdmin ||
      user.role == UserRoles.BranchAdmin
    )
      canEdit = true;
    else canEdit = user.canEditProduct;
    return canEdit;
  }

  static CanDelete() {
    let canDelete = false;
    let user = getUser();
    if (
      user.role === UserRoles.GlobalAdmin ||
      user.role == UserRoles.BranchAdmin
    )
      canDelete = true;
    else canDelete = user.canDeleteProduct;
    return canDelete;
  }
  S;

  static LogOut(dispatch) {
    resetStore(dispatch);
    localStorage.clear();
  }
}
