import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Utils from "../core/Utils";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import {
  Paper,
  Stack,
  Grid,
  Typography,
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { connectToStore } from "../data/store";
import { postAsync } from "../core/serviceClient";
import { ProductDetailsTypes } from "../core/Constants";
import BusinessHelper from "../core/BusinessHelper";

export default function OverviewProductDetails(props) {
  const { onClose, open, detailsType, startDT, endDT, ...other } = props;
  const [revenueDetails, setRevenueDetails] = useState([]);
  const [title, setTitle] = useState("");
  const [revenueDetailList, setRevenueDetailList] = useState([]);
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const handleRowClick = (event, row) => {};
  useEffect(() => {
    if (detailsType === ProductDetailsTypes.Consumables)
      setTitle(BusinessHelper.GetConsumeablePageTitle());
    else if (detailsType === ProductDetailsTypes.Spares)
      setTitle(BusinessHelper.GetSparePageTitle());
    else setTitle(BusinessHelper.GetServicePageTitle());
    if (open) revenueDetailsInit();
  }, [open]);

  const revenueDetailsInit = async () => {
    let response = await postAsync("Business/GetCustomerVisitsData", {
      BranchId: localSettings.branch.branchId,
      StartDT: Utils.ConvertToUTCDate(startDT),
      EndDT: Utils.ConvertToUTCDate(endDT),
    });
    if (response.error) {
      onClose(response);
    } else {
      setRevenueDetailList(response.data.visits);
      loadRevenueDetails(response.data.visits);
    }
  };

  const loadRevenueDetails = (data) => {
    var tempVisitDetails = data;
    if (Utils.IsNullOrEmptyArray(tempVisitDetails))
      tempVisitDetails = revenueDetailList;
    if (!Utils.IsNullOrEmptyArray(tempVisitDetails)) {
      var visitDetails = tempVisitDetails.flatMap((v) => v.serviceItems);
      let service = false,
        consumable = false;
      switch (detailsType) {
        case ProductDetailsTypes.Consumables:
          consumable = true;
          break;
        case ProductDetailsTypes.Services:
          service = true;
          break;
      }
      if (service) {
        visitDetails = visitDetails.filter(
          (v) =>
            (v.service === service || v.labour === service) &&
            v.material === consumable
        );
      } else {
        visitDetails = visitDetails.filter(
          (v) =>
            v.service === service &&
            v.labour === service &&
            v.material === consumable
        );
      }
      var groupedResult = Utils.GetGroupByArray(visitDetails, "productId");
      var result = groupedResult.map(function (item) {
        return {
          description: item.values[0].description,
          name: item.values[0].description,
          class: !Utils.IsNullOrEmpty(item.values[0].productClass)
            ? item.values[0].productClass
            : "",
          department: !Utils.IsNullOrEmpty(item.values[0].productDepartment)
            ? item.values[0].productDepartment
            : "",
          revenue: item.values.reduce(
            (a, v) =>
              (a =
                a + v.totalPriceIncludingTax - v.discountTotal - v.refundTotal),
            0
          ),
          quantity: item.values.reduce((a, v) => (a = a + v.quantity), 0),
          profit: item.values.reduce(
            (a, v) =>
              (a =
                a +
                v.totalPrice -
                v.discountTotal -
                v.refundTotal -
                v.quantity * v.cost),
            0
          ),
        };
      });
      setRevenueDetails(result);
    }
  };

  return (
    <Dialog
      className="dialogModal"
      sx={{
        "& .MuiDialog-paper": {
          minWidth: 800,
          maxWidth: 900,
          minHeight: 400,
          maxHeight: 600,
        },
      }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <h4>{title}</h4>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          xs={12}
          mt={2}
          container
          alignItems="stretch"
          className="font-size-14"
        >
          <TableContainer
            alignItems="stretch"
            component={Paper}
            className="data-grid"
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell className="text-right">QTY</TableCell>
                  <TableCell className="text-right">Profit</TableCell>
                  <TableCell className="text-right">Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!Utils.IsNullOrEmptyArray(revenueDetails) ? (
                  revenueDetails.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      onClick={(e) => handleRowClick(e, row)}
                    >
                      <TableCell component="td" scope="row">
                        <Stack direction="row" alignItems="center">
                          <Typography>{row.description}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        className="text-right"
                        component="td"
                        scope="row"
                      >
                        <Stack direction="column" alignContent="center">
                          <Typography>{row.quantity}</Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        className="text-right"
                        component="td"
                        scope="row"
                        align="right"
                      >
                        {Utils.GetCurrency(row.profit, localSettings.country)}
                      </TableCell>
                      <TableCell
                        className="text-right"
                        component="td"
                        scope="row"
                        align="right"
                      >
                        {Utils.GetCurrency(row.revenue, localSettings.country)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>
                      <Typography>No data found...</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

OverviewProductDetails.propTypes = {
  detailsType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  startDT: PropTypes.object.isRequired,
  endDT: PropTypes.object.isRequired,
};
