import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const InsuranceConfirmDialog = ({ open, close, yes, no }) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "50%", maxHeight: 300 } }}
      maxWidth="xs"
      open={open}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <h4>Insurance Details</h4>
        <IconButton aria-label="close" onClick={close}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Stack direction="column" alignItems="center">
            <Typography variant="h5" className="py-2">
              Do you want individual prices to be displayed in the Insurance
              Estimate document?
            </Typography>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          className="btn btn-primary white-text"
          variant="contained"
          onClick={yes}
        >
          Yes
        </Button>
        <Button
          autoFocus
          className="btn btn-secondary"
          color="primary"
          variant="contained"
          style={{ backgroundColor: "gray" }}
          onClick={no}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default InsuranceConfirmDialog;
