import DialogPopup from "./DialogPopup";
import PropTypes from 'prop-types';

export default function RetryPopup({ retryText, retryOpen, onRetry, retryTitle, ...other }) {
    return (
        <DialogPopup
            open={retryOpen}
            onClose={onRetry}
            content={retryText}
            isConfirm={false}
            titleText={retryTitle}
            okText="Retry"
            {...other}
        />
    );
}

RetryPopup.propTypes = {
    retryText: PropTypes.string.isRequired,
    retryOpen: PropTypes.bool.isRequired,
    onRetry: PropTypes.func.isRequired
};