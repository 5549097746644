import DialogPopup from "./DialogPopup";
import PropTypes from 'prop-types';

export default function ConfirmPopup(props) {
    const { confirmText, confirmOpen, onConfirmClose, ...other } = props;
    return (
        <DialogPopup
            open={confirmOpen}
            onClose={onConfirmClose}
            content={confirmText}
            isConfirm={true}
            {...other}
        />
    );
}

ConfirmPopup.propTypes = {
    confirmText: PropTypes.string.isRequired,
    confirmOpen: PropTypes.bool.isRequired,
    onConfirmClose: PropTypes.func.isRequired
};