import { SETTER } from "./localSettingsTypes";
import Utils from "../core/Utils";
import CountryHelper from "../core/CountryHelper";
import { India, UAE } from "../core/Constants";

export const initialState = {
  authenticated: false,
  user: "",
  business: "",
  products: [],
  campaigns: [],
  branch: "",
  admin: false,
  enterpriseId: "XUVI",
  amcPlans: [],
  stats: "",
  reservations: [],
  staff: [],
  customerGroups: [],
  businessEntities: [],
  makeAndModels: [],
  appConfiguration: "",
  token: "",
  publicKeyType: "",
  publicKey: "",
  paymentsKey: "",
  country: {},
  selectedCustomerPass: {},
  selectedVehicle: {},
  selectedVisit: {},
  navigationState: {},
  selectedServiceItems: [],
  isDarkMode: false,
  apiWhatsAppSupportNumbers: [],
  LocalWhatsAppSupportMobileNumber: [
    { Code: India.Code, MobileNumber: "9150033947" },
    { Code: UAE.Code, MobileNumber: "564242704" },
  ],
};

export const localSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SETTER.LOGINRESPONSE:
      let branch =
        !Utils.IsNullOrEmptyObject(action.payload.user) &&
        !Utils.IsNullOrEmptyObject(action.payload.businessEntityConfig)
          ? action.payload.businessEntityConfig.branchLocations.find(
              (o) => o.branchId === action.payload.user.branchId
            )
          : {};
      return {
        ...state,
        authenticated: true,
        user: action.payload.user,
        business: action.payload.businessEntityConfig,
        branch: branch,
        stats: action.payload.stats,
        amcPlans: action.payload.amcPlans,
        products: action.payload.products,
        campaigns: action.payload.campaigns,
        businessEntities: action.payload.businessEntities,
        admin: action.payload.admin,
        appConfiguration: action.payload.appConfiguration,
        reservations: action.payload.reservations,
        makeAndModels: action.payload.makeAndModels,
        paymentsKey: action.payload.paymentsKey,
        token: action.payload.token,
        publicKey: action.payload.publicKey,
        publicKeyType: action.payload.publicKeyType,
        country: branch.countryCode
          ? CountryHelper.GetCountryObject(branch.countryCode)
          : {},
        apiWhatsAppSupportNumbers: action.payload.whatsAppSupportMobileNumbers,
      };
    case SETTER.TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SETTER.BUSINESS:
      return {
        ...state,
        business: action.payload,
      };
    case SETTER.BRANCH:
      return {
        ...state,
        branch: action.payload,
      };
    case SETTER.PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case SETTER.COUNTRY:
      return {
        ...state,
        country: action.payload,
      };
    case SETTER.RESET:
      let newState = { ...initialState };
      newState.apiWhatsAppSupportNumbers = {
        ...state.apiWhatsAppSupportNumbers,
      };
      return newState;
    case SETTER.SELECTEDCUSTOMER:
      return {
        ...state,
        selectedCustomerPass: action.payload,
      };
    case SETTER.SELECTEDVEHICLE:
      return {
        ...state,
        selectedVehicle: action.payload,
      };
    case SETTER.SELECTEDVISIT:
      return {
        ...state,
        selectedVisit: action.payload,
      };
    case SETTER.NAVIGATIONSTATE:
      return {
        ...state,
        navigationState: action.payload,
      };
    case SETTER.CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
      };
    case SETTER.SELECTEDSERVICEITEMS:
      return {
        ...state,
        selectedServiceItems: action.payload,
      };
    case SETTER.ISDARKMODE:
      return {
        ...state,
        isDarkMode: action.payload,
      };
    default:
      return state;
  }
};
