import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Style.css";
import Utils from "../../core/Utils";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { SideBarMenuNames, SettingsMenuList } from "../../core/Constants";
import { Typography } from "@mui/material";
import { Grid, Box, Container } from "@mui/material";
import Loader from "../common/Loader";
import UserHelper from "../../core/UserHelper";
import { OnHandReportPage } from "../../core/PageConstants";

const OnHandReport = (props) => {
    document.title = "On-Hand";
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [localSettings, setLocalSettings] = useState(connectToStore());
    const [reportContent, setReportContent] = useState("");
    const checkAuthentication = () => {
        if (UserHelper.CheckPermission(OnHandReportPage, navigate, localSettings)) {
            setIsLoading(true);
            let url = "reports/GetStocksOnHand?";
            var response =
                Utils.GetServiceUrl() +
                url +
                "businessId=" +
                localSettings.business.id +
                "&token=" +
                localSettings.token +
                "&culture=" +
                localSettings.country.Culture;
            setReportContent(response);
            setTimeout(function () {
                setIsLoading(false);
            }.bind(this), 2000);
        }
    };
    useEffect(() => {
        checkAuthentication();
    }, []);

    const handleSubmit = async () => {
        setIsLoading(true);
        let url = "reports/GetStocksOnHand?";
        var response =
            Utils.GetServiceUrl() +
            url +
            "businessId=" +
            localSettings.business.id +
            "&token=" +
            localSettings.token +
            "&culture=" +
            localSettings.country.Culture;
        if (!Utils.IsNullOrEmpty(reportContent) && !reportContent.includes("branchId=")) {
            response = response + "&branchId=" +
                localSettings.branch.branchId;
        }
        setReportContent(response);
        setTimeout(function () {
            setIsLoading(false);
        }.bind(this), 2000);
    };
    return (
        <Container maxWidth="false" className="bg-color p-0">
            <Loader open={isLoading} />

            <Box>
                <Grid container m={0}>
                    <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
                    <Grid xs={12} className="content-sec">
                        <Grid container direction="row" className="">
                            <SettingsMenuItems
                                selectedMenu={SettingsMenuList.StockReports.OnHand}
                            />
                            <Grid flex="1" spacing={2} className="form-sec">
                                <form container spacing={2} onSubmit={handleSubmit}>
                                    <Grid container className="title-sec title-xs">
                                        <Grid xs={12} md={4} lg={4}>
                                            <Typography
                                                level="h1"
                                                component="h2"
                                                fontWeight="600"
                                                fontSize="20px"
                                            >
                                                On-Hand
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </form>

                                <iframe
                                    className="reportIframe card"
                                    src={reportContent}
                                    style={{
                                        width: "100%",
                                        height: "calc(106vh - 200px)",
                                    }}
                                ></iframe>
                                <br />
                                <Grid
                                    container
                                    spacing={2}
                                    style={{
                                        justifyContent: "End",
                                    }}
                                >
                                    <Grid
                                        justifyContent="center"
                                        alignItems="bottom"
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <Button
                                                className="btn btn-primary"
                                                variant="contained"
                                                type="submit"
                                                onClick={handleSubmit}
                                            >
                                                Refresh
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default OnHandReport;
