import * as React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ConfirmTypes } from "../../core/Constants";
import Utils from "../../core/Utils";

export default function DialogPopup(props) {
  const {
    content,
    onClose,
    open,
    isConfirm,
    title,
    okText,
    cancelText,
    titleText,
    ...other
  } = props;

  const handleCancel = () => {
    onClose(ConfirmTypes.CANCEL);
  };
  const handleClose = () => {
    onClose(ConfirmTypes.CLOSE);
  };
  const handleOk = () => {
    onClose(isConfirm ? ConfirmTypes.OK : ConfirmTypes.CLOSE);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 300 } }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="dialog-title"
      >
        {Utils.IsNullOrEmpty(titleText)
          ? title
            ? title
            : isConfirm
            ? "Confirm"
            : "Alert"
          : titleText}
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          <Typography
            variant="h5"
            className="py-2"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {isConfirm && (
          <Button
            variant="contained"
            className="btn btn-secondary"
            onClick={handleCancel}
          >
            {cancelText ? cancelText : "Cancel"}
          </Button>
        )}
        <Button
          autoFocus
          variant="contained"
          className="btn btn-primary"
          onClick={handleOk}
        >
          {okText ? okText : "Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DialogPopup.propTypes = {
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
