import React from "react";
import "../Style.css";
import "../bootstrap.min.css";
import Switch from "@mui/material/Switch";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Loader from "./common/Loader";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Input from "@mui/material/Input";
import { MaxFileSizeLimit } from "../core/Constants";
import { ConfirmTypes } from "../core/Constants";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDispatch } from "react-redux";
import { postAsync } from "../core/serviceClient";
import { connectToStore } from "../data/store";
import UserHelper from "../core/UserHelper";
import MessagePopup from "./common/MessagePopUp";
import SideBarMenuItems from "./SideBarMenuItems";
import { SideBarMenuNames } from "../core/Constants";
import SettingsMenuItems from "./SettingsMenuItems";
import BusinessHelper from "../core/BusinessHelper";
import Utils from "../core/Utils";
import ConfirmPopup from "./common/ConfirmPopup";
import { ImageEnlarge } from "./common/ImageEnlarge";
import { LoginPage, AddNewSparePage, SparesPage } from "../core/PageConstants";
import SalesInfoComponent from "./common/SalesInfoComponent";

const AddNewSpare = (props) => {
  document.title = "spares";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [category, setCategory] = useState("");
  const location = useLocation();
  const [name, setName] = useState("");
  const [sru, setSru] = useState("");
  const [productCode, setProductCode] = useState("");
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState("");
  const [priceExclusive, setPriceExclusive] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");
  const [onHand, setOnHand] = useState("");
  const [minBuyQty, setMinBuyQty] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [isEnableOnHand, setIsEnableOnHand] = useState(false);
  const [trackInventory, setTrackInventory] = useState(false);
  const [frequencyUsed, setFrequencyUsed] = useState(false);
  const [active, setActive] = useState(true);
  const [totalPrice, setTotalPrice] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [upc, setupc] = useState("");
  const [sku, setsku] = useState("");
  const [isDropdown, setIsDropdown] = useState(false);
  const [isDropdown1, setIsDropdown1] = useState(false);
  const [department, setDepartment] = useState("");
  const [departmentDescription, setDepartmentDescription] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [mfrDepartment, setMfrDepartment] = useState("");
  const [mfrPartnumber, setMfrPartnumber] = useState("");
  const [vendor, setVendor] = useState("");
  const [vendorStockNo, setVendorStockNo] = useState("");
  const [hsn, setHSN] = useState("");
  const [measuringUnit, setMeasuringUnit] = useState("Number");
  const [Deptlist, setDeptlist] = useState([]);
  const [Catlist, setCatlist] = useState([]);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isNavigateToSpare, setIsNavigateToSpare] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [confirmFor, setConfirmFor] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [enlargeImage, setEnlargeImage] = useState(false);
  const [selectedSpare, setselectedSpare] = useState(
    location.state && location.state.selectedSpares
      ? location.state.selectedSpares
      : {}
  );
  const [inclusiveOfTax,setInclusiveOfTax] = useState(false);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    } else if (isNavigateToSpare) navigate(SparesPage.Path);
  };

  const handleSwitchChangeCat = (event) => {
    setIsDropdown1(event.target.checked);
    setCategory("");
  };

  const handleSwitchChange = (event) => {
    setIsDropdown(event.target.checked);
    setDepartment("");
  };

  async function editSparesDetails() {
    const imgObj = getImgObj();
    setIsLoading(true);
    let ss = selectedSpare;
    ss["Active"] = active;
    ss["Default"] = frequencyUsed;
    ss["trackInventory"] = trackInventory;
    ss["description"] = description;
    ss["shortDesc"] = name;
    ss["code"] = productCode;
    ss["upc"] = upc;
    ss["HSN"] = hsn;
    ss["color"] = color;
    ss["size"] = size;
    ss["cost"] = parseFloat(cost);
    ss["price"] = parseFloat(priceExclusive);
    ss["onHandQuantity"] = parseInt(onHand);
    ss["minBuyQuantity"] = parseInt(minBuyQty);
    ss["taxPercent"] = parseFloat(taxPercentage);
    ss["imageUrl"] = imgObj.url;
    //  if( !Utils.IsNullOrEmpty(imageUrl))
    //   ss["imageUrl"] = imageUrl.startsWith("http")
    //     ? imageUrl
    //     : Utils.IsNullOrEmpty(imageUrl)
    //     ? ""
    //     : Utils.GetStringFromBase64(imageUrl);
    //   var i =  imageUrl
    //   : Utils.IsNullOrEmpty(imageUrl)
    //   ?
    // imageUrl.startsWith("http")


    ss["sku"] = sku;
    ss["sru"] = sru;
    ss["class"] = category;
    ss["retailPrice"] = taxAmount;
    ss["measuringUnit"] = measuringUnit;
    ss["classDescription"] = categoryDescription;
    ss["price"] = priceExclusive;
    ss["department"] = department;
    ss["departmentDescription"] = departmentDescription;
    ss["manufacturer"] = manufacturer;
    ss["mfrDepartment"] = mfrDepartment;
    ss["mfrPartnumber"] = mfrPartnumber;
    ss["vendor"] = vendor;
    ss["vendorStockNumber"] = vendorStockNo;
    ss["inclusiveOfTax"] = inclusiveOfTax;
    ss["priceIncludingTax"] = inclusiveOfTax ? Utils.ConvertToFloat(totalPrice) : 0;

    let result = await postAsync(
      "Product/SaveProduct",
      {
        BusinessId: localSettings.business.id,
        NewProduct: false,
        Product: ss,
      },
      "",
      "Execute",
      "",
      [],
      imgObj.newFile
    );

    if (result && result.data) {
      setIsNavigateToSpare(true);

      setMsgText("Changes saved Sucessfully");
      setMsgOpen(true);
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let errorMsg = "";

    if (Utils.IsNullOrEmpty(name)) {
      errorMsg += "Please enter a proper Name.<br />";
    }

    if (Utils.IsNullOrEmpty(description)) {
      errorMsg += "Please enter a proper Description.<br />";
    }
    if (Utils.IsNullOrEmpty(productCode)) {
      errorMsg += "Please enter a valid Product Code.<br />";
    }
    if (Utils.IsNullOrEmpty(cost)) {
      errorMsg += "Please enter Cost.<br />";
    }
    if (Utils.IsNullOrEmpty(priceExclusive)) {
      errorMsg += "Please enter Price Exclusive Tax.<br />";
    }

    if (!Utils.IsNullOrEmpty(errorMsg)) {
      setMsgText(errorMsg);
      setMsgOpen(true);
    } else {
      if (!Utils.IsNullOrEmptyObject(selectedSpare)) {
        setConfirmFor("Update");
      } else {
        setConfirmFor("Submit");
      }
      setConfirmText("Are you sure you want to save changes?");
      setConfirmOpen(true);
    }
  };
  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (confirmFor == "Submit") {
        SparesSubmit();
      }
      if (confirmFor == "Update") {
        editSparesDetails();
      }
    }
  };

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(AddNewSparePage, navigate, localSetting)) {
      setLocalSettings(localSetting);
    }
  }, []);

  useEffect(() => {
    getSpares();
  }, [localSettings]);

  const getImgObj = () => {
    return {
      url: Utils.IsNullOrEmpty(imageUrl)
        ? ""
        : imageUrl.startsWith("http")
        ? imageUrl
        : Utils.GetStringFromBase64(imageUrl),
      newFile: !Utils.IsNullOrEmpty(imageUrl) && !imageUrl.startsWith("http"),
    };
  };

  async function SparesSubmit() {
    setIsLoading(true);
    const imgObj = getImgObj();
    var spare = {
      BusinessId: localSettings.business.id,
      Active: active,
      Default: frequencyUsed,
      TrackInventory: trackInventory,
      Description: description,
      ShortDesc: name,
      Code: productCode,
      HSN: hsn,
      Color: color,
      Size: size,
      Cost: Utils.IsNullOrEmpty(cost) ? 0 : parseFloat(cost),
      Price: Utils.IsNullOrEmpty(priceExclusive)
        ? 0
        : parseFloat(priceExclusive),
      OnHandQuantity: Utils.IsNullOrEmpty(onHand) ? 0 : parseInt(onHand),
      MinBuyQuantity: Utils.IsNullOrEmpty(minBuyQty) ? 0 : parseInt(minBuyQty),
      TaxPercent: Utils.IsNullOrEmpty(taxPercentage)
        ? 0
        : parseFloat(taxPercentage),
      imageUrl: imgObj.url,
      UPC: upc,
      RetailPrice: Utils.IsNullOrEmpty(taxAmount) ? 0 : parseInt(taxAmount),
      SKU: sku,
      MeasuringUnit: measuringUnit,
      Class: category,
      ClassDescription: categoryDescription,
      Vendor: vendor,
      VendorStockNumber: vendorStockNo,
      Manufacturer: manufacturer,
      ManufacturerDepartment: mfrDepartment,
      ManufacturerPartNumber: mfrPartnumber,
      Department: department,
      DepartmentDescription: departmentDescription,
      Service: false,
      IsMaterial: false,
      Labour: false,
      InclusiveOfTax: inclusiveOfTax,
      PriceIncludingTax : inclusiveOfTax ? Utils.ConvertToFloat(totalPrice) : 0
    };

    let result = await postAsync(
      "Product/SaveProduct",
      {
        BusinessId: localSettings.business.id,
        NewProduct: true,
        Product: spare,
      },
      "",
      "Execute",
      "",
      [],
      imgObj.newFile
    );
    if (result && result.data) {
      setIsNavigateToSpare(true);
      setMsgText("Spares Added Sucessfully");
      setMsgOpen(true);
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  const getSpares = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result1 = await postAsync(
        "Product/GetProduct",
        {},
        "",
        "GetAll",
        "AllProductsAndMaterialsForBusiness",
        [localSettings.business.id]
      );
      setIsNavigateToLogin(result1.isNavigateToLogin);
      if (result1.error) {
        setMsgOpen(true);
        setMsgText(result1.errorMessage);
      } else {
        if (!Utils.IsNullOrEmptyArray(result1.data)) {
          var Spares = result1.data.filter(
            (item) =>
              item.isMaterial == false &&
              item.service == false &&
              item.labour == false
          );
          const temDeptllist = [];
          const temCatllist = [];
          Spares.forEach((element) => {
            if (
              !Utils.IsNullOrEmpty(element.department) &&
              !temDeptllist.includes(element.department)
            ) {
              temDeptllist.push(element.department);
            }
            if (
              !Utils.IsNullOrEmpty(element.class) &&
              !temCatllist.includes(element.class)
            ) {
              temCatllist.push(element.class);
            }
          });
          setDeptlist(temDeptllist);
          setCatlist(temCatllist);
        }
      }
      setIsLoading(false);
    }
  };
  const UploadSpareImage = async (event) => {
    var file = event.target.files[0];

    if (file.size > MaxFileSizeLimit) {
      setAlertTitle("Warning");
      setMsgText(
        "Unable to upload. File size exceeds the maximum limit, please ensure that it's less than 10 MB."
      );
      setMsgOpen(true);
      return;
    }
    var base64 = await convertBase64(file);
    setImageUrl(base64);
  };

  const handleSparePhoto = (event) => {
    const fileInput = event.target;
    const ext = fileInput.value.match(/\.(.+)$/)[1];

    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
        UploadSpareImage(event);
        break;
      default:
        setMsgOpen(true);
        setMsgText("/jpg,/jpeg,/png file types only allowed .");
        fileInput.value = "";
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteSparePhoto = () => {
    setMsgText("Spare image has been removed");
    setMsgOpen(true);
    setImageUrl("");
  };

  const closeImage = () => {
    setEnlargeImage(false);
  };

  const openImage = () => {
    setEnlargeImage(true);
  };
  useEffect(() => {
    populateSpare();
  }, [selectedSpare]);

  const populateSpare = () => {
    if (Utils.IsNullOrEmptyObject(selectedSpare)) {
      setIsEnableOnHand(false);
      return;
    }
    setIsEnableOnHand(true);
    setName(selectedSpare.shortDesc);
    setSru(selectedSpare.sru);
    setProductCode(selectedSpare.code);
    setColor(selectedSpare.color);
    setSize(selectedSpare.size);
    setDescription(selectedSpare.description);
    setCost(selectedSpare.cost);
    setPriceExclusive(selectedSpare.price);
    setTaxPercentage(selectedSpare.taxPercent);
    setOnHand(selectedSpare.onHandQuantity);
    setMinBuyQty(selectedSpare.minBuyQuantity);
    setActive(selectedSpare.active);
    setTrackInventory(selectedSpare.trackInventory);
    setFrequencyUsed(selectedSpare.default);
    setActive(selectedSpare.active);
    setPriceExclusive(selectedSpare.price);
    setTaxAmount(selectedSpare.retailPrice);
    setMeasuringUnit(selectedSpare.measuringUnit);
    setupc(selectedSpare.upc);
    setImageUrl(selectedSpare.imageUrl);
    setsku(selectedSpare.sku);
    setHSN(selectedSpare.hsn);
    setCategory(selectedSpare.class);
    setCategoryDescription(selectedSpare.classDescription);
    setDepartment(selectedSpare.department);
    setDepartmentDescription(selectedSpare.departmentDescription);
    setManufacturer(selectedSpare.manufacturer);
    setMfrDepartment(selectedSpare.manufacturerDepartment);
    setMfrPartnumber(selectedSpare.manufacturerPartNumber);
    setVendor(selectedSpare.vendor);
    setVendorStockNo(selectedSpare.vendorStockNumber);
    setInclusiveOfTax(selectedSpare.inclusiveOfTax);
    setTotalPrice(Utils.IsNullOrEmpty(selectedSpare.priceIncludingTax) ? "" : selectedSpare.priceIncludingTax.toFixed(2));
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 30,
    p: 4,
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <ImageEnlarge
        open={enlargeImage}
        imgSrc={imageUrl}
        onClose={closeImage}
      />

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec white-bg">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={BusinessHelper.GetSpareMenuName()}
              />

              <Grid flex="1" spacing={2} className="form-sec">
                <Grid container className="title-sec title-xs">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="h2 text-center"
                  >
                    {BusinessHelper.GetSparePageTitle()}
                  </Typography>
                </Grid>
                <form
                  className="cs-form"
                  style={{ paddingBottom: "30px" }}
                  onSubmit={handleSubmit}
                >
                  <Stack direction="row" spacing={3}>
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="600"
                      fontSize="2rem"
                      className="h3"
                    >
                      Tap to select Image:
                    </Typography>
                    {Utils.IsNullOrEmpty(imageUrl) && (
                      <Stack direction="row" spacing={2}>
                        <label>
                          <AddAPhotoIcon fontSize="large" />
                          <Input
                            type="file"
                            name="myImage"
                            className="hide"
                            inputProps={{
                              accept: "image/png, image/jpeg, image/jpg",
                            }}
                            onChange={(e) => handleSparePhoto(e)}
                          />
                        </label>
                      </Stack>
                    )}

                    <Grid>
                      {!Utils.IsNullOrEmpty(imageUrl) && (
                        <div className="card imgBox">
                          <img
                            alt="not found"
                            width={"150px"}
                            src={imageUrl}
                            onClick={openImage}
                          />
                          <br />
                          <button
                            className="p-0 mt-1 btn btn-default"
                            onClick={deleteSparePhoto}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      )}
                    </Grid>
                  </Stack>
                  <br />
                  <Stack sx={{ minWidth: 120 }} spacing={2}>
                    <FormControl fullWidth>
                      <TextField
                        inputProps={{ style: { fontSize: 13 } }}
                        label="Name"
                        type="text"
                        value={name}
                        error={!name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormControl>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="SKU"
                          type="text"
                          value={sku}
                          onChange={(e) => setsku(e.target.value)}
                          placeholder="SKU"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{
                            style: { fontSize: 13 },
                          }}
                          label="Product Code"
                          type="text"
                          value={productCode}
                          error={!productCode}
                          onChange={(e) => setProductCode(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Color"
                          type="text"
                          value={color}
                          onChange={(e) => setColor(e.target.value)}
                          placeholder="Color"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Size"
                          type="text"
                          value={size}
                          onChange={(e) => setSize(e.target.value)}
                          placeholder="Size"
                        />
                      </FormControl>
                    </Stack>
                    <FormControl fullWidth>
                      <TextField
                        inputProps={{ style: { fontSize: 13 } }}
                        label="Description "
                        type="text"
                        error={!description}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </FormControl>
                    <Stack
                      direction="row"
                      spacing={2}
                      style={{
                        border: "1px solid #cccccc",
                        "border-radius": "4px",
                      }}
                    >
                      <FormControl fullWidth>
                        <Box fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={trackInventory}
                                onChange={(e) =>
                                  setTrackInventory(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="trackInventoryOption"
                                color="primary"
                              />
                            }
                            label="Track Inventory"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>

                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={frequencyUsed}
                                onChange={(e) =>
                                  setFrequencyUsed(e.target.checked)
                                }
                                InputLabelProps={{ shrink: true }}
                                name="Frequently Used"
                                color="primary"
                              />
                            }
                            label="Frequently Used"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>

                      <FormControl fullWidth>
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={active}
                                onChange={(e) => setActive(e.target.checked)}
                                InputLabelProps={{ shrink: true }}
                                name="Active"
                                color="primary"
                              />
                            }
                            value={active}
                            label="Active"
                            labelPlacement="start"
                          />
                        </Box>
                      </FormControl>
                    </Stack>

                    <Typography fontSize="1.4rem" fontWeight="600">
                      Measuring Unit
                    </Typography>
                    <FormControl fullWidth variant="outlined" margin="normal">
                      <Select
                        inputProps={{ style: { fontSize: 15 } }}
                        labelId="Measuring-label"
                        id="Measuring-select"
                        value={measuringUnit}
                        onChange={(e) => setMeasuringUnit(e.target.value)}
                      >
                        <MenuItem value={"Number"}>Number</MenuItem>
                        <MenuItem value={"Gram"}>Gram</MenuItem>
                        <MenuItem value={"Kilogram"}>Kilogram</MenuItem>
                        <MenuItem value={"Millilitre"}>Millilitre</MenuItem>
                        <MenuItem value={"Litre"}>Litre</MenuItem>
                        <MenuItem value={"Meter"}>Meter</MenuItem>
                      </Select>
                    </FormControl>

                    <SalesInfoComponent priceExclusive={priceExclusive}
                    setPriceExclusive={setPriceExclusive}
                    inclusiveOfTax={inclusiveOfTax}
                    setInclusiveOfTax={setInclusiveOfTax}
                    cost={cost}
                    setCost={setCost}
                    taxPercentage={taxPercentage}
                    setTaxPercentage={setTaxPercentage}
                    totalPrice={totalPrice}
                    setTotalPrice={setTotalPrice}
                    taxAmount={taxAmount}
                    setTaxAmount={setTaxAmount}/>

                    <strong>Inventory Info</strong>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          type="text"
                          id="onHand"
                          placeholder="On Hand"
                          label="On Hand"
                          disabled={isEnableOnHand}
                          value={onHand}
                          onChange={(e) => setOnHand(e.target.value)}
                        />
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          type="text"
                          id="minBuyQty"
                          placeholder="Min Buy Qty"
                          label="Min Buy Qty"
                          value={minBuyQty}
                          onChange={(e) => setMinBuyQty(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                    <Stack alignContent="space-between">
                      <Stack
                        direction="row"
                        alignItems="baseline"
                        justifyContent="space-between"
                      >
                        <strong>Enter New Category</strong>
                        <Stack alignContent="end">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={isDropdown1}
                                onChange={handleSwitchChangeCat}
                              />
                            }
                          />
                        </Stack>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        {!isDropdown1 ? (
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="normal"
                          >
                            <InputLabel id="category-label">
                              Category
                            </InputLabel>
                            <Select
                              inputProps={{
                                style: { fontSize: 24 },
                              }}
                              labelId="Category-label"
                              id="category-select"
                              value={category}
                              onChange={(e) => setCategory(e.target.value)}
                              label="Category"
                              style={{ height: "51.5px" }}
                              InputLabelProps={{ shrink: true }}
                            >
                              {!Utils.IsNullOrEmptyArray(Catlist) &&
                                Catlist.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField
                            inputProps={{ style: { fontSize: 13 } }}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Category"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                        <FormControl fullWidth>
                          <TextField
                            inputProps={{ style: { fontSize: 13 } }}
                            fullWidth
                            variant="outlined"
                            margin="0"
                            label="Category Description"
                            value={categoryDescription}
                            onChange={(e) =>
                              setCategoryDescription(e.target.value)
                            }
                          />
                        </FormControl>
                      </Stack>
                    </Stack>
                    <Stack alignContent="space-between">
                      <Stack
                        direction="row"
                        alignItems="baseline"
                        justifyContent="space-between"
                      >
                        <strong>Enter New Department</strong>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isDropdown}
                              onChange={handleSwitchChange}
                            />
                          }
                        />
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        {!isDropdown ? (
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="normal"
                          >
                            <InputLabel id="department-label">
                              Department
                            </InputLabel>
                            <Select
                              inputProps={{ style: { fontSize: 13 } }}
                              labelId="department-label"
                              id="department-select"
                              value={department}
                              onChange={(e) => setDepartment(e.target.value)}
                              label="Department"
                              style={{ height: "51.5px" }}
                            >
                              {!Utils.IsNullOrEmptyArray(Deptlist) &&
                                Deptlist.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField
                            inputProps={{ style: { fontSize: 13 } }}
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            label="Department"
                            value={department}
                            onChange={(e) => setDepartment(e.target.value)}
                          />
                        )}
                        <FormControl fullWidth>
                          <TextField
                            inputProps={{ style: { fontSize: 13 } }}
                            fullWidth
                            variant="outlined"
                            margin="0"
                            label="Department Description"
                            value={departmentDescription}
                            onChange={(e) =>
                              setDepartmentDescription(e.target.value)
                            }
                          />
                        </FormControl>
                      </Stack>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Manufacturer"
                          type="text"
                          value={manufacturer}
                          onChange={(e) => setManufacturer(e.target.value)}
                          placeholder="Manufacturer"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Mfr Department"
                          type="text"
                          value={mfrDepartment}
                          onChange={(e) => setMfrDepartment(e.target.value)}
                          placeholder="Mfr Department"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Mfr Part Number"
                          type="text"
                          value={mfrPartnumber}
                          onChange={(e) => setMfrPartnumber(e.target.value)}
                          placeholder="Part Number"
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Vendor"
                          type="text"
                          value={vendor}
                          onChange={(e) => setVendor(e.target.value)}
                          placeholder="Vendor"
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="Vendor Stock Number"
                          type="number"
                          value={vendorStockNo}
                          onChange={(e) => setVendorStockNo(e.target.value)}
                          placeholder="Vendor Stock Number"
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2}>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="UPC"
                          type="text"
                          id="upc"
                          placeholder="UPC"
                          value={upc}
                          onChange={(e) => setupc(e.target.value)}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          inputProps={{ style: { fontSize: 13 } }}
                          label="HSN"
                          type="text"
                          id="sku"
                          placeholder="HSN"
                          value={hsn}
                          onChange={(e) => setHSN(e.target.value)}
                        />
                      </FormControl>
                    </Stack>
                    <br />
                    {UserHelper.CanEdit() && (
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="flex-end"
                        className="fixed-btn"
                      >
                        <FormControl>
                          <Button
                            className="btn btn-primary"
                            variant="contained"
                            type="submit"
                          >
                            Save
                          </Button>
                        </FormControl>
                      </Stack>
                    )}
                  </Stack>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AddNewSpare;
