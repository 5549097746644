import React, { useState, useEffect } from "react";
import {
  TextField,
  Checkbox,
  FormGroup,
  Button,
  Container,
  Select,
  Box,
  InputLabel,
  MenuItem,
  Grid,
  Typography,
  Stack,
  FormControlLabel,
  FormControl,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DnsIcon from "@mui/icons-material/Dns";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";
import Utils from "../../core/Utils";
import { CustomerBookingErrorPage } from "../../core/PageConstants";
import Loader from "../common/Loader";
import MessagePopup from "../common/MessagePopUp";
import moment from "moment";
import { get, post } from "./helpers/customerBookingServiceClient";
import NumericTextField from "../common/controls/NumericTextField";
import { India, CustomerBookingActions } from "../../core/Constants";
import { getServiceItemFromProduct } from "../../helpers/productHelper";
import { SuccessDialog } from "../common/SuccessDialog";
import CountryHelper from "../../core/CountryHelper";

const CustomerBookingXUVI = ({ businessId, passId, branchId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState({});
  const [branches, setBranches] = useState([]);
  const [customerPass, setCustomerPass] = useState({});
  const [selectedBranch, setSelectedBranch] = useState({});
  const [canSelectBranch, setCanSelectBranch] = useState(true);
  const [selectedBranchString, setSelectedBranchString] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [reservationDate, setReservationDate] = useState(moment());
  const [reservationTime, setReservationTime] = useState(moment());
  const [newCustomer, setNewCustomer] = useState(true);
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [maximumAllowedDT, setMaximumAllowedDT] = useState(moment());
  const [bookDisabled, setBookDisabled] = useState(true);
  const [customerName, setCustomerName] = useState("");
  const [customerMobileNumber, setCustomerMobileNumber] = useState("");
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [customerPincode, setCustomerPincode] = useState("");
  const [reserveProducts, setReserveroducts] = useState(false);
  const [otp, setOTP] = useState("");
  const [openOTP, setOpenOTP] = useState(false);
  const [otpId, setOTPId] = useState("");
  const [customerBookingAction, setCustomerBookingAction] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");

  useEffect(() => {
    checkParams();
  }, []);

  const checkParams = async () => {
    if (Utils.IsNullOrEmpty(businessId)) {
      navigate(CustomerBookingErrorPage.Path, {
        state: { errorMessage: "This link is not valid." },
      });
      return;
    }
    setLoading(true);
    var result = await get(
      "CustomerBooking/GetBusinessAndCustomerPass",
      "businessId=" + businessId + "&passId=" + (passId ? passId : "")
    );
    setLoading(false);
    if (result.error) {
      navigate(CustomerBookingErrorPage.Path, {
        state: { errorMessage: result.errorMessage },
      });
      return;
    }
    const isNewCustomer = Utils.IsNullOrEmptyObject(result.data.customerPass);
    if (!isNewCustomer) {
      setCustomerPass(result.data.customerPass);
      setNewCustomer(false);
      setCustomerName(result.data.customerPass.userName);
      setCustomerMobileNumber(result.data.customerPass.mobileNumber);
      setCustomerEmailId(result.data.customerPass.emailId);
      setCustomerAddress(result.data.customer.homeLocation);
      setCustomerCity(result.data.customer.city);
      setCustomerState(result.data.customer.state);
      setCustomerPincode(result.data.customer.zipCode);
    }
    if (!Utils.IsNullOrEmpty(branchId)) {
      var branch = result.data.businessEntity.branchLocations.find(
        (b) => b.branchId === branchId
      );
      populateDetailsFromSingleBranch(branch, result.data.businessEntity);
    } else {
      var validBranches = result.data.businessEntity.branchLocations.filter(
        (b) => !b.reservationsDisabled
      );
      var branchIds = validBranches.map((b) => {
        return parseInt(b.branchId);
      });
      if (
        Utils.IsNullOrEmpty(validBranches) ||
        Utils.IsNullOrEmptyArray(validBranches)
      ) {
        navigate(CustomerBookingErrorPage.Path, {
          state: {
            errorMessage: "Reservations are not allowed for this business.",
          },
        });
        return;
      }
      var sortedBranches = branchIds.sort();
      var defaultBranchId =
        sortedBranches[0].toString().length === 1
          ? "00" + sortedBranches[0]
          : sortedBranches[0].toString().length === 2
            ? "0" + sortedBranches[0]
            : sortedBranches[0].toString();
      if (validBranches.length === 1) {
        populateDetailsFromSingleBranch(
          validBranches[0],
          result.data.businessEntity
        );
        return;
      }
      populateDetailsFromSingleBranch(
        validBranches.find((v) => v.branchId === defaultBranchId),
        result.data.businessEntity
      );
      setBranches(validBranches);
      setCanSelectBranch(true);
    }
  };

  const populateDetailsFromSingleBranch = (branchObj, businessObj) => {
    if (Utils.IsNullOrEmptyObject(branchObj)) {
      navigate(CustomerBookingErrorPage.Path, {
        state: { errorMessage: "Branch not found." },
      });
      return;
    }
    if (branchObj.reservationsDisabled) {
      navigate(CustomerBookingErrorPage.Path, {
        state: {
          errorMessage: "Reservations are not allowed for the current branch.",
        },
      });
      return;
    }
    setBusiness(businessObj);
    setSelectedBranch(branchObj);
    setSelectedBranchString(branchObj.branchName);
    setCanSelectBranch(false);
  };

  useEffect(() => {
    setBranchDetails();
  }, [selectedBranch]);

  const setBranchDetails = async () => {
    if (
      Utils.IsNullOrEmptyObject(selectedBranch) ||
      Utils.IsNullOrEmptyObject(business)
    ) {
      return;
    }
    if (selectedBranch.reserveProducts) {
      setLoading(true);
      var result = await get(
        "CustomerBooking/GetServices",
        "businessId=" + business.id
      );
      setLoading(false);
      if (result.error) {
        setReserveroducts(false);
        setServices([]);
        setAlertMessage(result.errorMessage);
        setShowAlert(true);
        return;
      } else {
        if (!Utils.IsNullOrEmptyArray(result.data)) {
          setReserveroducts(true);
          setServices(result.data);
        } else {
          setReserveroducts(false);
          setServices([]);
        }
      }
    }
    setMaximumAllowedDT(
      moment().add(selectedBranch.reservationMaxDays, "days")
    );
    populateTimeSlots(reservationDate.format("YYYY-MM-DDTHH:mm:ss"));
  };

  const populateTimeSlots = (reservationDT) => {
    setSelectedTimeSlot('');
    const standardStartTime = moment(selectedBranch.serviceStartTime);
    const standardEndTime = moment(selectedBranch.serviceEndTime);
    var reservationDTMoment = moment(reservationDT).utc();
    var selectedStartDT = reservationDTMoment
      .set({
        hour: standardStartTime.get("hour"),
        minute: standardStartTime.get("minute"),
        second: standardStartTime.get("second"),
      })
      .format("YYYY-MM-DDTHH:mm:ss");
    var selectedEndDT = reservationDTMoment
      .set({
        hour: standardEndTime.get("hour"),
        minute: standardEndTime.get("minute"),
        second: standardEndTime.get("second"),
      })
      .format("YYYY-MM-DDTHH:mm:ss");
    var startDT = moment(selectedStartDT);
    var endDT = moment(selectedEndDT);
    var totalMinutes = endDT.diff(startDT, "minutes");
    var slots = totalMinutes / selectedBranch.reservationTimeInterval;
    var startTime = startDT.utc();
    var slotsArr = [];
    for (var i = 0; i < slots; i++) {
      if (i > 0) {
        startTime = startTime.add(
          selectedBranch.reservationTimeInterval,
          "minutes"
        );
      }
      var timeSlot = {
        key: startTime.format("LT"),
        value: startTime.format("YYYY-MM-DDTHH:mm:ss"),
      };
      if (moment(timeSlot.value).isAfter(moment())) {
        slotsArr.push(timeSlot);
      }
    }
    setTimeSlots(slotsArr);
  };

  const handleBranchSelection = (event) => {
    if (Utils.IsNullOrEmpty(event.target.value)) {
      return;
    }
    var branch = branches.find((b) => b.branchId === event.target.value);
    setSelectedBranchString(branch.branchName);
    setSelectedBranch(branch);
  };

  const closeAlert = () => {
    setShowAlert(false);
    if (customerBookingAction === CustomerBookingActions.WrongOTP) {
      setOpenOTP(true);
    }
  };

  const proceedBook = async () => {
    var currentDT = moment().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    var reservationDT = reservationDate.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    if (reservationDT.isSame(currentDT) && reservationTime.isBefore(moment())) {
      setAlertMessage(
        "Reservation time must be greater than current date and time"
      );
      setShowAlert(true);
      return;
    }

    var endStringLocal = moment(selectedBranch.serviceEndTime).local().format();
    var endDT = moment(endStringLocal);
    var endTime = moment().set({
      hour: endDT.get("hour"),
      minute: endDT.get("minute"),
      second: endDT.get("second"),
      millisecond: 0,
    });
    var reserDT = moment(reservationTime);
    var reserTime = moment().set({
      hour: reserDT.get("hour"),
      minute: reserDT.get("minute"),
      second: reserDT.get("second"),
      millisecond: 0,
    });
    var duration = moment.duration(endTime.diff(reserTime));
    var mins = duration.asMinutes();
    if (mins < Utils.GetLastBookingAllowedMins() - 1) {
      setAlertMessage(
        "Please select the reservation slot 30 mins before the business closing time."
      );
      setShowAlert(true);
      return;
    }
    var id = !newCustomer
      ? customerPass.id
      : selectedBranch.countryCode === India.Code ||
        Utils.IsNullOrEmpty(customerEmailId)
        ? customerMobileNumber
        : customerEmailId;
    var request = {
      MobileNumber: newCustomer
        ? customerMobileNumber
        : customerPass.mobileNumber,
      EmailId: newCustomer ? customerEmailId : customerPass.emailId,
      CountryCode: selectedBranch.countryCode,
      OTPId: id,
      BusinessId: business.id,
    };
    setLoading(true);
    let result = await post("CustomerBooking/ValidateReservation", request);
    setLoading(false);
    if (result.error) {
      setAlertMessage(result.errorMessage);
      setShowAlert(true);
      return;
    }
    setOTP("");
    setOTPId(id);
    setOpenOTP(true);
  };

  const handleServiceSelection = (e, i) => {
    var tempSelectedServices = [...selectedServices];
    if (e.target.checked) {
      var serviceFromSelection = tempSelectedServices.find(
        (c) => c.id === services[i].id
      );
      if (Utils.IsNullOrEmptyObject(serviceFromSelection)) {
        tempSelectedServices.push(services[i]);
        setSelectedServices(tempSelectedServices);
      }
    } else {
      var newSelectedServices = tempSelectedServices.filter(
        (t) => t.id !== services[i].id
      );
      setSelectedServices(newSelectedServices);
    }
  };

  useEffect(() => {
    checkDetailsFilled();
  }, [
    customerName,
    customerMobileNumber,
    newCustomer,
    reserveProducts,
    selectedServices,
    vehicleNumber,
    vehicleMake,
    vehicleModel,
    selectedTimeSlot
  ]);

  const checkDetailsFilled = () => {
    var isDetaisFilled =
      (!newCustomer ||
        (!Utils.IsNullOrEmpty(customerName) &&
          !Utils.IsNullOrEmpty(customerMobileNumber) &&
          Utils.IsMobileValid(customerMobileNumber))) &&
      (!reserveProducts || !Utils.IsNullOrEmptyArray(selectedServices)) &&
      !Utils.IsNullOrEmpty(vehicleNumber) &&
      !Utils.IsNullOrEmpty(vehicleModel) &&
      !Utils.IsNullOrEmpty(vehicleMake) &&
      !Utils.IsNullOrEmpty(selectedTimeSlot);
    setBookDisabled(!isDetaisFilled);
  };

  const closeOTP = () => {
    setOTP("");
    setCustomerBookingAction("");
    setOpenOTP(false);
  };

  const confirmOTP = async () => {
    var request = {
      OTP: otp,
      UserId: otpId,
    };
    setLoading(true);
    let result = await post(
      "CustomerBooking/AuthenticateReservationOTP",
      request
    );
    setLoading(false);
    if (result.error) {
      setOpenOTP(false);
      setOTP("");
      setAlertMessage(result.errorMessage);
      setShowAlert(true);
      setCustomerBookingAction(CustomerBookingActions.WrongOTP);
      return;
    }
    if (result.data) {
      setOpenOTP(false);
      setCustomerBookingAction(CustomerBookingActions.ProceedReservation);
      reservation(CustomerBookingActions.ProceedReservation);
    }
  };

  const reservation = async (customerBookingStatus) => {
    if (customerBookingStatus === CustomerBookingActions.ProceedReservation) {
      var reservationDT = reservationDate.set({
        hour: reservationTime.get("hour"),
        minute: reservationTime.get("minute"),
        second: reservationTime.get("second"),
      });
      var serviceItemList = [];
      if (!Utils.IsNullOrEmptyArray(selectedServices)) {
        selectedServices.forEach((service) => {
          var serviceItem = getServiceItemFromProduct(service);
          serviceItemList.push(serviceItem);
        });
      }
      var reservationRequest = {
        PassId: Utils.IsNullOrEmptyObject(customerPass) ? "" : customerPass.id,
        CustomerName: customerName,
        MobileNumber: customerMobileNumber?.trim(),
        EmailId: customerEmailId?.trim(),
        Address: customerAddress,
        City: customerCity?.trim(),
        State: customerState,
        ZipCode: customerPincode?.trim(),
        BusinessId: business.id,
        BranchId: selectedBranch.branchId,
        CountryCode: selectedBranch.countryCode,
        ReservationDT: Utils.GetISODateTimeString(
          Utils.ConvertToUTCDate(reservationDT)
        ),
        VehicleDetails: {
          Number: vehicleNumber.trim().toUpperCase(),
          Make: vehicleMake.trim().toUpperCase(),
          Model: vehicleModel.trim().toUpperCase(),
          Color: vehicleColor,
        },
        Services: Utils.IsNullOrEmptyArray(serviceItemList)
          ? null
          : serviceItemList,
      };
      setLoading(true);
      let result = await post(
        "CustomerBooking/SaveBooking",
        reservationRequest
      );
      setLoading(false);
      if (result.error) {
        setAlertMessage(result.errorMessage);
        setShowAlert(true);
        return;
      }
      setShowSuccess(true);
    }
  };

  const handleTimeSlotChange = (event) => {
    var timeSlot = timeSlots.find((t) => t.key === event.target.value);
    if (!Utils.IsNullOrEmptyObject(timeSlot)) {
      setSelectedTimeSlot(timeSlot.key);
      setReservationTime(moment(timeSlot.value));
    }
  };

  const closeSuccess = () => {
    setShowSuccess(false);
    //window.location.replace("https://www.infopoint.com/");
  };

  return (
    <>
      <Loader open={loading} />
      {/* OTP */}
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 400 } }}
        maxWidth="xs"
        open={openOTP}
      >
        <DialogTitle
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="dialog-title"
        >
          Confirm OTP
          <IconButton aria-label="close" onClick={closeOTP}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Typography
              variant="h5"
              className="py-2"
              dangerouslySetInnerHTML={{
                __html: "Please enter the OTP sent to " + customerMobileNumber,
              }}
            />
          </DialogContentText>
          <FormControl fullWidth className="my-xs-1">
            <NumericTextField
              id="customerFirstName"
              label="OTP"
              variant="outlined"
              value={otp}
              fullWidth={true}
              inputProps={{ maxLength: 6 }}
              setValue={setOTP}
              required
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="btn btn-secondary"
            onClick={closeOTP}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            variant="contained"
            className="btn btn-primary"
            onClick={() => confirmOTP()}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {/* Success */}
      <SuccessDialog
        open={showSuccess}
        onClose={closeSuccess}
        message="Your appointment booked successfully"
      />
      {/* Alert */}
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertMessage}
        onMsgClose={closeAlert}
      />
      <div id="indexLoading">
        <Grid container className="title-sec">
          <Typography
            level="h2"
            component="h2"
            fontWeight="600"
            fontSize="2rem"
            className="page-title text-center"
          >
            Booking
          </Typography>
        </Grid>
        <Stack
          container
          direction="row"
          spacing={5}
          style={{ overflowY: "auto" }}
        >
          <Container>
            <Box>
              <Grid container m={2}>
                <Grid
                  xs={12}
                  className="content-sec"
                  style={{ background: "#ffffff" }}
                >
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                  >
                    <img
                      alt="logo"
                      src={business.logoUrl}
                      height={200}
                      width={200}
                    />
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="500"
                      fontSize="2rem"
                    >
                      {business.companyName}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="column"
                    spacing={2}
                    className="xs-column"
                    alignItems="flex-start"
                    mt={2}
                  >
                    <Typography
                      level="h3"
                      component="h3"
                      fontWeight="500"
                      fontSize="2rem"
                    >
                      Branch Details
                    </Typography>
                    <Stack direction="row" spacing={1} className="m-t-2">
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="2rem"
                      >
                        <DnsIcon />
                        {": "}
                      </Typography>
                      <p>{selectedBranch.branchName}</p>
                    </Stack>
                    <Stack direction="row" spacing={1} className="m-t-2">
                      <Typography
                        level="h2"
                        component="h2"
                        fontWeight="600"
                        fontSize="2rem"
                      >
                        <LocationOnIcon />
                        {": "}
                      </Typography>
                      <p>{selectedBranch.address}</p>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Container>
            <Box>
              <Grid container m={2}>
                <Grid
                  xs={12}
                  className="content-sec"
                  style={{ background: "#ffffff" }}
                >
                  <Stack
                    direction="column"
                    spacing={2}
                    className="xs-column"
                    style={{ marginTop: "5px" }}
                  >
                    <FormControl fullWidth className="my-xs-1">
                      <TextField
                        id="customerFirstName"
                        label="Name"
                        variant="outlined"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required
                        InputLabelProps={{ shrink: true }}
                        disabled={!newCustomer}
                      />
                    </FormControl>
                    <Stack direction="row" spacing={2} className="m-t-2">
                      <NumericTextField
                        autoComplete="off"
                        id="customerMobile"
                        className="my-xs-1"
                        label="Mobile Number"
                        type="tel"
                        variant="outlined"
                        inputProps={{ maxLength: CountryHelper.GetMobileNumberMaxLength() }}
                        fullWidth={true}
                        value={customerMobileNumber}
                        setValue={setCustomerMobileNumber}
                        required
                        InputLabelProps={{ shrink: true }}
                        disabled={!newCustomer}
                      />
                      <FormControl fullWidth className="my-xs-1">
                        <TextField
                          id="customerFirstName"
                          label="EmailId"
                          variant="outlined"
                          value={customerEmailId}
                          onChange={(e) => setCustomerEmailId(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                          disabled={!newCustomer}
                        />
                      </FormControl>
                    </Stack>
                    <FormControl fullWidth>
                      <TextField
                        id="customerCustomAddress"
                        label="Address"
                        variant="outlined"
                        value={customerAddress}
                        disabled={!newCustomer}
                        onChange={(e) => setCustomerAddress(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <Stack direction="row" spacing={2} className="m-t-2">
                      <FormControl fullWidth>
                        <TextField
                          id="customerCity"
                          label="City"
                          variant="outlined"
                          value={customerCity}
                          disabled={!newCustomer}
                          onChange={(e) => setCustomerCity(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="customerState"
                          label="State"
                          variant="outlined"
                          disabled={!newCustomer}
                          value={customerState}
                          onChange={(e) => setCustomerState(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          id="customerPincode"
                          label="Pincode"
                          variant="outlined"
                          disabled={!newCustomer}
                          value={customerPincode}
                          onChange={(e) => setCustomerPincode(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2} className="m-t-2">
                      <FormControl fullWidth className="my-xs-1">
                        <TextField
                          id="customerFirstName"
                          label="Vehicle Number"
                          variant="outlined"
                          value={vehicleNumber}
                          onChange={(e) => setVehicleNumber(e.target.value)}
                          required
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth className="my-xs-1">
                        <TextField
                          id="customerFirstName"
                          label="Vehicle Make"
                          variant="outlined"
                          value={vehicleMake}
                          onChange={(e) => setVehicleMake(e.target.value)}
                          required
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Stack>
                    <Stack direction="row" spacing={2} className="m-t-2">
                      <FormControl fullWidth className="my-xs-1">
                        <TextField
                          id="customerFirstName"
                          label="Vehicle Model"
                          variant="outlined"
                          value={vehicleModel}
                          onChange={(e) => setVehicleModel(e.target.value)}
                          required
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                      <FormControl fullWidth className="my-xs-1">
                        <TextField
                          id="customerFirstName"
                          label="Vehicle Color"
                          variant="outlined"
                          value={vehicleColor}
                          onChange={(e) => setVehicleColor(e.target.value)}
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="column"
                    spacing={2}
                    className="xs-column"
                    style={{ marginTop: "5px" }}
                  >
                    <Stack direction="row" spacing={2} className="m-t-2">
                      <FormControl fullWidth className="my-xs-1">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <DatePicker
                            id="businessOpen"
                            label="Preferred Booking Date"
                            value={reservationDate}
                            disablePast={true}
                            onChange={(newValue) => {
                              setReservationDate(newValue);
                              populateTimeSlots(
                                newValue.format("YYYY-MM-DDTHH:mm:ss")
                              );
                            }}
                            closeOnSelect={true}
                            maxDate={maximumAllowedDT}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Preferred Booking Time
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          style={{ height: '28px' }}
                          label='Preferred Booking Time'
                          value={selectedTimeSlot}
                          onChange={(e) => handleTimeSlotChange(e)}
                        >
                          {timeSlots.map((time) => (
                            <MenuItem value={time.key}>{time.key}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                    {canSelectBranch && (
                      <Stack spacing={2} className="xs-column">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Preferred Location/Store
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedBranchString}
                            renderValue={(selected) => <p>{selected}</p>}
                            label="Preferred Location/Store"
                            onChange={(e) => handleBranchSelection(e)}
                            style={{ height: "53px" }}
                          >
                            {branches.map((branch) => (
                              <MenuItem value={branch.branchId}>
                                <Stack
                                  direction="column"
                                  spacing={1}
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  <Typography
                                    level="h3"
                                    component="h3"
                                    fontWeight="500"
                                    fontSize="2rem"
                                  >
                                    {branch.branchName}
                                  </Typography>
                                  <p>{branch.address}</p>
                                </Stack>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Stack>
                    )}
                    <FormGroup
                      row="false"
                      style={{ height: "200px", overflowY: "auto" }}
                    >
                      {!Utils.IsNullOrEmptyArray(services) &&
                        services.map((service, i) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  !Utils.IsNullOrEmptyArray(selectedServices) &&
                                  !Utils.IsNullOrEmptyObject(
                                    selectedServices.find(
                                      (s) => s.id === service.id
                                    )
                                  )
                                }
                                onChange={(e) => handleServiceSelection(e, i)}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                              />
                            }
                            label={service.shortDesc}
                          />
                        ))}
                    </FormGroup>
                  </Stack>
                  <Button disabled={bookDisabled} onClick={() => proceedBook()}>
                    Book
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Stack>
      </div>
    </>
  );
};
export default CustomerBookingXUVI;
