import React, { Fragment, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from '@mui/material/IconButton';
import { TextField, Typography } from '@mui/material';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import Utils from "../../core/Utils";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { getBusiness, setBranch } from '../../data/localSettingsActions';


BranchPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default function BranchPopup(props) {
    const { onClose, open, dispatch, ...other } = props;
    const [searchText, setSearchText] = useState("");
    const [branchList, setBranchList] = useState([]);
    const [branches, setBranches] = useState([]);

    useEffect(() => {
        let business = getBusiness();
        let branches = [];
        if (!Utils.IsNullOrEmptyObject(business) && !Utils.IsNullOrEmptyArray(business.branchLocations)) {
            business.branchLocations.forEach((branch) => {
                branches.push(branch);
            });
        }
        setBranchList(branches);
        setBranches(branches);
    }, []);

    const handleSearchTextChange = (event) => {
        let searchText = event.target.value;
        setSearchText(searchText);
        if (searchText) {
            let upperCaseSearch = Utils.ConvertToUpperCase(searchText);
            let tempBranches = branchList.filter(
                (item) => Utils.ConvertToUpperCase(item.branchName).includes(upperCaseSearch)
            );
            setBranches(tempBranches);
        } else setBranches(branchList);
    };

    const handleClose = () => {
        onClose(false, {});
    };
    const handleSelect = (branch) => {
        setBranch(branch, dispatch);
        onClose(true, branch);
    };
    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={open}
            {...other}
            sx={{ '& .MuiDialog-paper': { width: '80%', height: 400 } }} maxWidth="xs"
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
                display="flex" justifyContent="space-between" alignItems="center"
            >
                <Typography variant="h3"                >
                    Select Branch
                </Typography>
                <IconButton aria-label="close" onClick={handleClose} >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <TextField fullWidth
                    className="form-control font-size-14"
                    value={searchText}
                    onChange={handleSearchTextChange}
                    placeholder="Search..."
                />
                <List>
                    {!Utils.IsNullOrEmptyArray(branches) && branches.map((branch, index) => (
                        <ListItem disablePadding key={index}>
                            <ListItemButton onClick={() => handleSelect(branch)}>
                                <ListItemText primary={<Fragment>
                                    <Typography variant="h5">{branch.branchName}                                    </Typography>
                                </Fragment>}
                                    secondary={
                                        <Fragment>
                                            <Typography variant="h6">{branch.address}                                           </Typography>
                                        </Fragment>
                                    } />
                            </ListItemButton>
                        </ListItem>))}
                </List>
            </DialogContent>
        </Dialog>
    );
}