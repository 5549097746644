import DialogPopup from "./DialogPopup";
import PropTypes from 'prop-types';

export default function MessagePopup(props) {
    const { msgText, msgOpen, onMsgClose, msgTitle, ...other } = props;
    return (
        <DialogPopup
            open={msgOpen}
            onClose={onMsgClose}
            content={msgText}
            isConfirm={false}
            titleText={msgTitle}
            {...other}
        />
    );
}

MessagePopup.propTypes = {
    msgText: PropTypes.string.isRequired,
    msgOpen: PropTypes.bool.isRequired,
    onMsgClose: PropTypes.func.isRequired
};