import { Button, Dialog, DialogContent, Stack } from "@mui/material";

const EstimateOptionsDialog = ({
  open,
  regularEstimate,
  insuranceEstimate,
  close,
}) => {
  return (
    <Dialog open={open} onClose={close}>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Button
            fullWidth
            className="btn btn-primary white-text align-self-start"
            variant="contained"
            onClick={regularEstimate}
          >
            Regular Estimate
          </Button>
          <Button
            fullWidth
            className="btn btn-primary white-text align-self-start"
            variant="contained"
            onClick={insuranceEstimate}
          >
            Insurance Estimate
          </Button>
          <Button
            fullWidth
            className="btn btn-secondary"
            variant="contained"
            onClick={close}
          >
            Close
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default EstimateOptionsDialog;
