export const SETTER = {
  LOGINRESPONSE: "LOGINRESPONSE",
  BUSINESS: "BUSINESS",
  BRANCH: "BRANCH",
  TOKEN: "TOKEN",
  PRODUCTS: "PRODUCTS",
  CAMPAIGNS: "CAMPAIGNS",
  AMCPLANS: "AMCPLANS",
  ADMIN: "ADMIN",
  BRANCHSTATS: "BRANCHSTATS",
  USER: "USER",
  AUTHENTICATED: "AUTHENTICATED",
  COUNTRY: "COUNTRY",
  SELECTEDCUSTOMER: "SELECTEDCUSTOMER",
  SELECTEDVEHICLE: "SELECTEDVEHICLE",
  SELECTEDVISIT: "SELECTEDVISIT",
  NAVIGATIONSTATE: "NAVIGATIONSTATE",
  SELECTEDSERVICEITEMS: "SELECTEDSERVICEITEMS",
  RESET: "RESET",
  ISDARKMODE: "DARKMODE",
};

export const GETTER = {
  BUSINESS: "BUSINESS",
  BRANCH: "BRANCH",
  TOKEN: "TOKEN",
  PRODUCTS: "PRODUCTS",
  CAMPAIGNS: "CAMPAIGNS",
  AMCPLANS: "AMCPLANS",
  ADMIN: "ADMIN",
  BRANCHSTATS: "BRANCHSTATS",
  USER: "USER",
  AUTHENTICATION: "AUTHENTICATION",
  ENTERPRISEID: "ENTERPRISEID",
  SELECTEDCUSTOMER: "SELECTEDCUSTOMER",
  SELECTEDVEHICLE: "SELECTEDVEHICLE",
  SELECTEDVISIT: "SELECTEDVISIT",
  COUNTRY: "COUNTRY",
  NAVIGATIONSTATE: "NAVIGATIONSTATE",
  SELECTEDSERVICEITEMS: "SELECTEDSERVICEITEMS",
  MAKEMODEL: "MAKEMODEL",
  XUVIADMIN: "XUVIADMIN",
  ISDARKMODE: "DARKMODE",
};
