import React, { useState, useEffect } from "react";
import "../Style.css";
import "../bootstrap.min.css";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getBranch, getBusiness } from "../data/localSettingsActions";

import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Utils from "../core/Utils";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import EvStationIcon from "@mui/icons-material/EvStation";
import PropaneIcon from "@mui/icons-material/Propane";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import SideBarMenuItems from "./SideBarMenuItems";
import {
  ConfirmTypes,
  SideBarMenuNames,
  VehicleTypes,
} from "../core/Constants";

// Table
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CanRaiseJobcard } from "../core/ModuleHelper";
import MessagePopup from "./common/MessagePopUp";
import ConfirmPopup from "./common/ConfirmPopup";
import { postAsync } from "../core/serviceClient";
import moment from "moment";
import Loader from "./common/Loader";
import UserHelper from "../core/UserHelper";
import {
  CheckOutPage,
  CustomersPage,
  JobCardDetailsPage,
  LoginPage,
  VehiclesPage,
} from "../core/PageConstants";

const Vehicles = () => {
  document.title = "Vehicles";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [vehicleList, setVehicleList] = useState([]);
  const [pass, setPass] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [business, setBusiness] = useState({});
  const [branch, setBranch] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);

  useEffect(() => {
    if (UserHelper.CheckPermission(VehiclesPage, navigate)) {
      if (Utils.IsNullOrEmptyObject(location.state)) {
        navigate(CustomersPage.Path);
        return;
      }
      var customer = location.state.pass;
      if (Utils.IsNullOrEmptyObject(customer)) {
        navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
          state: {
            ...location.state,
            navigationStack: Utils.RemoveLastElementFromArray(
              location.state.navigationStack
            ),
          },
        });
        return;
      }
      setBusiness(getBusiness());
      setBranch(getBranch());
      setPass(customer);
    }
  }, []);

  useEffect(() => {
    populateVehicles();
  }, [pass]);

  const populateVehicles = () => {
    if (Utils.IsNullOrEmptyObject(pass)) {
      return;
    }
    if (
      (location.state.to !== CheckOutPage.Path &&
        !CanRaiseJobcard(getBusiness())) ||
      Utils.IsNullOrEmptyObject(pass)
    ) {
      navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
        state: {
          ...location.state,
          navigationStack: Utils.RemoveLastElementFromArray(
            location.state.navigationStack
          ),
        },
      });
      return;
    }
    var vehicleType =
      branch.vehicleType === VehicleTypes.None
        ? VehicleTypes.FourWheeler
        : branch.vehicleType;
    var validVehicles = pass.vehicleDetails.filter(
      (v) => v.vehicleType === vehicleType
    );
    if (Utils.IsNullOrEmptyArray(validVehicles)) {
      navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
        state: {
          ...location.state,
          navigationStack: Utils.RemoveLastElementFromArray(
            location.state.navigationStack
          ),
        },
      });
      return;
    }
    setVehicleList(validVehicles);
  };

  const onSelectVehicle = async (vehicle) => {
    setSelectedVehicle(vehicle);
    if (location.state.to !== CheckOutPage.Path) {
      if (!CanRaiseJobcard(business)) {
        setAlertText("Please contact our team to enable the jobcard feature");
        setShowAlert(true);
        return;
      }
      setIsloading(true);
      var request = {
        BusinessId: business.id,
        VehicleNumber: vehicle.number,
        CreatedDT: Utils.GetISODateTimeString(moment.utc()),
      };
      let result = await postAsync("Customer/GetConflictingJobcard", request);
      setIsloading(false);
      if (result.error) {
        setIsNavigateToLogin(result.isNavigateToLogin);
        setAlertText(result.errorMessage);
        setShowAlert(true);
        return;
      }
      if (!Utils.IsNullOrEmptyArray(result.data)) {
        setConfirmText(
          "A Jobcard was created with " +
            vehicle.number +
            " for the current date already. Do you wish to raise the jobcard again ?"
        );
        setShowConfirm(true);
        return;
      }
    }
    navigate(location.state.to, {
      state: {
        ...location.state,
        navigationStack: Utils.AddElementToArray(
          location.state.navigationStack,
          VehiclesPage.Path
        ),
        vehicleDetails: vehicle,
      },
    });
  };

  const alertClose = () => {
    setShowAlert(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleCloseConfirm = (value) => {
    setShowConfirm(false);
    switch (value) {
      case ConfirmTypes.OK:
        navigate(location.state.to, {
          state: {
            ...location.state,
            navigationStack: Utils.AddElementToArray(
              location.state.navigationStack,
              VehiclesPage.Path
            ),
            vehicleDetails: selectedVehicle,
          },
        });
        break;
      default:
        setSelectedVehicle({});
        break;
    }
  };

  const goBack = () => {
    navigate(Utils.GetLastArrayElement(location.state.navigationStack), {
      state: {
        ...location.state,
        navigationStack: Utils.RemoveLastElementFromArray(
          location.state.navigationStack
        ),
      },
    });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={showAlert}
        msgText={alertText}
        onMsgClose={alertClose}
      />
      <ConfirmPopup
        confirmOpen={showConfirm}
        confirmText={confirmText}
        onConfirmClose={handleCloseConfirm}
      />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems
            selectedTab={
              location.state && location.state.fromJobcard
                ? SideBarMenuNames.JobCard
                : SideBarMenuNames.Customers
            }
          />
          <Grid xs={12} className="content-sec">
            <Grid container className="title-sec">
              <Typography
                level="h2"
                component="h2"
                fontWeight="600"
                fontSize="2rem"
                className="page-title text-center"
              >
                <Button onClick={() => goBack()} className="back-btn">
                  <NavigateBeforeIcon />
                </Button>
                Vehicles
              </Typography>
            </Grid>

            <Grid container direction="row">
              <Grid flex="1" className="p-lg-2">
                {/* <form method="post" className="cs-form text-left p-0 search-form" onSubmit={handleSubmit} autoComplete="off">
                        <Stack direction="row" spacing={2} >
                          <Grid xs={10}><TextField className="form-control font-size-14" placeholder="Search vehicles..." onChange={(e)=>setSearchText(e.target.value)} /></Grid>
                          <Grid xs={2}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              size='large'
                              className='submitBtn cs-btn'
                              fullWidth="true"
                              >
                              Search
                            </Button>
                          </Grid>
                        </Stack>
                      </form> */}
                <TableContainer
                  stickyHeader
                  component={Paper}
                  className="visit-table vehicles-table"
                >
                  <Table aria-label="simple table" className="font-size-14">
                    <TableHead>
                      <TableRow>
                        <TableCell width="150px">Vehicle No</TableCell>
                        <TableCell>
                          <EmojiTransportationIcon
                            style={{
                              width: "24px",
                              height: "24px",
                              "vertical-align": "middle",
                            }}
                          />
                          &nbsp; Make
                        </TableCell>
                        <TableCell>
                          <DirectionsCarIcon
                            style={{
                              width: "24px",
                              height: "24px",
                              "vertical-align": "middle",
                            }}
                          />
                          &nbsp; Model
                        </TableCell>
                        <TableCell width="150px">
                          <LocalGasStationIcon
                            style={{
                              width: "24px",
                              height: "24px",
                              "vertical-align": "middle",
                            }}
                          />
                          &nbsp; Fuel Type
                        </TableCell>
                        <TableCell>Chassis No</TableCell>
                        <TableCell>Engine No</TableCell>
                        <TableCell width="20px"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vehicleList.map((vehicle) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                          onClick={() => onSelectVehicle(vehicle)}
                        >
                          <TableCell scope="row">
                            <Typography component="h3" fontWeight="600">
                              {vehicle.number}
                            </Typography>
                          </TableCell>
                          <TableCell>{vehicle.make}</TableCell>
                          <TableCell>
                            {vehicle.model}
                            <Stack direction="row">
                              {!Utils.IsNullOrEmpty(vehicle.year) &&
                                Utils.ConvertToInteger(vehicle.year) > 0 && (
                                  <Typography
                                    component="p"
                                    className="model-year"
                                  >
                                    {vehicle.year} model
                                  </Typography>
                                )}
                              {vehicle.color && (
                                <Typography
                                  component="p"
                                  className="model-year"
                                >
                                  &nbsp;
                                  <DirectionsCarIcon
                                    style={{
                                      color: vehicle.color,
                                      "vertical-align": "middle",
                                    }}
                                  />
                                  &nbsp;
                                  {vehicle.color} color
                                </Typography>
                              )}
                            </Stack>
                          </TableCell>
                          <TableCell>
                            <span>
                              {vehicle.fuelType} &nbsp;
                              {vehicle.fuelType == "Petrol" && (
                                <WaterDropIcon
                                  style={{
                                    color: "gold",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                              {vehicle.fuelType == "Diesel" && (
                                <WaterDropIcon
                                  style={{
                                    color: "#cddc39",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                              {vehicle.fuelType == "CNG" && (
                                <PropaneIcon
                                  style={{
                                    color: "#03a9f4",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                              {vehicle.fuelType == "LPG" && (
                                <LocalGasStationIcon
                                  style={{
                                    color: "red",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                              {vehicle.fuelType == "Electric" && (
                                <EvStationIcon
                                  style={{
                                    color: "green",
                                    "vertical-align": "middle",
                                  }}
                                />
                              )}
                            </span>
                          </TableCell>
                          <TableCell>{vehicle.chassisNumber}</TableCell>
                          <TableCell>{vehicle.engineNumber}</TableCell>
                          <TableCell className="hidden-xs">
                            <NavigateNextIcon fontSize="large" />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* {vehicleList.map((vehicle) => (
                      <Grid
                        onClick={() => onSelectVehicle(vehicle)}
                        className="mt-1"
                      >
                        <Link title="Jobcard" style={{ color: "#000000" }}>
                          <Grid
                            container
                            xs={12}
                            padding="10px"
                            className="card"
                          >
                            <Grid xs={3} className="">
                              <Typography
                                level="h2"
                                className="h2 mt-1"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {vehicle.number}
                              </Typography>
                            </Grid>
                            <Grid xs={2}>
                              <Box>
                                <Typography
                                  level="p"
                                  component="p"
                                  fontWeight="600"
                                  fontSize="16px"
                                >
                                  {vehicle.make}
                                </Typography>
                                <Typography
                                  level="p"
                                  component="p"
                                  fontSize="12px"
                                  display="flex"
                                >
                                  <EmojiTransportationIcon
                                    style={{ "align-self": "center" }}
                                  />
                                  &nbsp; Make
                                </Typography>
                              </Box>
                              <Box className="mt-1">
                                <Typography
                                  level="p"
                                  component="p"
                                  fontWeight="600"
                                  fontSize="16px"
                                >
                                  {vehicle.model}
                                </Typography>
                                <Typography
                                  level="p"
                                  component="p"
                                  fontSize="12px"
                                  display="flex"
                                >
                                  <DirectionsCarIcon
                                    style={{ "align-self": "center" }}
                                  />
                                  &nbsp; Model
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid xs={2}>
                              <Box>
                                <Typography
                                  level="p"
                                  component="p"
                                  fontWeight="600"
                                  fontSize="16px"
                                >
                                  {vehicle.fuelType}
                                </Typography>
                                <Typography
                                  level="p"
                                  component="p"
                                  fontSize="12px"
                                  display="flex"
                                >
                                  <LocalGasStationIcon
                                    style={{ "align-self": "center" }}
                                  />
                                  &nbsp; Fuel Type
                                </Typography>
                              </Box>
                              <Box className="mt-1">
                                <Typography
                                  level="p"
                                  component="p"
                                  fontWeight="600"
                                  fontSize="16px"
                                >
                                  {vehicle.color} &nbsp;
                                </Typography>
                                <Typography
                                  level="p"
                                  component="p"
                                  fontSize="12px"
                                  display="flex"
                                >
                                  <ColorLensIcon
                                    style={{ "align-self": "center" }}
                                  />
                                  &nbsp; Color
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid xs={2} className="">
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="16px"
                              >
                                {vehicle.chassisNumber}
                              </Typography>
                              <Typography
                                level="p"
                                component="p"
                                fontSize="12px"
                                className="mt-1"
                              >
                                {vehicle.engineNumber}
                              </Typography>
                            </Grid>
                            <Grid xs={2} className="text-right">
                              <Typography
                                level="h4"
                                component="h4"
                                fontWeight="600"
                                fontSize="14px"
                              >
                                Year: {vehicle.year}
                              </Typography>
                            </Grid>
                            <Grid xs={1} className="text-right align-center">
                              <NavigateNextIcon fontSize="large" />
                            </Grid>
                          </Grid>
                        </Link>
                      </Grid>
                    ))} */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Vehicles;
