import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Utils from "../core/Utils";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import {
  Paper,
  Stack,
  Grid,
  Chip,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StraightIcon from "@mui/icons-material/Straight";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { connectToStore } from "../data/store";
import { postAsync } from "../core/serviceClient";
import { RevenueFilters } from "../core/Constants";

export default function OverviewDetails(props) {
  const { onClose, open, isRevenue, startDT, endDT, ...other } = props;
  const [revenueDetails, setRevenueDetails] = useState([]);
  const [revenueDetailList, setRevenueDetailList] = useState([]);
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [isProduct, setIsProduct] = useState(true);
  const [filters, setFilters] = useState([
    { text: RevenueFilters.Product, isActive: true },
    { text: RevenueFilters.Category, isActive: false },
    { text: RevenueFilters.Department, isActive: false },
  ]);
  const filterClicked = (index) => {
    let tempFilters = [...filters];
    tempFilters.forEach((element, i) => {
      if (i == index) element.isActive = true;
      else element.isActive = false;
    });
    setFilters(tempFilters);
  };
  const handleRowClick = (event, row) => {};
  useEffect(() => {
    if (open) revenueDetailsInit();
  }, [open]);
  useEffect(() => {
    loadRevenueDetails();
  }, [filters]);

  const revenueDetailsInit = async () => {
    let response = await postAsync("Business/GetCustomerVisitsData", {
      BranchId: localSettings.branch.branchId,
      StartDT: Utils.ConvertToUTCDate(startDT),
      EndDT: Utils.ConvertToUTCDate(endDT),
    });
    if (response.error) {
      onClose(response);
    } else {
      setRevenueDetailList(response.data.visits);
      loadRevenueDetails(response.data.visits);
    }
  };

  const loadRevenueDetails = (data) => {
    var tempVisitDetails = data;
    if (Utils.IsNullOrEmptyArray(tempVisitDetails))
      tempVisitDetails = revenueDetailList;
    if (!Utils.IsNullOrEmptyArray(tempVisitDetails)) {
      var filter = filters.find((f) => f.isActive == true);
      if (filter.text == RevenueFilters.Product) {
        setIsProduct(true);
        var visitDetails = tempVisitDetails.flatMap((v) => v.serviceItems);
        var groupedResult = Utils.GetGroupByArray(visitDetails, "productId");
        var result = groupedResult.map(function (item) {
          return {
            description: item.values[0].description,
            name: item.values[0].description,
            class: !Utils.IsNullOrEmpty(item.values[0].productClass)
              ? item.values[0].productClass
              : "",
            department: !Utils.IsNullOrEmpty(item.values[0].productDepartment)
              ? item.values[0].productDepartment
              : "",
            revenue: item.values.reduce(
              (a, v) =>
                (a =
                  a +
                  (v.totalPriceIncludingTax - v.discountTotal - v.refundTotal)),
              0
            ),
            quantity: item.values.reduce((a, v) => (a = a + v.quantity), 0),
            profit: item.values.reduce(
              (a, v) =>
                (a =
                  a +
                  (v.totalPrice -
                    v.discountTotal -
                    v.refundTotal -
                    v.quantity * v.cost)),
              0
            ),
          };
        });
        setRevenueDetails(result);
      } else if (filter.text == RevenueFilters.Category) {
        setIsProduct(false);
        var visitDetails = tempVisitDetails.flatMap((v) => v.serviceItems);
        var groupedResult = Utils.GetGroupByArray(visitDetails, "productClass");
        var result = groupedResult.map(function (item) {
          return {
            description: !Utils.IsNullOrEmpty(item.values[0].productClass)
              ? item.values[0].productClass
              : "No Category",
            name: !Utils.IsNullOrEmpty(item.values[0].productClass)
              ? item.values[0].productClass
              : "",
            class: !Utils.IsNullOrEmpty(item.values[0].productClass)
              ? item.values[0].productClass
              : "",
            department: !Utils.IsNullOrEmpty(item.values[0].productDepartment)
              ? item.values[0].productDepartment
              : "",
            revenue: item.values.reduce(
              (a, v) =>
                (a =
                  a +
                  (v.totalPriceIncludingTax - v.discountTotal - v.refundTotal)),
              0
            ),
            quantity: item.values.reduce((a, v) => (a = a + v.quantity), 0),
            profit: item.values.reduce(
              (a, v) =>
                (a =
                  a +
                  (v.totalPrice -
                    v.discountTotal -
                    v.refundTotal -
                    v.quantity * v.cost)),
              0
            ),
          };
        });
        setRevenueDetails(result);
      } else if (filter.text == RevenueFilters.Department) {
        setIsProduct(false);
        var visitDetails = tempVisitDetails.flatMap((v) => v.serviceItems);
        var groupedResult = Utils.GetGroupByArray(
          visitDetails,
          "productDepartment"
        );
        var result = groupedResult.map(function (item) {
          return {
            description: !Utils.IsNullOrEmpty(item.values[0].productDepartment)
              ? item.values[0].productDepartment
              : "No Department",
            name: !Utils.IsNullOrEmpty(item.values[0].productDepartment)
              ? item.values[0].productDepartment
              : "",
            class: !Utils.IsNullOrEmpty(item.values[0].productClass)
              ? item.values[0].productClass
              : "",
            department: !Utils.IsNullOrEmpty(item.values[0].productDepartment)
              ? item.values[0].productDepartment
              : "",
            revenue: item.values.reduce(
              (a, v) =>
                (a =
                  a +
                  (v.totalPriceIncludingTax - v.discountTotal - v.refundTotal)),
              0
            ),
            quantity: item.values.reduce((a, v) => (a = a + v.quantity), 0),

            profit: item.values.reduce(
              (a, v) =>
                (a =
                  a +
                  (v.totalPrice -
                    v.discountTotal -
                    v.refundTotal -
                    v.quantity * v.cost)),
              0
            ),
          };
        });
        setRevenueDetails(result);
      }
    }
  };

  return (
    <Dialog
      className="dialogModal"
      sx={{
        "& .MuiDialog-paper": {
          minWidth: 700,
          maxWidth: 800,
          minHeight: 400,
          maxHeight: 600,
        },
      }}
      maxWidth="xs"
      open={open}
      {...other}
    >
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        {isRevenue ? (
          <h4>
            Revenue <TrendingUpIcon sx={{ fontSize: 20 }} />
          </h4>
        ) : (
          <h4>
            Profit <StraightIcon sx={{ fontSize: 20 }} />
          </h4>
        )}
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack direction="row" spacing={2} justifyContent="space-evenly">
          {!Utils.IsNullOrEmptyArray(filters) &&
            filters.map((filter, index) => (
              <Chip
                label={<Typography variant="h6">{filter.text}</Typography>}
                color={filter.isActive ? "primary" : "default"}
                style={{ width: "150px" }}
                onClick={() => filterClicked(index)}
              />
            ))}
        </Stack>
        <Grid
          xs={12}
          mt={2}
          container
          alignItems="stretch"
          className="font-size-14"
        >
          <TableContainer
            alignItems="stretch"
            component={Paper}
            className="data-grid"
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableBody>
                {!Utils.IsNullOrEmptyArray(revenueDetails) ? (
                  revenueDetails.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                      onClick={(e) => handleRowClick(e, row)}
                    >
                      <TableCell component="td" scope="row">
                        <Stack direction="row" alignItems="center">
                          <Typography>{row.description}</Typography>
                        </Stack>
                      </TableCell>
                      {isProduct && (
                        <TableCell component="td" scope="row">
                          <Stack direction="column" alignContent="center">
                            <Typography>{row.quantity}</Typography>
                          </Stack>
                        </TableCell>
                      )}
                      <TableCell component="td" scope="row" align="right">
                        {Utils.GetCurrency(
                          isRevenue ? row.revenue : row.profit,
                          localSettings.country
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell>
                      <Typography>No data found...</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

OverviewDetails.propTypes = {
  isRevenvue: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  startDT: PropTypes.object.isRequired,
  endDT: PropTypes.object.isRequired,
};
