import { India, USA, UAE, Nepal, Malaysia, DefaultCulture } from "./Constants";
import { getCountry } from "../data/localSettingsActions";
import Utils from "./Utils";

const propertyNames = {
  MaxLength:"MaxLength",
  MinLenth:"MinLenth",
  TaxIdLength:"TaxIdLength",
  SMS:"SMS"
}

const getCountryProps = (country,propName) =>
{
  var tempCountry = CountryHelper.GetCountry(country);
  switch (propName) {
    case propertyNames.MaxLength:
      return tempCountry.MobileNumberMaxLength;
    case propertyNames.MinLenth:
      return tempCountry.MobileNumberMinLength;
    case propertyNames.TaxIdLength:
      return tempCountry.TaxIdLength;
    case propertyNames.SMS:
      return tempCountry.SMSEnabled;
  }
}

export default class CountryHelper {
  static GetSupportedCountries() {
    return [
      {
        Code: USA.Code,
        Name: USA.ShortName,
        Culture: USA.Culture,
      },
      {
        Code: India.Code,
        Name: India.ShortName,
        Culture: India.Culture,
      },
      {
        Code: UAE.Code,
        Name: UAE.ShortName,
        Culture: UAE.Culture,
      },
      {
        Code: Malaysia.Code,
        Name: Malaysia.ShortName,
        Culture: Malaysia.Culture,
      },
    ];
  }
  static GetCulture(country) {
    let culture = country;
    switch (country) {
      case India.Code:
      case India.Name:
      case India.ISOCode:
      case India.ShortName:
      case India.Currency:
      case India.CurrencySmall:
        culture = India.Culture;
        break;
      case USA.Code:
      case USA.Name:
      case USA.ISOCode:
      case USA.ShortName:
      case USA.Currency:
      case USA.CurrencySmall:
        culture = USA.Culture;
        break;
      case UAE.Code:
      case UAE.Name:
      case UAE.ISOCode:
      case UAE.ShortName:
      case UAE.Currency:
      case UAE.CurrencySmall:
        culture = UAE.Culture;
        break;
      case Nepal.Code:
      case Nepal.Name:
      case Nepal.ISOCode:
      case Nepal.ShortName:
      case Nepal.Currency:
      case Nepal.CurrencySmall:
        culture = Nepal.Culture;
        break;
      case Malaysia.Code:
      case Malaysia.Name:
      case Malaysia.ISOCode:
      case Malaysia.ShortName:
      case Malaysia.Currency:
      case Malaysia.CurrencySmall:
        culture = Malaysia.Culture;
        break;
    }
    return culture;
  }
  static GetCountryCode(country) {
    let countryCode = country;
    switch (country) {
      case USA.Name:
      case USA.ISOCode:
      case USA.ShortName:
      case USA.Culture:
      case USA.Currency:
      case USA.CurrencySmall:
        countryCode = USA.Code;
        break;
      case India.Name:
      case India.ISOCode:
      case India.ShortName:
      case India.Culture:
      case India.Currency:
      case India.CurrencySmall:
        countryCode = India.Code;
        break;
      case UAE.Name:
      case UAE.ISOCode:
      case UAE.ShortName:
      case UAE.Culture:
      case UAE.Currency:
      case UAE.CurrencySmall:
        countryCode = UAE.Code;
        break;
      case Nepal.Name:
      case Nepal.ISOCode:
      case Nepal.ShortName:
      case Nepal.Culture:
      case Nepal.Currency:
      case Nepal.CurrencySmall:
        countryCode = Nepal.Code;
        break;
      case Malaysia.Name:
      case Malaysia.ISOCode:
      case Malaysia.ShortName:
      case Malaysia.Culture:
      case Malaysia.Currency:
      case Malaysia.CurrencySmall:
        countryCode = Malaysia.Code;
        break;
    }
    return countryCode;
  }
  static GetName(country) {
    let name = country;
    switch (country) {
      case USA.Code:
      case USA.Culture:
      case USA.ISOCode:
      case USA.ShortName:
      case USA.Currency:
      case USA.CurrencySmall:
        name = USA.Name;
        break;
      case India.Code:
      case India.Culture:
      case India.ISOCode:
      case India.ShortName:
      case India.Currency:
      case India.CurrencySmall:
        name = India.Name;
        break;
      case UAE.Code:
      case UAE.Culture:
      case UAE.ISOCode:
      case UAE.ShortName:
      case UAE.Currency:
      case UAE.CurrencySmall:
        name = UAE.Name;
        break;
      case Nepal.Code:
      case Nepal.Culture:
      case Nepal.ISOCode:
      case Nepal.ShortName:
      case Nepal.Currency:
      case Nepal.CurrencySmall:
        name = Nepal.Name;
        break;
      case Malaysia.Code:
      case Malaysia.Culture:
      case Malaysia.ISOCode:
      case Malaysia.ShortName:
      case Malaysia.Currency:
      case Malaysia.CurrencySmall:
        name = Malaysia.Name;
        break;
    }
    return name;
  }
  static GetShortName(country) {
    let shortName = country;
    switch (country) {
      case USA.Code:
      case USA.Culture:
      case USA.ISOCode:
      case USA.Name:
      case USA.Currency:
      case USA.CurrencySmall:
        shortName = USA.ShortName;
        break;
      case India.Code:
      case India.Culture:
      case India.ISOCode:
      case India.Name:
      case India.Currency:
      case India.CurrencySmall:
        shortName = India.ShortName;
        break;
      case UAE.Code:
      case UAE.Culture:
      case UAE.ISOCode:
      case UAE.Name:
      case UAE.Currency:
      case UAE.CurrencySmall:
        shortName = UAE.ShortName;
        break;
      case Nepal.Code:
      case Nepal.Culture:
      case Nepal.ISOCode:
      case Nepal.Name:
      case Nepal.Currency:
      case Nepal.CurrencySmall:
        shortName = Nepal.ShortName;
        break;
      case Malaysia.Code:
      case Malaysia.Culture:
      case Malaysia.ISOCode:
      case Malaysia.Name:
      case Malaysia.Currency:
      case Malaysia.CurrencySmall:
        shortName = Malaysia.ShortName;
        break;
    }
    return shortName;
  }
  static GetISOName(country) {
    let shortName = country;
    switch (country) {
      case USA.Code:
      case USA.Culture:
      case USA.ShortName:
      case USA.Name:
      case USA.Currency:
      case USA.CurrencySmall:
        shortName = USA.ISOCode;
        break;
      case India.Code:
      case India.Culture:
      case India.ShortName:
      case India.Name:
      case India.Currency:
      case India.CurrencySmall:
        shortName = India.ISOCode;
        break;
      case UAE.Code:
      case UAE.Culture:
      case UAE.ShortName:
      case UAE.Name:
      case UAE.Currency:
      case UAE.CurrencySmall:
        shortName = UAE.ISOCode;
        break;
      case Nepal.Code:
      case Nepal.Culture:
      case Nepal.ShortName:
      case Nepal.Name:
      case Nepal.Currency:
      case Nepal.CurrencySmall:
        shortName = Nepal.ISOCode;
        break;
      case Malaysia.Code:
      case Malaysia.Culture:
      case Malaysia.ShortName:
      case Malaysia.Name:
      case Malaysia.Currency:
      case Malaysia.CurrencySmall:
        shortName = Malaysia.ISOCode;
        break;
    }
    return shortName;
  }
  static GetCurrency(country) {
    let currency = country;
    switch (country) {
      case USA.Code:
      case USA.Culture:
      case USA.ShortName:
      case USA.Name:
      case USA.ISOCode:
      case USA.CurrencySmall:
        currency = USA.Currency;
        break;
      case India.Code:
      case India.Culture:
      case India.ShortName:
      case India.Name:
      case India.ISOCode:
      case India.CurrencySmall:
        currency = India.Currency;
        break;
      case UAE.Code:
      case UAE.Culture:
      case UAE.ShortName:
      case UAE.Name:
      case UAE.ISOCode:
      case UAE.CurrencySmall:
        currency = UAE.Currency;
        break;
      case Nepal.Code:
      case Nepal.Culture:
      case Nepal.ShortName:
      case Nepal.Name:
      case Nepal.ISOCode:
      case Nepal.CurrencySmall:
        currency = Nepal.Currency;
        break;
      case Malaysia.Code:
      case Malaysia.Culture:
      case Malaysia.ShortName:
      case Malaysia.Name:
      case Malaysia.ISOCode:
      case Malaysia.CurrencySmall:
        currency = Malaysia.Currency;
        break;
    }
    return currency;
  }
  static GetCurrencySmall(country) {
    let currency = country;
    switch (country) {
      case USA.Code:
      case USA.Culture:
      case USA.ShortName:
      case USA.Name:
      case USA.ISOCode:
      case USA.Currency:
        currency = USA.CurrencySmall;
        break;
      case India.Code:
      case India.Culture:
      case India.ShortName:
      case India.Name:
      case India.ISOCode:
      case India.Currency:
        currency = India.CurrencySmall;
        break;
      case UAE.Code:
      case UAE.Culture:
      case UAE.ShortName:
      case UAE.Name:
      case UAE.ISOCode:
      case UAE.Currency:
        currency = UAE.CurrencySmall;
        break;
      case Nepal.Code:
      case Nepal.Culture:
      case Nepal.ShortName:
      case Nepal.Name:
      case Nepal.ISOCode:
      case Nepal.Currency:
        currency = Nepal.CurrencySmall;
        break;
      case Malaysia.Code:
      case Malaysia.Culture:
      case Malaysia.ShortName:
      case Malaysia.Name:
      case Malaysia.ISOCode:
      case Malaysia.Currency:
        currency = Malaysia.CurrencySmall;
        break;
    }
    return currency;
  }
  static GetCountryObject(country) {
    let countryObj = {};
    switch (country) {
      case India.Code:
      case India.Name:
      case India.ISOCode:
      case India.ShortName:
      case India.Culture:
      case India.Currency:
      case India.CurrencySmall:
        countryObj = India;
        break;
      case USA.Code:
      case USA.Name:
      case USA.ISOCode:
      case USA.ShortName:
      case USA.Culture:
      case USA.Currency:
      case USA.CurrencySmall:
        countryObj = USA;
        break;
      case UAE.Code:
      case UAE.Name:
      case UAE.ISOCode:
      case UAE.ShortName:
      case UAE.Culture:
      case UAE.Currency:
      case UAE.CurrencySmall:
        countryObj = UAE;
        break;
      case Nepal.Code:
      case Nepal.Name:
      case Nepal.ISOCode:
      case Nepal.ShortName:
      case Nepal.Culture:
      case Nepal.Currency:
      case Nepal.CurrencySmall:
        countryObj = Nepal;
        break;
      case Malaysia.Code:
      case Malaysia.Name:
      case Malaysia.ISOCode:
      case Malaysia.ShortName:
      case Malaysia.Culture:
      case Malaysia.Currency:
      case Malaysia.CurrencySmall:
        countryObj = Malaysia;
        break;
    }
    return countryObj;
  }

  static IsBusinessCountryIndia(country) {
    var tempCountry = this.GetCountry(country);
    return tempCountry.Code == India.Code;
  }
  static IsBusinessCountryUSA(country) {
    var tempCountry = this.GetCountry(country);
    return tempCountry.Code == USA.Code;
  }
  static IsBusinessCountryUAE(country) {
    var tempCountry = this.GetCountry(country);
    return tempCountry.Code == UAE.Code;
  }
  static IsBusinessCountryNepal(country) {
    var tempCountry = this.GetCountry(country);
    return tempCountry.Code == Nepal.Code;
  }
  static IsBusinessCountryMalaysia(country) {
    var tempCountry = this.GetCountry(country);
    return tempCountry.Code == Malaysia.Code;
  }
  static IsOtherPaymentsAvailable(country) {
    var tempCountry = this.GetCountry(country);
    return tempCountry.Code == India.Code;
  }

  static GetContactUsUrl(country) {
    let url = "";
    var tempCountry = this.GetCountry(country);
    switch (tempCountry.Code) {
      case India.Code:
        url = India.ContactUsURL;
        break;
      case UAE.Code:
        url = UAE.ContactUsURL;
        break;
      case Nepal.Code:
        url = Nepal.ContactUsURL;
        break;
      case Malaysia.Code:
        url = Malaysia.ContactUsURL;
        break;
      case USA.Code:
      default:
        url = USA.ContactUsURL;
        break;
    }
    return encodeURI(url);
  }
  static GetTermsAndConditionUrl(country) {
    let url = "";
    var tempCountry = this.GetCountry(country);
    switch (tempCountry.Code) {
      case India.Code:
        url = India.TermsAndConditionURL;
        break;
      case UAE.Code:
        url = UAE.TermsAndConditionURL;
        break;
      case Nepal.Code:
        url = Nepal.TermsAndConditionURL;
        break;
      case Malaysia.Code:
        url = Malaysia.TermsAndConditionURL;
        break;
      case USA.Code:
      default:
        url = USA.TermsAndConditionURL;
        break;
    }
    return encodeURI(url);
  }
  static GetCountry(country) {
    var tempCountry = country;
    if (Utils.IsNullOrEmptyObject(tempCountry)) {
      tempCountry = getCountry();
    }
    return tempCountry;
  }

  
  static GetMobileNumberMinLength(country) {
    return getCountryProps(country,propertyNames.MinLenth);
  }

  static GetTaxIdLength(country) {
    return getCountryProps(country,propertyNames.TaxIdLength);
  }

  static IsSMSEnabled(country) {
    return getCountryProps(country,propertyNames.SMS);
  }

  static GetMobileNumberMaxLength(country) {
    return getCountryProps(country,propertyNames.MaxLength);
  }

  static GetDateCulture(culture) {
    if (culture === India.Culture || culture === USA.Culture)
      return culture;
    else
      return DefaultCulture;
  }
  static GetAPICulture(culture) {
    if (culture === India.Culture || culture === USA.Culture)
      return culture;
    else
      return DefaultCulture;
  }
}
