import { serviceItemActionTypes } from "./serviceItemActionTypes";

export const serviceItemReducer = (serviceItems, action) => {
  switch (action.type) {
    case serviceItemActionTypes.Add:{
        return [...serviceItems,action.value];
  }
    case serviceItemActionTypes.Edit:{
      return serviceItems.map(s => {
        if (s.Sequence === action.value.Sequence) {
          return action.value;
        } else {
          return s;
        }
      });
    }
    case serviceItemActionTypes.Delete:{ 
      return serviceItems.filter((s,i) => i !== action.value);
    }
    case serviceItemActionTypes.Clear:{
      return [];
    }
    default:
      return serviceItems;
  }
};
