export const Regexes = {
  OnlyNumeric: /^[0-9\b]+$/,
  UAEMobile: /^[0-9]{9}$|^[0-9]{10}$/,
  DecimalAndNumeric: /^(0|[1-9]\d*)(\.\d+)?$/,
  onlyUPIid: /^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/,
  //GST:/^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5})([0-9]{4})([A-Z]{1}[1-9A-Z]{1})([Z]{1})([0-9A-Z]{1})+$/,
  GST: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
  AlphaNumeric: /^[A-Za-z0-9]+$/,
  onlyDecimalValue: /^(\d+(\.\d{0,2})?|\.\d{1,2})$/,
  MalaysiaMobile: /^[0-9]{6,11}$/
};
