import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import UserHelper from "../../core/UserHelper";
import useIdle from "../../core/useIdleTimer.js";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  LoginPage
} from "../../core/PageConstants";

export default function SessionTimeOut(props) {
    const { open } = props;
    const [showModal, setShowModal] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const handleIdle = () => {
        setShowModal(true); //show modal
        setRemainingTime(30); //set seconds as time remaining
    };

    const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 0.3});

    useEffect(() => {
        let interval;

        if (isIdle && showModal) {
        interval = setInterval(() => {
            setRemainingTime(
            (prevRemainingTime) =>
                prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
            );
        }, 1000);
        }

        return () => {
        clearInterval(interval);
        };
    }, [isIdle, showModal]);

    useEffect(() => {
        if (remainingTime === 0 && showModal) {
            if (open == true) {
                // alert("Time out!");
                setShowModal(false);
                UserHelper.LogOut(dispatch);
                navigate(LoginPage.Path);
            }
        }
    }, [remainingTime, showModal, navigate]);

    const handleLogOut = () => {
        setShowModal(false);
        UserHelper.LogOut(dispatch);
        navigate(LoginPage.Path);
    };

    const handleStayLoggedIn = () => {
        setShowModal(false);

    };

    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }
  return (
      <>
          {open ? (
            <>
            {isIdle && showModal && (
                <div className="modal session-modal">
                    <div className="modal-content">
                        <h2>Idle Timeout Warning</h2>
                        <p>You are about to be logged out due to inactivity.</p>
                        <br />
                        Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}
                        <br />
                        <div className="row">
                        <button className="btn btn-default" onClick={handleLogOut}>
                        Logout
                        </button>
                        <button className="btn btn-default " onClick={handleStayLoggedIn}>
                        Stay Logged In
                        </button>
                        </div>

                    </div>
                </div>
            )}
            </>
          ) : (
        <span></span>
      )}
    </>
  );
}

SessionTimeOut.propTypes = {
  open: PropTypes.bool.isRequired,
};
