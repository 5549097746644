import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";

import unauthorizedImg from "../images/unauthorized.png";
import { OverviewPage } from "../core/PageConstants";
const UnAuthorized = (props) => {
  document.title = "UnAuthorized";
  return (
    <>
      <div id="indexLoading">
        <div className="align-center">
          <Box component="img" src={unauthorizedImg}></Box>
          <h1>401 - UnAuthorized</h1>
          <p>You are not authorized to access this page.</p>
          <p>
            <Link to={OverviewPage.Path} className="btn btn-secondary">
              Back to Overview
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};
export default UnAuthorized;
