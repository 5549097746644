import React, { useState, useEffect } from "react";
import "../../Style.css";
import Utils from "../../core/Utils";
import UserHelper from "../../core/UserHelper";
import { connectToStore } from "../../data/store";
import SideBarMenuItems from "../SideBarMenuItems";
import SettingsMenuItems from "../SettingsMenuItems";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {
  SideBarMenuNames,
  SettingsMenuList,
  MaxDateLimit,
} from "../../core/Constants";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MessagePopup from "../common/MessagePopUp";
import { Typography } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import { Grid, Box, Container, Stack, Switch } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Loader from "../common/Loader";
import moment from "moment";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { StaffReportPage, LoginPage } from "../../core/PageConstants";
import InputLabel from "@mui/material/InputLabel";
import { getBusiness } from "../../data/localSettingsActions";
import { postAsync } from "../../core/serviceClient";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CountryHelper from "../../core/CountryHelper";

const StaffsReport = (props) => {
  document.title = "Employee ";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [reportContent, setReportContent] = useState("");
  const [filterByBranch, setFilterByBranch] = useState(false);
  const [filterByStaff, setFilterByStaff] = useState(false);
  const [hideSwitch, setHideSwitch] = useState(false);
  const [selectedDate, setSelectedDate] = useState([
    Utils.GetStartDate(),
    Utils.GetEndDate(),
  ]);
  const [localSettings, setLocalSettings] = useState(connectToStore());
  const [statementMethod, setStatementMethod] = useState("Summary");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [selectedStaffBranch, setSelectedStaffBranch] = useState("");
  const [branchStaff, setBranchStaff] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [allStaffsList, setAllStaffList] = useState([]);

  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };
  const handleFilterByBranchChange = (event) => {
    setFilterByBranch(event.target.checked);
    setFilterByStaff();
    setHideSwitch();
  };

  const handleFilterByStaffBranchChange = (event) => {
    setFilterByStaff(event.target.checked);
  };
  const handleSubmitDownload = () => {
    getStatementDownload(statementMethod);
  };

  const getStatementDownload = async (statementMethod) => {
    let url = "Reports/GetStaffDetailedReport?";
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const staffId =
      filterByStaff && selectedStaffBranch !== null ? selectedStaffBranch : "";

    if (statementMethod === "Summary") {
      url = "Reports/GetStaffSummaryReport?";
    }
    let reportUrl =
      Utils.GetServiceUrl() +
      url +
      "branchId=" +
      branchId +
      "&businessId=" +
      localSettings.business.id +
      "&startDate=" +
      Utils.GetISODateTimeString(selectedDate[0]) +
      "&endDate=" +
      Utils.GetISODateTimeString(selectedDate[1]) +
      "&staffId=" +
      staffId +
      "&token=" +
      localSettings.token +
      "&culture=" +
      CountryHelper.GetAPICulture(localSettings.country.Culture);
    window.open(reportUrl, "_blank");

    setIsLoading(false);
  };

  const handleSubmit = () => {
    handleShowReport(statementMethod);
  };

  const handleShowReport = async (statementMethod) => {
    let url = "Reports/StaffDetailedReport";

    if (statementMethod === "Summary") {
      url = "Reports/StaffSummaryReport";
    }
    const branchId =
      filterByBranch && selectedBranch !== null ? selectedBranch : "";
    const staffId =
      filterByStaff && selectedStaffBranch !== null ? selectedStaffBranch : "";
    setIsLoading(true);
    let response = await postAsync(url, {
      branchId: branchId,
      businessId: localSettings.business.id,
      startDate: selectedDate[0],
      endDate: selectedDate[1],
      staffId: staffId,
      Token: localSettings.token,
    });
    replaceImg(response.data);
    setIsLoading(false);
  };
  const replaceImg = (reportContent) => {
    var imgURL = Utils.GetServiceUrl() + "/images/";
    var webcontent = reportContent.replaceAll("/images/", imgURL);
    setReportContent(webcontent);
  };

  const onDateChange = (date) => {
    if (!Utils.IsNullOrEmptyArray(date)) {
      var dateArray = [moment(date[0]), moment(date[1])];
      let dateDiff = dateArray[1].diff(dateArray[0], "days");
      if (dateDiff > MaxDateLimit) {
        setMsgText(
          "Please note that the maximum date range you can select is 90 days. You can choose any start date within the past 90 days, but the end date cannot exceed this limit."
        );

        setMsgOpen(true);
      } else {
        setSelectedDate(dateArray);
      }
    }
  };

  const checkAuthentication = async () => {
    if (UserHelper.CheckPermission(StaffReportPage, navigate, localSettings)) {
      const business = getBusiness();
      if (
        !Utils.IsNullOrEmptyObject(business) &&
        !Utils.IsNullOrEmptyArray(business.branchLocations)
      ) {
        setBranches(business.branchLocations);
        await StaffList();
      }
    }
  };
  const handleInputStatement = (e) => {
    setStatementMethod(e.target.value);
  };
  useEffect(() => {
    checkAuthentication();
  }, []);

  const handleSelect = (event) => {
    setSelectedBranch(event.target.value);
    setStaffs(event.target.value);
    setHideSwitch(true);
  };
  const getStaffs = async () => {
    setIsLoading(true);
    let result = await postAsync("Account/GetUsers", {
      ServiceStaff: true,
    });
    setIsNavigateToLogin(result.isNavigateToLogin);
    setIsLoading(false);
    if (result.error) {
      setMsgOpen(true);
      setMsgText(result.errorMessage);
      return;
    }
    return result.data.userProfiles;
  };

  const StaffList = async () => {
    let temptech = await getStaffs();
    setAllStaffList(temptech)
  };
  const setStaffs = (branchId) => {
    const tempBranch = allStaffsList.filter((s) => s.branchId === branchId);
    if (!Utils.IsNullOrEmptyArray(tempBranch)) {
      setBranchStaff(tempBranch);
    }
  };
  const handleStaffSelect = (event) => {
    setSelectedStaffBranch(event.target.value);
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />

      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.BusinessReports.Employee}
              />
              <Grid flex="1" spacing={2} className="form-sec">
                <form container spacing={2} onSubmit={handleSubmitDownload}>
                  <Grid container className="title-sec title-xs">
                    <Grid xs={12} md={4} lg={4}>
                      <Typography
                        level="h1"
                        component="h2"
                        fontWeight="600"
                        fontSize="20px"
                        A
                      >
                        Employee
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      md={3}
                      lg={3}
                      style={{ "align-self": "stretch" }}
                      justifyContent="end"
                      paddingRight="15px"
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ height: "100%" }}
                      >
                        <DateRangePicker
                          showLeadingZeros={true}
                          onChange={onDateChange}
                          maxDate={new Date()}
                          value={selectedDate}
                          locale={CountryHelper.GetDateCulture(localSettings.country.Culture)}
                          clearIcon={null}
                          calendarIcon={
                            <CalendarMonthOutlinedIcon
                              style={{
                                "font-size": "24px",
                                "align-self": "center",
                                color: "#666666",
                              }}
                            />
                          }
                        />
                      </Stack>
                    </Grid>
                  </Grid>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="end"
                    value="hide"
                    marginBottom={1}
                  >
                    {filterByBranch && (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        className="font-size-14"
                      >
                        <InputLabel id="Branch-label">Branch Name</InputLabel>
                        <Select
                          value={selectedBranch}
                          onChange={handleSelect}
                          label="Branch"
                          InputLabelProps={{ shrink: true }}
                          style={{
                            height: "50px",
                          }}
                        >
                          {branches.map((branch, index) => (
                            <MenuItem key={index} value={branch.branchId}>
                              {branch.branchName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    <Switch
                      checked={filterByBranch}
                      onChange={handleFilterByBranchChange}
                    />

                    <Typography
                      variant="h6"
                      style={{ "white-space": "nowrap", marginLeft: "0" }}
                      alignSelf="center"
                    >
                      Filter by branch
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="end"
                    value="hide"
                  >
                    {filterByStaff && (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        className="font-size-14"
                      >
                        <InputLabel id="Branch-label">
                          Filter by Staff
                        </InputLabel>
                        <Select
                          value={selectedStaffBranch}
                          onChange={handleStaffSelect}
                          label="Staff"
                          InputLabelProps={{ shrink: true }}
                          style={{
                            height: "50px",
                            width: "98%",
                          }}
                        >
                          {!Utils.IsNullOrEmptyArray(branchStaff) &&
                            branchStaff.map((branch, index) => (
                              <MenuItem key={index} value={branch.id}>
                                {branch.userName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}

                    {hideSwitch && (
                      <>
                        <Switch
                          checked={filterByStaff}
                          onChange={handleFilterByStaffBranchChange}
                        />
                        <Typography
                          variant="h6"
                          style={{ "white-space": "nowrap", margin: "0" }}
                          alignSelf="center"
                        >
                          Filter by staff
                        </Typography>
                      </>
                    )}
                  </Stack>

                  <Grid
                    spacing={2}
                    rowSpacing={2}
                    className="card mt-1"
                    padding={1}
                  >
                    <RadioGroup
                      value={statementMethod}
                      onChange={(e) => handleInputStatement(e)}
                      InputLabelProps={{ shrink: true }}
                    >
                      <Stack direction="row" spacing={40}>
                        <FormControlLabel
                          value={"Summary"}
                          control={<Radio />}
                          label="Summary"
                        />
                        <FormControlLabel
                          value={"Detailed"}
                          control={<Radio />}
                          label="Detailed"
                        />
                      </Stack>
                    </RadioGroup>
                  </Grid>
                </form>
                <iframe
                  className="reportIframe card"
                  srcdoc={reportContent}
                  style={{
                    width: "100%",
                    height: "calc(95vh - 270px)",
                  }}
                ></iframe>
                <br />

                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    container
                    justifyContent="center" // Horizontally center the content
                    alignItems="center" // Vertically center the content
                  >
                    <FormControl fullWidth>
                      <Button
                        className="btn btn-primary"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Show Statment
                      </Button>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    container
                    justifyContent="center" // Horizontally center the content
                    alignItems="center" // Vertically center the content
                  >
                    <FormControl fullWidth>
                      <Button
                        className="btn btn-primary"
                        variant="contained"
                        type="submit"
                        onClick={handleSubmitDownload}
                      >
                        DOWNLOAD
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default StaffsReport;
