import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import Utils from "../../../core/Utils";

export default function AmountTypography ({value, country, decimalPoints = 2, ...others}) {
  return (
    <Typography {...others}>
      {Utils.GetCurrency(
        value,
        country,
        decimalPoints
      )}
    </Typography>
  );
};

AmountTypography.propTypes = {
  value: PropTypes.number.isRequired,
  country: PropTypes.object.isRequired,
  decimalPoints: PropTypes.number.isRequired,
};
