import { BusinessCategories } from "./Constants";
import { getBusiness } from "../data/localSettingsActions";
import Utils from "./Utils";
import { SettingsMenuList } from "./Constants";
export default class BusinessHelper {
  static GetBusinessCategory(business) {
    var businessCategory = "";
    if (Utils.IsNullOrEmptyObject(business) === true) {
      var tempBusiness = getBusiness();
      if (Utils.IsNullOrEmptyObject(tempBusiness) !== true)
        businessCategory = tempBusiness.category;
    } else businessCategory = business.category;
    return businessCategory;
  }
  static IsSalon(business) {
    var businessCategory = this.GetBusinessCategory(business);
    return businessCategory == BusinessCategories.Salon;
  }
  static IsAutomobile(business) {
    var businessCategory = this.GetBusinessCategory(business);
    return businessCategory == BusinessCategories.Automobile;
  }
  static GetConsumeablePageTitle() {
    return this.GetConsumablesTitle(this.IsSalon());
  }
  static GetConsumeableMenuName() {
    if (this.IsSalon()) return SettingsMenuList.BusinessSettings.MaterialSetup;
    return SettingsMenuList.BusinessSettings.ConsumablesSetup;
  }
  static GetSparePageTitle() {
    return this.GetSparesTitle(this.IsSalon());
  }
  static GetSpareMenuName() {
    if (this.IsSalon()) return SettingsMenuList.BusinessSettings.ProductSetup;
    return SettingsMenuList.BusinessSettings.SparesSetup;
  }
  static GetServicePageTitle() {
    return this.GetServicesTitle(this.IsSalon());
  }
  static GetServiceMenuName() {
    if (this.IsSalon()) return SettingsMenuList.BusinessSettings.ServiceSetup;
    return SettingsMenuList.BusinessSettings.ServicesOrLabourSetup;
  }

  static GetSparesTitle(isSalon) {
    if (isSalon) return "Products";
    return "Spares";
  }
  static GetServicesTitle(isSalon) {
    return "Services";
  }
  static GetConsumablesTitle(isSalon) {
    if (isSalon) return "Materials";
    return "Consumables";
  }
}
