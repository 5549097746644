import { TextField } from "@mui/material";
import { Regexes } from "../../../core/Regexes";

const NumericTextField = (props) => {
  const { value, setValue, ...other } = props;
  const handleOnChange = (event) => {
    const val = event.target.value;
    if (val !== "" && !Regexes.OnlyNumeric.test(val)) {
      setValue(val.slice(0, -1));
    } else {
      setValue(val);
    }
  };
  return (
    <TextField {...other} value={value} onChange={(e) => handleOnChange(e)} />
  );
};

export default NumericTextField;
