import React from "react";
import "../Style.css";
import "../bootstrap.min.css";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import AddSharpIcon from "@mui/icons-material/AddSharp";
import Utils from "../core/Utils";
import ConfirmPopup from "./common/ConfirmPopup";
import { ConfirmTypes } from "../core/Constants";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { postAsync } from "../core/serviceClient";
import Loader from "./common/Loader";
import MessagePopup from "./common/MessagePopUp";
import SideBarMenuItems from "./SideBarMenuItems";
import SettingsMenuItems from "./SettingsMenuItems";
import { GridToolbarContainer } from "@mui/x-data-grid";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  SideBarMenuNames,
  SettingsMenuList,
  UserRoles,
} from "../core/Constants";

import { useDispatch } from "react-redux";
import { connectToStore } from "../data/store";
import UserHelper from "../core/UserHelper";
import { AddNewUserPage, LoginPage, UsersPage } from "../core/PageConstants";

const Users = (props) => {
  document.title = "User";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [localSettings, setLocalSettings] = useState({});
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgText, setMsgText] = useState("");
  const [isNavigateToLogin, setIsNavigateToLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfrimText] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const [userList, setUserList] = useState([]);
  const columns = [
    {
      field: "firstName",
      valueGetter: (params) => {
        return params.row.firstName + " " + params.row.lastName;
      },
      headerName: "Name",
      headerClassName: "headerStyle",
      flex: 1,
    },

    {
      field: "emailId",
      headerName: "Email",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "headerStyle",
      flex: 1,
    },
    {
      field: "active",
      headerName: "Active",
      headerClassName: "headerStyle",
      flex: 1,
      type: "boolean",
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "headerStyle",
      width: 100,
      cellClassName: "actions",

      getActions: ({ row }) => {
        return [
          <>
            {localSettings.user.role === UserRoles.GlobalAdmin && (
              <>
                <GridActionsCellItem
                  icon={<EditRoundedIcon className="icon20" />}
                  label="Edit"
                  className="textPrimary"
                  onClick={() => handleEditUser(row)}
                  color="inherit"
                />
                {localSettings.user.id !== row.id && (
                  <GridActionsCellItem
                    icon={<DeleteOutlineIcon className="icon20" />}
                    label="Delete"
                    onClick={() => deleteUser(row)}
                    color="error"
                  />
                )}
              </>
            )}
            {localSettings.user.role == UserRoles.BranchAdmin &&
              row.role !== UserRoles.GlobalAdmin && (
                <>
                  <GridActionsCellItem
                    icon={<EditRoundedIcon className="icon20" />}
                    label="Edit"
                    className="textPrimary"
                    onClick={() => handleEditUser(row)}
                    color="inherit"
                  />
                  {localSettings.user.id !== row.id && (
                    <GridActionsCellItem
                      icon={<DeleteOutlineIcon className="icon20" />}
                      label="Delete"
                      onClick={() => deleteUser(row)}
                      color="inherit"
                    />
                  )}
                </>
              )}
          </>,
        ];
      },
    },
  ];
  const handleMsgClose = () => {
    setMsgOpen(false);
    if (isNavigateToLogin) {
      UserHelper.LogOut(dispatch);
      navigate(LoginPage.Path);
    }
  };

  const handleConfirmClose = (value) => {
    setConfirmOpen(false);
    if (value == ConfirmTypes.OK) {
      if (!Utils.IsNullOrEmptyObject(selectedUser)) {
        UserFormDelete(selectedUser);
      } else {
        UserHelper.LogOut(dispatch);
        navigate(LoginPage.Path);
      }
    }
  };

  useEffect(() => {
    let localSetting = connectToStore();
    if (UserHelper.CheckPermission(UsersPage, navigate, localSetting)) {
      setLocalSettings(localSetting);
    }
  }, []);

  useEffect(() => {
    getUsers();
  }, [localSettings]);

  const getUsers = async () => {
    if (localSettings.authenticated) {
      setIsLoading(true);
      let result = await postAsync(
        "Account/GetAccount",
        {},
        "",
        "GetAll",
        "AllUsersForBusiness",
        [localSettings.business.id]
      );

      if (!Utils.IsNullOrEmptyArray(result.data)) {
        setUserList(result.data);
      } else if (result.error) {
        setMsgOpen(true);
        setMsgText(result.errorMessage);
      }
      setIsLoading(false);
    }
  };
  const deleteUser = (User) => {
    setSelectedUser(User);
    setConfrimText(
      "Are you sure you want to delete the " + User.firstName + "?"
    );
    setConfirmOpen(true);
  };
  async function UserFormDelete(User) {
    setIsLoading(true);
    let result = await postAsync(
      "Account/DeleteUser",
      {
        Id: User.id,
        product: User,
        BusinessId: localSettings.business.id,
      },
      "",
      "Delete",
      "",
      []
    );
    if (result && result.data) {
      setMsgText("Deleted sucessfully");
      setMsgOpen(true);
      setSelectedUser({});
      getUsers();
    } else {
      setMsgText("Unable to process your request");
      setMsgOpen(true);
    }
    setIsLoading(false);
  }

  const handleEditUser = (rows) => {
    navigate(AddNewUserPage.Path, {
      state: {
        selectedUser: rows,
      },
    });
  };

  return (
    <Container maxWidth="false" className="bg-color p-0">
      <Loader open={isLoading} />
      <MessagePopup
        msgOpen={msgOpen}
        msgText={msgText}
        onMsgClose={handleMsgClose}
      />
      <ConfirmPopup
        confirmOpen={confirmOpen}
        confirmText={confirmText}
        onConfirmClose={handleConfirmClose}
      />
      <Box>
        <Grid container m={0}>
          <SideBarMenuItems selectedTab={SideBarMenuNames.Settings} />
          <Grid xs={12} className="content-sec">
            <Grid container direction="row" className="">
              <SettingsMenuItems
                selectedMenu={SettingsMenuList.BusinessSettings.UserSetup}
              />
              <Grid flex="1" spacing={2} className="form-sec xs-pd-0">
                <Grid container className="title-sec title-xs">
                  <Typography
                    level="h2"
                    component="h2"
                    fontWeight="600"
                    fontSize="2rem"
                    className="page-title text-center"
                  >
                    Users
                  </Typography>

                  <Stack paddingRight="15px" alignSelf="center">
                    <Link to={AddNewUserPage.Path}>
                      <Button variant="contained" className="btn btn-secondary">
                        <AddSharpIcon
                          style={{ color: "#2196f3" }}
                          fontSize="large"
                        />
                        <span className="hidden-xs ml-h">Add New</span>
                      </Button>
                    </Link>
                  </Stack>
                </Grid>

                <Grid item xs={12} className="font-size-14 data-grid-box">
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      getRowId={(row) => row.id}
                      rows={userList}
                      columns={columns}
                      disableColumnFilter={true}
                      disableColumnMenu={true}
                      rowSelection={false}
                      sx={{
                        boxShadow: 1,
                        border: 1,
                        borderColor: "primary.light",
                        "& .MuiDataGrid-cell:hover": {
                          color: "primary.main",
                        },
                      }}
                      slots={{ toolbar: CustomToolbar }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: true,
                          showFilters: false,
                        },
                      }}
                      className="data-grid"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter className="searchbar" />
    </GridToolbarContainer>
  );
}
export default Users;
