import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Stack,
  TextField,
  Autocomplete,
  Tooltip,
  Box,
} from "@mui/material";
import { loadProductsFromServer } from "../../core/serviceClient";
import { getCountry } from "../../data/localSettingsActions";
import Utils from "../../core/Utils";

const AddProduct = ({ onAddItem, fromServiceSetup = false }) => {
  const [allProducts, setAllProducts] = useState([]);
  const [countryInfo, setCountryInfo] = useState({});
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    setCountryInfo(getCountry());
    let result = await loadProductsFromServer();
    var data = result.data;
    var products = [];
    if (!Utils.IsNullOrEmptyArray(data)) {
      data = data.filter(
        (c) =>
          c.active === true
      );
      if (fromServiceSetup === true) {
        data = data.filter((c) =>
          c.active === true && c.service == false && c.isMaterial == false);
      }
    }
    if (!Utils.IsNullOrEmptyArray(data)) {
      data.forEach((item) => {
        var stringItem = JSON.stringify(item);
        products.push(stringItem);
      });
    }
    setAllProducts(products);
  };

  const filterProducts = (options, state) => {
    if (Utils.IsNullOrEmpty(state.inputValue)) {
      return options;
    }
    var upperCaseSearchText = state.inputValue.trim().toUpperCase();
    var parsedOptions = [];
    options.forEach((item) => {
      var parsedItem = JSON.parse(item);
      parsedOptions.push(parsedItem);
    });
    var newFilteredProducts = parsedOptions.filter(
      (p) =>
        Utils.ConvertToUpperCase(p.code).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.description).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.shortDesc).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.class).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.classDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.manufacturer).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.department).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.departmentDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.fineline).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.finelineDescription).includes(
          upperCaseSearchText
        ) ||
        Utils.ConvertToUpperCase(p.sku).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.upc).includes(upperCaseSearchText) ||
        Utils.ConvertToUpperCase(p.vendor).includes(upperCaseSearchText)
    );
    var newProducts = [];
    if (!Utils.IsNullOrEmptyArray(newFilteredProducts)) {
      newFilteredProducts.forEach((item) => {
        newProducts.push(JSON.stringify(item));
      });
    }
    return newProducts;
  };

  const addItemToCart = (item) => {
    if (!Utils.IsNullOrEmpty(item)) {
      setSearchText("");
      onAddItem(JSON.parse(item));
    }
  };

  return (
    <Grid xs={12} style={{ padding: "0" }} className="productsListSec">
      <Autocomplete
        className="prod-input font-size-13"
        disablePortal
        value={searchText}
        onChange={(event, newValue) => {
          addItemToCart(newValue);
        }}
        inputValue={searchText}
        onInputChange={(event, newInputValue) => {
          setSearchText(newInputValue);
        }}
        options={allProducts}
        filterOptions={filterProducts}
        fullWidth
        disableCloseOnSelect
        renderOption={(props, item) => (
          <li style={{ width: "100%" }} {...props}>
            <Box
              sx={{ "&:": { mr: 2, flexShrink: 0 } }}
              style={{ overflow: "visible", width: "100%" }}
              className="prod-wrap"
            >
              <Tooltip
                placement="top"
                title={
                  Utils.IsNullOrEmpty(JSON.parse(item).class) &&
                    Utils.IsNullOrEmpty(JSON.parse(item).department) ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <Stack direction="column">
                        {!Utils.IsNullOrEmpty(JSON.parse(item).class) && (
                          <Typography>{JSON.parse(item).class}</Typography>
                        )}
                        {!Utils.IsNullOrEmpty(JSON.parse(item).department) && (
                          <Typography>{JSON.parse(item).department}</Typography>
                        )}
                      </Stack>
                    </React.Fragment>
                  )
                }
                arrow
              >
                <Stack
                  direction="row"
                  spacing={2}
                  paddingLeft={2}
                  paddingRight={2}
                  justifyContent="space-between"
                  width="100%"
                  className="bb-1 prod-item"
                >
                  <Grid container xs={12}>
                    <Grid xs={2}>
                      <Typography
                        padding="10px 0 0px 0"
                        level="h4"
                        component="h4"
                        fontWeight="600"
                        fontSize="14px"
                        justifyContent="flex-start"
                      >
                        {JSON.parse(item).code}
                      </Typography>
                    </Grid>
                    <Grid xs={12} lg={2}>
                      <Typography
                        padding="10px 0 0px 0"
                        level="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="text-left"
                      >
                        {JSON.parse(item).shortDesc}
                      </Typography>
                    </Grid>
                    <Grid xs={12} lg={4}>
                      <Typography
                        padding="10px 0 0px 0"
                        level="h6"
                        component="h6"
                        fontWeight="500"
                        fontSize="12px"
                        className="text-left"
                      >
                        {JSON.parse(item).description}
                      </Typography>
                    </Grid>
                    <Grid xs={6} lg={2}>
                      <Typography
                        padding="10px 0 0px 0"
                        level="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="text-right text-xs-left"
                      >
                        {Utils.GetCurrency(JSON.parse(item).price, countryInfo)}
                      </Typography>
                    </Grid>
                    <Grid xs={6} lg={2}>
                      <Typography
                        padding="10px 0 0px 0"
                        level="h6"
                        component="h6"
                        fontWeight="600"
                        fontSize="14px"
                        className="text-right"
                        justifyContent="flex-end"
                      >
                        {JSON.parse(item).taxPercent.toFixed(2) + "%"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Stack>
              </Tooltip>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Add a product"
            inputProps={{
              ...params.inputProps,
              autoComplete: "off", // disable autocomplete and autofill
            }}
            className="lbl-text"
          />
        )}
      />
    </Grid>
  );
};

export default AddProduct;
