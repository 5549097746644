import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import { History, useNavigate } from "react-router-dom";
import { pageTitle } from "react";
import Utils from "../core/Utils";
import { CustomersPage, OverviewPage } from "../core/PageConstants";

const Register = () => {
  document.body.classList.add("login");
  document.title = "Register";
  const LoggedInWith = "Email";
  const [LoginId, setId] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  async function login() {
    let item = { LoggedInWith, LoginId };
    var request = {
      Data: {
        LoginId: LoginId,
        LoggedInWith: LoggedInWith,
      },
      Operation: "Execute",
    };

    let result = await fetch(Utils.GetServiceUrl() + "Account/Login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    result = await result.json();
    localStorage.setItem("user-info", JSON.stringify(result));
    var userInfo = JSON.parse(localStorage.getItem("user-info"));
    if (userInfo.data.notRegistered == false) {
      localStorage.setItem("authenticated", true);
      navigate(OverviewPage.Path);
    } else {
      navigate(CustomersPage.Path);
    }
  }

  // const history = useNavigate();
  // const [username, setusername] = useState("");
  // const [password, setpassword] = useState("");
  // const users = [{ username: "admin@infopoint.com", password: "admin" }];
  // const [authenticated, setauthenticated] = useState(null);
  // const [totalReactPackages, setTotalReactPackages] = useState(null);

  // var user;
  // const handleSubmit = (e) => {
  //     e.preventDefault();

  //     //url = "http://10.40.123.19:12750/Account/Login",

  //     const account = users.find((user) => user.username === username);
  //     if (account && account.password === password) {
  //         setauthenticated(true);
  //         localStorage.setItem("authenticated", true);
  //         navigate(OverviewPage.Path);
  //     }
  //     else {
  //         alert('Username/Password Mismatch! Please try again.');
  //     }

  // }

  if (localStorage.getItem("authenticated") == "true") {
    //navigate(OverviewPage.Path);
  } else {
    return (
      <Container maxWidth="full" className="main" sx={{ display: "flex" }}>
        <div className="lg-sec bg-sec"></div>
        <div className="lg-sec form-sec">
          <div className="lg-form">
            <div className="p-a-0">
              <h1>Sign Up</h1>
              <h3>
                Best business management software - Automobiles, Saloon &amp;
                Spa, Health &amp; Wellness
              </h3>
            </div>
            <div className="p-a-0 m-t-2">
              <form
                action="/Overview/"
                method="post"
                className="cs-form text-left"
                onSubmit={handleSubmit}
              >
                <TextField
                  className="input"
                  type="text"
                  id="name"
                  variant="outlined"
                  label="Name"
                  fullWidth="true"
                  name="name"
                  required
                  onChange={(e) => setId(e.target.value)}
                />

                <TextField
                  className="input m-t-1"
                  type="email"
                  id="emailId"
                  variant="outlined"
                  label="Email Id"
                  fullWidth="true"
                  name="email"
                  required
                  onChange={(e) => setId(e.target.value)}
                />

                <TextField
                  className="input m-t-1"
                  type="tel"
                  id="mobile"
                  variant="outlined"
                  label="Mobile"
                  fullWidth="true"
                  name="mobile"
                  required
                  onChange={(e) => setId(e.target.value)}
                />
                {/* <div className='m-t-1'>
                                    <label for="otp" className="form-lbl">OTP</label>
                                    <div className="otp-sec hide">
                                        <TextField type="text" name="otp" className="mand-fld" id="otp" maxlength="1" variant='outlined' required />
                                        <TextField type="text" name="otp" className="mand-fld" id="otp" maxlength="1" variant='outlined' required />
                                        <TextField type="text" name="otp" className="mand-fld" id="otp" maxlength="1" variant='outlined' required />
                                        <TextField type="text" name="otp" className="mand-fld" id="otp" maxlength="1" variant='outlined' required />
                                        <TextField type="text" name="otp" className="mand-fld" id="otp" maxlength="1" variant='outlined' required />
                                        <TextField type="text" name="otp" className="mand-fld" id="otp" maxlength="1" variant='outlined' required />
                                    </div>
                                </div> */}
                <div className="m-t-1 text-center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    className="submitBtn cs-btn"
                    fullWidth="true"
                  >
                    Sign Up
                  </Button>
                </div>
                <div className="p-a-0 text-center m-t-1">
                  <p>
                    <small className="text-muted">OR</small>
                  </p>
                  <p className="m-t-1">Sign Up with</p>
                  <p className="m-t-1">
                    <Button variant="outlined">
                      <small>Mobile</small>
                    </Button>
                    &nbsp;
                    {/* <Button variant="outlined"><small>POS</small></Button> */}
                  </p>
                  <p className=" m-t-2">
                    Already registered? <a href="./">Login</a>.
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12 copyrights">
            <p>
              <a href="">Privacy Policy</a>
              &copy; 2022
              <a href="https://infopoint.com/" target="_blank">
                Infopoint
              </a>
              powered by
              <a href="https://xuvi.com/" target="_blank">
                XUVI
              </a>
            </p>
          </div>
        </div>
      </Container>
    );
  }
};

export default Register;
